import "./index.scss";

import { maintenanceIcon } from "assets";
import { MetaDescription } from "components";
import { Footer, Header } from "layouts";

export function Maintenance() {
  return (
    <>
      <MetaDescription
        pageTitle="Site under maintenance"
        pageDescription="CyberCert maintenance ongoing. Sorry for the inconvenience."
      />

      <div className="maintenance-wrapper">
        <Header />

        <div className="maintenance">
          <div className="maintenance-box">
            <div className="maintenance-icon">
              <img src={maintenanceIcon} alt="Maintenance mode" />
            </div>
            <span className="heading2">Maintenance mode</span>
            <p>
              {" "}
              We are working to improve your experience using our app. Please
              check back later.
            </p>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
