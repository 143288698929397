import { useState } from "react";

import {
  AccountCertificationSettings,
  AccountSettings,
  OrganisationSettings,
  Profile,
} from "components";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export function DashboardTabMobile({
  userData,
  progress,
  displayProgress,
  progresses,
  setDisplayProgress,
  currentProgressScroll,
  certificationInfo,
}) {
  const [activeKey, setActiveKey] = useState(0);

  const toggleActiveKey = (target) => {
    if (target === activeKey) {
      setActiveKey(0);
    } else {
      setActiveKey(target);
    }
  };

  return (
    <Accordion
      className="acc-tabs-content accordion-acc mobile"
      activeKey={activeKey.toString()}
    >
      <Card className="acc-tabs-item">
        <Card.Header>
          <a
            className="opener"
            title="Certifications"
            onClick={() => toggleActiveKey(1)}
          >
            Certifications
          </a>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div className="slide card-compl">
              <AccountCertificationSettings
                progress={progress}
                displayProgress={displayProgress}
                progresses={progresses}
                setDisplayProgress={setDisplayProgress}
              />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="acc-tabs-item">
        <Card.Header>
          <a
            className="opener"
            title="Organisation"
            onClick={() => toggleActiveKey(2)}
          >
            Organisation
          </a>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <div className="slide card-compl">
              <OrganisationSettings
                user={userData}
                certificationInfo={certificationInfo}
              />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="acc-tabs-item">
        <Card.Header>
          <a
            className="opener"
            title="Profile"
            onClick={() => toggleActiveKey(3)}
          >
            Profile
          </a>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <div className="slide card-compl">
              <Profile user={userData} certificationInfo={certificationInfo} />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="acc-tabs-item">
        <Card.Header>
          <a
            className="opener"
            title="Settings"
            onClick={() => toggleActiveKey(4)}
          >
            Settings
          </a>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <div className="slide card-compl">
              <AccountSettings
                userData={userData}
                certificationInfo={certificationInfo}
              />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
