import {
  AttestationLink,
  CertificateLink,
  CertificationDates,
  CertificationHistory,
  CredlyLink,
  StatusBadge,
} from "components";
import { useDownloadUrlState } from "middleware";
import { Link } from "react-router-dom";
import { BADGE_ANIMS, BADGES, CERTIFICATION_INFO, ROUTES } from "variables";

export function AccountCertificationSettings({
  progress,
  displayProgress,
  progresses,
  setDisplayProgress,
  doScroll,
}) {
  const { downloadUrl } = useDownloadUrlState();

  return (
    <>
      {progresses?.length > 0 ? (
        <>
          <div className="account-certification-flex">
            <div>
              <h4
                className="table-heading main-heading"
                id="account-settings-certification-tab-heading"
              >
                <svg
                  width="19"
                  height="24"
                  viewBox="0 0 19 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 24V2.25C0 1.00734 1.06331 0 2.375 0H16.625C17.9367 0 19 1.00734 19 2.25V24L9.5 18.75L0 24Z"
                    fill="black"
                  />
                </svg>
                Certifications ({progresses?.length || 0})
              </h4>

              <div className="custom-table-holder">
                <ul className="custom-table">
                  <li>
                    {!!displayProgress?.id &&
                    displayProgress?.id === progress?.id ? (
                      <strong className="tc-title">ACTIVE CERTIFICATE</strong>
                    ) : (
                      <strong className="tc-title">CERTIFICATE</strong>
                    )}
                    <span>
                      <strong>
                        Level {displayProgress?.level} -{" "}
                        {!!displayProgress?.level &&
                          CERTIFICATION_INFO[displayProgress?.level]
                            .marketingName}
                      </strong>
                    </span>
                  </li>
                  <li>
                    <strong className="tc-title">STATUS</strong>

                    <div id="account-settings-certification-badge">
                      <StatusBadge progress={displayProgress} />
                    </div>
                  </li>
                  {/* <li>
                    <strong className="tc-title">CERTIFICATE ID</strong>
                    <span>{progress?.id}</span>
                  </li> */}
                  <CertificationDates
                    progress={displayProgress}
                    completedDate={displayProgress?.completedDate}
                    expiredDate={displayProgress?.expiredDate}
                    purchasedDate={displayProgress?.purchasedDate}
                  />
                  {/* <li>
                    <strong className="tc-title">TOTAL</strong>
                    <span>${PRICING[displayProgress?.level]} + gst</span>
                  </li> */}
                </ul>
              </div>
            </div>
            <div>
              <div className="card-lvl card-lvl-animation">
                {displayProgress?.status !== "COMPLETED" ? (
                  <>
                    <h4 className="card-title">Certification documents</h4>
                    <ul className="download-list">
                      <li>
                        Certification documents will be available once
                        certification is complete.
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <video
                      autoPlay
                      loop
                      playsInline
                      muted
                      width="100%"
                      height="100%"
                      src={BADGE_ANIMS[progress?.level]}
                    >
                      <img
                        src={BADGES[progress?.level]}
                        className="badgeSeal"
                        alt={`Level ${progress?.level} Badge`}
                      />
                    </video>
                    <h4 className="card-title">Certification Documents</h4>
                    <ul className="download-list">
                      <li>
                        <CertificateLink
                          href={downloadUrl.certificateLink}
                          progress={displayProgress}
                        />
                      </li>
                      <li>
                        <AttestationLink
                          href={downloadUrl.signedDeedLink}
                          progress={displayProgress}
                        />
                      </li>
                      <li>
                        <CredlyLink
                          href={downloadUrl.badges}
                          progress={displayProgress}
                        />
                      </li>
                    </ul>
                    {progress?.id && displayProgress?.id === progress?.id && (
                      <Link
                        className="btn btn-warning"
                        to={`${ROUTES.CERTIFICATION_MANAGER}#step`}
                      >
                        View Details
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <CertificationHistory
            progresses={progresses}
            setDisplayProgress={(prog) => {
              if (displayProgress?.id !== prog?.id) {
                setDisplayProgress(prog);
              } else {
                doScroll();
              }
            }}
          />
        </>
      ) : (
        <>
          <h4
            className="table-heading main-heading"
            id="account-settings-certification-tab-heading"
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 24V2.25C0 1.00734 1.06331 0 2.375 0H16.625C17.9367 0 19 1.00734 19 2.25V24L9.5 18.75L0 24Z"
                fill="black"
              />
            </svg>
            Certifications
          </h4>

          <p>No certification history yet.</p>
        </>
      )}
    </>
  );
}
