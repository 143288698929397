import { Amplify } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { isLocalHost, OFFICIAL_DOMAIN } from "services";
import App from "./App";
import awsExports from "./aws-configure";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// Sohne fonts
import './assets/fonts/Sohne/soehne-buch.woff2';
import './assets/fonts/Sohne/soehne-dreiviertelfett.woff2';
import './assets/fonts/Sohne/soehne-extrafett.woff2';
import './assets/fonts/Sohne/soehne-extraleicht.woff2';
import './assets/fonts/Sohne/soehne-fett.woff2';
import './assets/fonts/Sohne/soehne-halbfett.woff2';
import './assets/fonts/Sohne/soehne-kraftig.woff2';
import './assets/fonts/Sohne/soehne-leicht.woff2';

Amplify.configure({
  ...awsExports,
  Auth: {
    ...awsExports.Auth,
    // session across cybercert.ai domain
    cookieStorage: {
      domain: isLocalHost() ? document.location.hostname : OFFICIAL_DOMAIN,
      secure: !isLocalHost(),
      path: "/",
      expires: 30,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
