import "./index.scss";

import { ArrowFwd, Img404 } from "assets";
import { MetaDescription } from "components";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "variables";

export function PageNotFound() {
  return (
    <>
      <MetaDescription
        pageTitle="Page not found"
        pageDescription="Sorry, the page requested does not exist."
      />
      <div className="nf-super-container f-row-center">
        <div className="nf-container f-row-center">
          <div className="content">
            <div className="nf-title">
              <h2 className="heading2">Uh-oh!</h2>
              <p>
                Sorry, the page you are looking for does not exist or has been
                moved.
              </p>
            </div>
            <Link to={ROUTES.ROOT}>
              <Button className="btn btn-warning-gd">Back to Home</Button>
            </Link>
            <div className="get-help">
              <p>Get Help</p>
              <img src={ArrowFwd} alt="Get Help" />
            </div>
            <p className="description">
              Reach out to us anytime if you need assistance. Use the Help
              buttons in our site to get help.
            </p>
          </div>
          <div className="nf-illustration">
            <img src={Img404} alt="Page not found." />
          </div>
        </div>
      </div>
    </>
  );
}
