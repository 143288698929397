import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function AssessmentToolQuestionInputRadio({
  qid,
  title,
  options,
  onChange,
  stepProgress,
  group,
  groupId,
}) {
  const [internalOption, setOption] = useState();
  const id = uuidv4();

  useEffect(() => {
    const q = stepProgress.answers.filter((q) => q.id === qid)[0];
    setOption(q?.value);
  }, [qid]);

  return (
    <>
      <strong>
        {`${group}.${groupId}`} {title}
      </strong>

      <div className="input-col">
        {options.map((option, i) => (
          <label htmlFor={`${option}-${qid}-${id}`}>
            <input
              className="fake-input"
              id={`${option}-${qid}-${id}`}
              data-id={qid}
              data-option={option}
              type="radio"
              name={qid}
              onChange={(e) => {
                setOption(option);
                onChange(e);
              }}
              checked={option === internalOption}
            />
            <span className="fake-radio"></span>
            <span>{option}</span>
          </label>
        ))}
      </div>
    </>
  );
}
