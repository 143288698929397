import { OTHERS_TAG } from "./constants";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function AssessmentToolQuestionInputCheckbox({
  qid,
  title,
  options,
  onChange,
  stepProgress,
  others,
  inheritOptions,
  dependsOn,
  group,
  groupId,
}) {
  const [availableOptions, setAvailOptions] = useState([]);
  const [internalOptions, setOptions] = useState([]);
  const [selectedOthers, setSelectedOthers] = useState();
  const [internalOthersInput, setOthersInput] = useState();
  const id = uuidv4();

  useEffect(() => {
    const q = stepProgress.answers.filter((q) => q.id === qid)[0];
    const selectedOptions = (q.value || "").split(/[\r\n]+/);
    setOptions(selectedOptions);
    const othersOption = selectedOptions.filter((o) =>
      o.includes(OTHERS_TAG)
    )[0];
    if (!!othersOption) {
      setSelectedOthers(true);
      setOthersInput(othersOption.replace(OTHERS_TAG, ""));
    }
    if (!inheritOptions) {
      setAvailOptions(options);
    } else {
      updateInheritedOption();
    }
  }, [qid]);

  useEffect(() => {
    updateInheritedOption();
  }, [stepProgress]);

  const updateInheritedOption = () => {
    if (inheritOptions && !!dependsOn) {
      const baseQuestion = stepProgress.answers.filter(
        (q) => q.id === dependsOn
      )[0];

      if (!!baseQuestion) {
        setAvailOptions(baseQuestion.value?.split(/[\r\n]+/));
      }
    }
  };

  return (
    <>
      <strong>
        {`${group}.${groupId}`} {title}
      </strong>

      <div className="input-col question-input-checkbox">
        {availableOptions?.map((option) => (
          <>
            {!!option.replace(OTHERS_TAG, "") && (
              <label htmlFor={`${option}-${qid}-${id}`}>
                <input
                  className="fake-input"
                  id={`${option}-${qid}-${id}`}
                  data-id={qid}
                  data-option={option}
                  type="checkbox"
                  name={qid}
                  onChange={(e) => {
                    if (e.target.checked && !internalOptions.includes(option)) {
                      internalOptions.push(option);
                      setOptions(internalOptions.filter(Boolean));
                    }
                    if (!e.target.checked) {
                      setOptions(
                        internalOptions.filter((o) => !!o && o !== option)
                      );
                    }
                    onChange(e);
                  }}
                  checked={internalOptions.includes(option)}
                />
                <span className="fake-checkbox"></span>
                <span>{option.replace(OTHERS_TAG, "")}</span>
              </label>
            )}
          </>
        ))}

        {others && (
          <label htmlFor={`others-${qid}-${id}`}>
            <input
              className="fake-input"
              id={`others-${qid}-${id}`}
              data-id={qid}
              data-option="others"
              type="checkbox"
              name={qid}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedOthers(true);
                }
                if (!e.target.checked) {
                  setSelectedOthers(false);
                  setOthersInput("");
                }
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    value: OTHERS_TAG,
                    type: "checkbox-others",
                  },
                });
              }}
              checked={selectedOthers}
            />
            <span className="fake-checkbox"></span>
            <span className="others-title">Other (please specify) </span>

            {selectedOthers && (
              <input
                className="others-input"
                type="text"
                onChange={(e) =>
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: `${OTHERS_TAG}${e.target.value}`,
                      type: "checkbox-others",
                    },
                  })
                }
                data-id={qid}
                data-option="others"
                defaultValue={internalOthersInput}
                required
              />
            )}
          </label>
        )}
      </div>
    </>
  );
}
