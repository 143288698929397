import { checkInProgressIcon } from "assets";
import { Link } from "react-router-dom";
import { CertificationService } from "services/certification";
import { ROUTES } from "variables";

export function DashboardSummaryInProgress({ progress }) {
  return (
    <div className="step-item">
      <div className="item">
        <div className="step-name">
          <img src={checkInProgressIcon} alt="check in progress icon" />

          <span className="current-step">
            Step {CertificationService.getActualCurrentStep(progress)}:{" "}
            {CertificationService.getCertStepTitleFor(progress)}
          </span>
        </div>
        <Link
          className="btn btn-warning pulse"
          to={`${ROUTES.CERTIFICATION_MANAGER}#step`}
        >
          <span>{progress?.step > 1 ? "Continue" : "Start"}</span>
        </Link>
      </div>
    </div>
  );
}
