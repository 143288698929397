import { useLoadingState } from "middleware";
import { Link, useLocation } from "react-router-dom";

export function NavBreadcrumb({ item }) {
  const location = useLocation();
  const { setLoading } = useLoadingState();
  const data = {
    class:
      item.active === false
        ? ""
        : item.active === true
        ? "active"
        : location.pathname === item?.to
        ? "active"
        : "",
    to: "",
    name: "",
    ...item,
  };
  return (
    <li className={data.class}>
      <Link
        to={data.to}
        onClick={() => {
          if (window.location.pathname !== item?.to) {
            setLoading(true);
          }
          if (item?.forceReload === true) {
            window.location.href = item?.to;
          }
        }}
      >
        {data.name}
      </Link>
    </li>
  );
}
