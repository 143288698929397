import { loadStripe } from "@stripe/stripe-js";
import {
  getProductCheckoutSession,
  getProductPrice,
  getProductsPrice,
} from "api";
import { spinner } from "assets";
import { Spinner } from "components";
import { useLoadingState } from "middleware";
import { useEffect, useState } from "react";

const products = [
  "GUIDE",
  "LEVEL_1",
  "LEVEL_2",
  "LEVEL_3",
  "LEVEL_4",
  "LEVEL_5",
];

export function TestStripe() {
  const { loading, setLoading } = useLoadingState();
  const [prices, setPrices] = useState();

  useEffect(() => {
    setLoading(true);

    getProductsPrice().then((res) => {
      setPrices(res.prices);
      setLoading(false);
    });
  }, []);

  const testBuy = async (item) => {
    setLoading(true);
    // const stripe = await loadStripe(
    //   "pk_live_51LAobDG9vLNxrpyvk6mPeVhfpT8Dtgrzz8bDZK1o2vUG40Mv3EbDVXG2OujhjEpfe3swfCRRszAzCwkxMQLS8FDV00UhcaKJfi"
    // );
    const stripe = await loadStripe(
      "pk_test_51LAobDG9vLNxrpyvhZbtor9Yh7DAHiWoSIL9mrDWVJ4uoGRmynljnSNMRxhHHYMChfOSpeDP4eiCnDnSjJNPUcS9002p5attMw"
    );

    const { sessionId, err } = await getProductCheckoutSession({
      item,
      success_url: window.location.href,
      cancel_url: window.location.origin,
    });

    if (err === "FREE") {
      console.log("This item is free :)");

      setLoading(false);
    } else {
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
    }
  };

  const simpleCapitalize = (c) => {
    const capitalize = (s) => {
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    };

    return capitalize(c.replace("_", " "));
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      {!!prices &&
        products.map((product) => (
          <>
            {prices[product] > 0 ? (
              <div>
                {simpleCapitalize(product)}: ${prices[product]}
                <button onClick={() => testBuy(product)}>
                  Buy {simpleCapitalize(product)}
                </button>
              </div>
            ) : (
              <div>{simpleCapitalize(product)}: Purchased</div>
            )}
          </>
        ))}
    </>
  );
}
