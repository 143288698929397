import { useEffect, useState } from "react";

import { getIssuedCertification } from "api";
import { Spinner } from "components";
import { useParams } from "react-router-dom";
import { CertificationService } from "services";

import { Issued } from "./issued";

export function IssuedCertificatePage() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [certification, setCertification] = useState();
  const [certRequirements, setCertRequirements] = useState();

  useEffect(() => {
    (async () => {
      const reqs = await CertificationService.getRequirementsForLevel(
        certification?.level,
      );

      setCertRequirements(reqs);
    })();
  }, [certification]);

  useEffect(() => {
    if (!id) {
      window.location.href = "/";
    }
    (async () => {
      try {
        const result = await getIssuedCertification(id);
        setCertification(result);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    })();
  }, []);

  console.log(certification);

  return (
    <>
      {isLoading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <Issued
        ABN={certification?.ABN}
        id={certification?.certID}
        level={certification?.level}
        name={certification?.name}
        reqs={certRequirements}
        status={certification?.status}
        completedDate={certification?.completedDate}
        expiredDate={certification?.expiredDate}
      />
    </>
  );
}
