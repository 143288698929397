import { Asterisk } from "components/required-asterisk";
import { useState } from "react";
import Form from "react-bootstrap/Form";

import "./form-input.scss";

export function FormDropdownInput({
  controlId,
  options = [],
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  successTxt = "",
  errorTxt = "Field is required.",
  required,
  disabled,
  pValidation,
  pSetValidation,
}) {
  let [validation, setValidation] = useState("NA");

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const validateInput = (event) => {
    if (!required || !!event.target.value || event.target.value !== -1) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>

      <Form.Group controlId={controlId}>
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <Form.Control
          className="form-input"
          as="select"
          name={name}
          maxLength={maxLength}
          defaultValue={value || -1}
          placeholder={placeholder}
          onChange={validateInput}
          disabled={disabled}
        >
          <option disabled value={-1} key={-1}>
            {/* Default select value */}
            {placeholder}
          </option>
          {options.map((o, i) => (
            <option key={i} value={o}>
              {o}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
}
