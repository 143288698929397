import {
  createOrganisation as createAPI,
  deleteOrganisation as deleteAPI,
  updateOrganisation as updateAPI,
} from "graphql/mutations";
import {
  makeCreateFunction,
  makeDeleteFunction,
  makeUpdateFunction,
} from "../../utils";

export const createOrganisation = (input) =>
  makeCreateFunction({
    name: "createOrganisation",
    query: createAPI,
    params: {
      input,
    },
  })();

export const updateOrganisation = (input) =>
  makeUpdateFunction({
    name: "updateOrganisation",
    query: updateAPI,
    params: {
      input,
    },
  })();

export const deleteOrganisation = (input) =>
  makeDeleteFunction({
    name: "deleteOrganisation",
    query: deleteAPI,
    params: {
      input,
    },
  })();
