import "./register-confirm.scss";

import { useEffect, useState } from "react";

import { FormInput, Spinner } from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { AuthService } from "services";
import { ROUTES } from "variables";

const initialState = {
  email: "",
  code: "",
};

const initialValidation = {
  email: "NA",
  code: "NA",
};

const validationRules = ["email", "code"];

export function RegisterConfirm({ email = "", onConfirm = () => {} }) {
  const [disabled, setDisabled] = useState(true);
  const { loading, setLoading } = useLoadingState();
  const [confirmed, setConfirmed] = useState(false);

  const { state, search } = useLocation();
  let registerEmail = state?.registerEmail;
  let registerCode = "";
  if (!!search) {
    const params = queryString.parse(search);
    if (!!params.email) {
      registerEmail = params.email;
    }
    if (!!params.verifyCode) {
      registerCode = params.verifyCode;
    }
  }
  const [confirmState, setConfirmState] = useState({
    ...initialState,
    email: email || registerEmail,
    code: registerCode,
  });
  const [validation, setValidation] = useState({
    ...initialValidation,
    email: !!(email || registerEmail) ? "success" : "NA",
    code: !!registerCode ? "success" : "NA",
  });
  const [codeOpResult, setCodeOpResult] = useState("NA");
  const [successTxt, setSuccessTxt] = useState();
  const [errorTxt, setErrorTxt] = useState();

  useEffect(() => {
    setLoading(false);
    if (!!email || !!registerEmail) {
      pSetValidation("email", "success");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInput(event) {
    setConfirmState({
      ...confirmState,
      [event.target.name]: event.target.value,
    });
  }

  const onConfirmSuccess = () => {
    setTimeout(() => {
      setConfirmed(true);
      setLoading(false);
    }, 3000);
  };

  const confirm = async () => {
    try {
      const { email, code } = confirmState;
      await AuthService.confirmSignup(email, code, () => {}, onConfirmSuccess);
    } catch (error) {
      // console.log(error);
      setCodeOpResult("error");
      setErrorTxt("Your verification code is incorrect.");
      setLoading(false);
    }
  };

  const resend = async () => {
    try {
      const { email } = confirmState;
      if (!email) {
        return;
      }
      await AuthService.resendConfirmationCode(email);
      setCodeOpResult("success");
      setSuccessTxt("Verification code resent successfully.");
    } catch (error) {
      console.error("Verification code resend failed.");
      setCodeOpResult("error");
      setErrorTxt("Verification code resend failed.");
    }
  };

  const pSetValidation = (name, value) => {
    const validationResult = { ...validation, [name]: value };
    setValidation(validationResult);
    setDisabled(
      !validationRules.every((prop) => validationResult[prop] === "success"),
    );
  };

  const validateSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const canSubmit = validationRules.every((prop) => !!confirmState[prop]);

    if (!canSubmit) {
      setDisabled(true);
    } else {
      confirm();
    }
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      {confirmed ? (
        <>
          <div className="auth-title">Your email is verified</div>
          <div className="subtitle">
            Your email has been verified successfully!
            <br />
            Access your account by logging in.
          </div>

          <Button
            className="btn btn-warning-gd"
            onClick={() => {
              window.location.href = ROUTES.LOGIN;
            }}
          >
            Login
          </Button>
        </>
      ) : (
        <>
          <div className="auth-title">Verify your Email Address</div>
          <div className="subtitle">
            Please enter the code we sent to{" "}
            <mark>{email || registerEmail}</mark> to complete your registration.
          </div>

          <Form>
            <div className={`form-validation ${codeOpResult}`}>
              <div className="validation-txt">
                {!!successTxt && <p className="v-text success">{successTxt}</p>}
                {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
              </div>
            </div>

            <FormInput
              controlId="confirmCode"
              label="Verification Code"
              type="code"
              name="code"
              value={registerCode}
              onChange={handleInput}
              pValidation={validation.code}
              pSetValidation={(value) => pSetValidation("code", value)}
              required
            />
            <a className="resend" onClick={resend}>
              Resend code
            </a>
            <div className="mt-30">
              <input
                type="submit"
                className="register-btn btn btn-warning-gd"
                onClick={validateSubmit}
                disabled={disabled}
                value="Complete Registration"
              />
            </div>
          </Form>
        </>
      )}
    </>
  );
}
