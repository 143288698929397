export function CertificationDates({
  progress,
  completedDate,
  expiredDate,
  purchasedDate,
}) {
  return (
    <>
      <li>
        <strong className="tc-title">START DATE</strong>
        <span>{purchasedDate}</span>
      </li>
      <li>
        <strong className="tc-title">COMPLETED DATE</strong>
        <span>{!!progress?.completedDate ? completedDate : "NA"}</span>
      </li>
      <li>
        <strong className="tc-title">EXPIRATION DATE</strong>
        <span>{!!progress?.expiredDate ? expiredDate : "NA"}</span>
      </li>
    </>
  );
}
