import { StatusBadge } from "components";
import { parseDate } from "services";
import { CERTIFICATION_INFO } from "variables";

export function CertificationHistory({
  title,
  progresses,
  setDisplayProgress,
}) {
  return (
    <>
      <div className="table-holder">
        <strong className="table-title">{title || "History"}</strong>
        <div className="table-wrap">
          <table className="table">
            <thead>
              <tr>
                <td>ITEM</td>
                <td>STATUS</td>
                <td>PURCHASE DATE</td>
                <td>EXPIRES</td>
              </tr>
            </thead>
            <tbody>
              {progresses?.map((progress) => (
                <tr
                  className="cursor-pointer"
                  onClick={() => {
                    setDisplayProgress(progress);
                  }}
                >
                  <td>
                    <strong>
                      Level {progress?.level} -{" "}
                      {!!progress?.level &&
                        CERTIFICATION_INFO[progress?.level].marketingName}
                    </strong>
                  </td>
                  <td>
                    <StatusBadge
                      progress={progress}
                      trigger={() => {
                        setDisplayProgress(progress);
                      }}
                    />
                  </td>
                  <td>
                    {parseDate(progress?.purchasedDate || progress?.createdAt)}
                  </td>
                  <td>
                    {!!progress?.expiredDate ? (
                      parseDate(progress.expiredDate)
                    ) : (
                      <>N/A</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
