import { failIcon, searchIcon, successIcon } from "assets";
import { Asterisk } from "components/required-asterisk";
import { useState } from "react";
import Form from "react-bootstrap/Form";

export function FormInputSearch({
  controlId,
  label,
  name,
  placeholder,
  onChange,
  required,
  searchFn,
  successTxt = "",
  errorTxt = "Field is required.",
  pValidation,
  pSetValidation,
  autoComplete,
  setRef,
  onKeyPress = () => {},
}) {
  const [state, setState] = useState(undefined);
  let [validation, setValidation] = useState("NA");

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const searchWrapper = () => {
    if (!!searchFn)
      searchFn().then((result) => {
        if (!result) {
          setState("searchFailed");
        } else {
          setState("searchSuccess");
        }
      });
  };

  const search = (event) => {
    event.preventDefault();

    searchWrapper();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchWrapper();
    } else if (state !== "searching") {
      setState("searching");
    }
  };

  const validateInput = (event) => {
    if (!required || !!event.target.value) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>
      <Form.Group className="mb-3 full-input" controlId={controlId}>
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <div className="search-input">
          <Form.Control
            className="form-input"
            type="text"
            name={name}
            ref={(r) => setRef(r)}
            autoComplete={autoComplete}
            placeholder={placeholder}
            onChange={validateInput}
            onKeyDown={handleKeyDown}
            onBlur={validateInput}
            onKeyPress={onKeyPress}
          />

          {!!searchFn && (
            <button className="search-btn" onClick={search} tabIndex="-1">
              {
                {
                  searching: (
                    <img
                      src={searchIcon}
                      alt="cybercert-search-icon"
                      width="20"
                      height="20"
                    />
                  ),
                  searchFailed: (
                    <img
                      src={failIcon}
                      alt="cybercert-search-icon"
                      width="20"
                      height="20"
                    />
                  ),
                  searchSuccess: (
                    <img
                      src={successIcon}
                      alt="cybercert-search-icon"
                      width="20"
                      height="20"
                    />
                  ),
                }[state]
              }
            </button>
          )}
        </div>
      </Form.Group>
    </div>
  );
}
