import { API } from "aws-amplify";

export const lookupABN = async ({ ABN }) => {
  const myInit = {
    queryStringParameters: {
      ABN,
      op: "lookup",
      country: "au",
    },
  };

  return API.get("organisation", "/lookupOp", myInit);
};
