import {
  createCertificationProgress as createAPI,
  deleteCertificationProgress as deleteAPI,
  updateCertificationProgress as updateAPI,
} from "graphql/mutations";

import {
  makeCreateFunction,
  makeUpdateFunction,
  makeDeleteFunction,
} from "api/utils";

export const createCertificationProgress = (input) =>
  makeCreateFunction({
    name: "createCertificationProgress",
    query: createAPI,
    params: {
      input,
    },
  })();

export const updateCertificationProgress = (input) =>
  makeUpdateFunction({
    name: "updateCertificationProgress",
    query: updateAPI,
    params: {
      input,
    },
  })();

export const deleteCertificationProgress = (input) =>
  makeDeleteFunction({
    name: "deleteCertificationProgress",
    query: deleteAPI,
    params: {
      input,
    },
  })();
