import "./index.scss";

import { useEffect, useState } from "react";

import { getSpreadsheetDownloadUrl, getWorkbookDownloadUrl } from "api";
import { LoadingLink } from "components";
import {
  CERTIFICATION_INFO,
  LEVEL_NAMES,
  LEVEL_WORKBOOK_COVERS,
} from "variables";

export function WorkbookFullWidth({ progress }) {
  const [workbook, setWorkbook] = useState("/");
  const [spreadsheet, setSpreadsheet] = useState("/");

  useEffect(() => {
    getWorkbookDownloadUrl().then(setWorkbook);
    getSpreadsheetDownloadUrl().then(setSpreadsheet);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getWorkbookDownloadUrl().then(setWorkbook);
      getSpreadsheetDownloadUrl().then(setSpreadsheet);
    }, 3600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="certification-section assessment-tool">
      <div className="container-md">
        <div className="panel-quiz-header">
          <div className="panel-header">
            {/* <div className="progress-bar">
              <span></span>
            </div> */}
            <div className="panel-steps">
              <div className="panel-steps-header workbook-spread">
                <div className="heading w-70">
                  <h4 className="item-title">
                    {/* LEVEL {progress?.level} -{" "} */}
                    {progress?.level &&
                      CERTIFICATION_INFO[progress?.level].marketingName}{" "}
                    WORKBOOK
                  </h4>
                  <h1>Download your Certification Workbook</h1>
                  <p>
                    This certification workbook has been designed to help make
                    it easier for your and your technical support specialist to
                    implement the certification requirements, track your
                    progress and document your actions.
                  </p>
                  <div className="requirement-btns-flex">
                    <LoadingLink
                      href={workbook}
                      className="btn btn-warning-gd"
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download workbook
                    </LoadingLink>
                    <LoadingLink
                      href={spreadsheet}
                      className="btn btn-warning-gd"
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download spreadsheet
                    </LoadingLink>
                  </div>
                </div>
                <div className="col-img">
                  <div className="item-img">
                    <img
                      src={LEVEL_WORKBOOK_COVERS[progress?.level]}
                      alt={`${LEVEL_NAMES[progress?.level]} Workbook`}
                      width="173"
                      height="244"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
