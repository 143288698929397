import "./index.scss";

export function InfoBar({ title, content, special, level }) {
  return (
    <>
      {/* <div className="help-block popup-holder popup-active"> */}
      <div className={`help-block ${level}`}>
        <div className="container-md">
          <p>
            <span className="title"> {title} </span> {content}
            {!!special && <span className="special-text">{special}</span>}
          </p>
        </div>
      </div>
    </>
  );
}
