import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function AssessmentToolQuestionInputText({
  qid,
  title,
  onChange,
  stepProgress,
  group,
  groupId,
}) {
  const [internalValue, setValue] = useState();
  const id = uuidv4();

  useEffect(() => {
    const q = stepProgress.answers.filter((q) => q.id === qid)[0];
    setValue(q.value);
  }, [qid]);

  return (
    <>
      <strong>
        {`${group}.${groupId}`} {title}
      </strong>

      <div className="input-col">
        <input
          type="text"
          onChange={onChange}
          data-id={qid}
          defaultValue={internalValue}
        />
      </div>
    </>
  );
}
