import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { Asterisk } from "components/required-asterisk";

import "./form-input.scss";

export function FormInputGroup({ controlId,
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  required,
  disabled,
  children
}) {
  /*let group = [
    <InputGroup.Text>Suburb</InputGroup.Text>,
    <Form.Control aria-label="first name" />,
    <InputGroup.Text>City</InputGroup.Text>,
    <Form.Control aria-label="last name" />,
    <InputGroup.Text>Postcode</InputGroup.Text>,
    <Form.Control aria-label="last name" />,
  ];*/
  return (
    <InputGroup className="mb3" controlId={controlId}>
      {children}
    </InputGroup>
  );
}