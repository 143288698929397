import { useState } from "react";

import { FormInput, PasswordStrength } from "components";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTES } from "variables";

export function VerificationCodePasswdInputForm({
  email,
  state,
  handleInput,
  onResetConfirm,
  onResendClick,
  validation,
  setValidation,
  errorTxt,
  setErrorTxt,
}) {
  const [disabled, setDisabled] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState();

  const [loading, setLoading] = useState(false);

  const validateContinue = () => {
    if (!state.password || !state.confirmPassword) {
      setValidation({
        ...validation,
        [!state.password ? "password" : "confirmPassword"]: "error",
      });
      setErrorTxt({
        ...errorTxt,
        [!state.password ? "password" : "confirmPassword"]:
          "Password is invalid.",
      });
      setDisabled(false || state?.code?.length !== 6);
      return;
    }
    if (
      ["password", "confirmPassword"].every(
        (prop) => validation[prop] === "success",
      )
    ) {
      onResetConfirm();
    } else {
      setDisabled(true);
    }
  };

  const setPasswdValidation = (name, check, reason, value) => {
    // console.log(name, check, reason, value);
    const newState = { ...state, [name]: value };
    let validationResult = validation;
    let errorTxtResult = errorTxt;
    if (check !== "error" && name === "confirmPassword") {
      if (!!value && !!state.password) {
        if (value !== state.password) {
          validationResult = {
            ...validationResult,
            password:
              errorTxtResult.password === "Passwords do not match."
                ? "success"
                : validation.password,
            confirmPassword: "error",
          };
          errorTxtResult = {
            ...errorTxtResult,
            password:
              errorTxtResult.password === "Passwords do not match."
                ? ""
                : errorTxtResult.password,
            [name]: "Passwords do not match.",
          };
        } else {
          validationResult = {
            ...validation,
            [name]: "success",
          };
        }
      }
    } else if (check !== "error" && name === "password") {
      if (!!value && !!state.confirmPassword) {
        if (value !== state.confirmPassword) {
          validationResult = {
            ...validationResult,
            confirmPassword: "success",
            password: "error",
          };
          errorTxtResult = {
            ...errorTxtResult,
            confirmPassword: "",
            [name]: "Passwords do not match.",
          };
        } else {
          validationResult = {
            ...validationResult,
            password: "success",
          };
          errorTxtResult = {
            ...errorTxtResult,
            [name]: "",
          };
        }
      } else {
        validationResult = {
          ...validationResult,
          password: "success",
        };
        errorTxtResult = {
          ...errorTxtResult,
          [name]: "",
        };
      }
    } else if (check === "error") {
      validationResult = {
        ...validationResult,
        [name]: "error",
      };
      errorTxtResult = {
        ...errorTxtResult,
        [name]: reason || "Invalid password.",
      };
    } else {
      validationResult = {
        ...validationResult,
        [name]: "success",
      };
      errorTxtResult = {
        ...errorTxtResult,
        [name]: "",
      };
    }

    setValidation(validationResult);
    setErrorTxt(errorTxtResult);
    setDisabled(
      !["password", "confirmPassword"].every((prop) => !!newState[prop]) ||
        !["password", "confirmPassword"].every(
          (prop) => validationResult[prop] === "success",
        ) ||
        state?.code?.length !== 6,
    );
  };

  const handleInputSub = (event) => {
    if (event.target.value.length === 6) {
      setDisabled(
        false ||
          !["password", "confirmPassword"].every((prop) => !!state[prop]) ||
          !["password", "confirmPassword"].every(
            (prop) => validation[prop] === "success",
          ),
      );
    } else {
      setDisabled(true);
    }
    handleInput(event);
  };

  const resend = async () => {
    setLoading(true);
    await onResendClick();
    setLoading(false);
  };

  return (
    <>
      <div className="auth-title heading2">Reset Password</div>
      <div className="subtitle no-p">
        <Link to={ROUTES.LOGIN}>Back to Login</Link>
      </div>

      <div className="form-validation error">
        {validation.code === "error" && !!errorTxt.code && (
          <p className="v-text error">{errorTxt.code}</p>
        )}
      </div>

      <br />

      {/* <div className="auth-title heading2">Reset Password</div> */}

      <div className="form-validation error">
        {validation.password === "error" && !!errorTxt.password && (
          <p className="v-text error">{errorTxt.password}</p>
        )}
        {validation.confirmPassword === "error" &&
          !!errorTxt.confirmPassword && (
            <p className="v-text error">{errorTxt.confirmPassword}</p>
          )}
      </div>

      <FormInput
        controlId="resetPwd"
        label="New Password"
        type="password"
        name="password"
        placeholder=""
        errorTxt=""
        onChange={handleInput}
        checkPasswdStrength={true}
        pValidation={validation}
        pSetValidation={(check, reason, value) =>
          setPasswdValidation("password", check, reason, value)
        }
        reportPasswdCheck={setPasswordStrength}
        required
      />

      <FormInput
        controlId="resetConfirmPwd"
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        placeholder=""
        errorTxt=""
        pValidation={validation}
        onChange={handleInput}
        pSetValidation={(check, reason, value) =>
          setPasswdValidation("confirmPassword", check, reason, value)
        }
        required
      />

      <PasswordStrength passwdStrength={passwordStrength} />

      <Button
        className="btn btn-warning-gd"
        onClick={validateContinue}
        disabled={disabled}
      >
        Reset Password
      </Button>
    </>
  );
}
