import { NavBreadcrumb } from "components";

export function NavBreadcrumbList({ items }) {
  return (
    <ul className="status-label-list dark">
      {items.map((item) => (
        <NavBreadcrumb item={item} key={item.to} />
      ))}
    </ul>
  );
}
