import { useEffect, useState } from "react";

import { Spinner } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { CertificationService, toTitleCase } from "services";
import { LEVEL_NAMES, ROUTES } from "variables";

import { Issued } from "./issued";

const SAMPLE_DATA = {
  ABN: "12 345 678 910",
  id: "0124200000XXXXXXXXXXXI",
  name: "ACME Pty Ltd.",
  status: "COMPLETED",
  completedDate: "DD/MM/YYYY",
  expiredDate: "DD/MM/YYYY",
};

export function IssuedSamplePage() {
  const navigate = useNavigate();

  const { level } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [certRequirements, setCertRequirements] = useState();

  useEffect(() => {
    (async () => {
      const reqs = await CertificationService.getRequirementsForLevel(
        level.toLowerCase(),
      );

      setCertRequirements(reqs);
      setIsLoading(false);
    })();
  }, []);

  if (!LEVEL_NAMES.includes(toTitleCase(level.toLowerCase()))) {
    navigate(ROUTES[404]);
  }

  return (
    <>
      {isLoading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <Issued
        ABN={SAMPLE_DATA?.ABN}
        id={SAMPLE_DATA?.id}
        level={LEVEL_NAMES.indexOf(toTitleCase(level.toLowerCase())) + 1}
        name={SAMPLE_DATA?.name}
        reqs={certRequirements}
        status={SAMPLE_DATA?.status}
        completedDate={SAMPLE_DATA?.completedDate}
        expiredDate={SAMPLE_DATA?.expiredDate}
      />
    </>
  );
}
