import { configureStore } from "@reduxjs/toolkit";
import sessionSliceReducer from "./slices/sessionSlice";
import downloadUrlReducer from "./slices/downloadUrlSlice";
import loadingReducer from "./slices/loadingSlice";
import appReducer from "./slices/appSlice";
import confettiReducer from "./slices/confettiSlice";

export const store = configureStore({
  reducer: {
    appSession: sessionSliceReducer,
    downloadUrl: downloadUrlReducer,
    loading: loadingReducer,
    app: appReducer,
    confetti: confettiReducer,
  },
});
