import "./index.scss";

import { logoDark } from "assets";
import { Link } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

export function Footer() {
  return (
    <footer id="cybercert-footer">
      <div className="footer-content">
        <div className="footer-info">
          <div className="footer-between">
            <div className="logo-copy">
              <Link to={ROUTES.ROOT}>
                <img src={logoDark} alt="Logo" />
              </Link>

              <div className="copy-only">
                <span>CyberCert Pty Ltd</span>
                <span>ABN 87 662 681 423</span>
              </div>
            </div>

            <div className="footer-links">
              <div className="footer-link-item">
                <span className="item-title">CONTACT US</span>
                <span className="cursor-pointer" onClick={openZEWidget}>
                  Get in touch with us via our contact form.
                </span>
              </div>

              {/* <div className="footer-link-item">
                <span className="item-title">APAC</span>
                <span>
                  60 Martin Place, Sydney, NSW 2000 Australia
                </span>
              </div> */}

              <div className="footer-link-item">
                <span className="item-title">LEGAL</span>

                <div className="footer-a">
                  <a
                    href="https://cybercert.com.au/terms"
                    target="_blank"
                    className="hover:underline"
                  >
                    Terms of Use
                  </a>
                  <a
                    href="https://cybercert.com.au/privacy-policy"
                    target="_blank"
                    className="hover:underline"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="https://cybercert.com.au/cookie-policy"
                    target="_blank"
                    className="hover:underline"
                  >
                    Cookie Policy
                  </a>
                  {/* <Link className="hover:underline">CPS</Link> */}
                </div>
              </div>
            </div>
          </div>

          <div className="footer-text">
            <div>© Copyright 2023, All rights reserved.</div>
            <div>
              The cybercert name, logo, and badges are trademarks.
              <br />
              Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
