import Accordion from "react-bootstrap/Accordion";
import { LEVEL_ICONS, LEVEL_NAMES } from "variables";

import { Step1 } from "./steps/step-1";
import { Step2 } from "./steps/step-2";
import { Step3 } from "./steps/step-3";
import { Step4 } from "./steps/step-4";
import { Step6 } from "./steps/step-6";

export function CertificationWithoutAudit({
  certificationInfo,
  getCurrentStep,
  setCurrentStep,
  assessStep,
  isStepCompleted,
  progressData,
  resendSignRequest,
  level,
}) {
  return (
    <div className="certification-progress">
      <Accordion className="steps-accordion" activeKey={getCurrentStep()}>
        {/* <CertificationProgressHeader setCurrentStep={setCurrentStep} /> */}
        <div className="cert-step-0 acc-item heading">
          <img
            src={LEVEL_ICONS[level - 1]}
            alt={`${LEVEL_NAMES[level - 1]}.png`}
            width={32}
            height={32}
          />
          <h3>
            {["Bronze", "Silver", "Gold"][level - 1]}{" "}
            <span className="certmanager-level-subtitle">
              SMB1001:2023 Level {level}
            </span>
          </h3>
        </div>

        <Step1
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          done={isStepCompleted(1)}
          level={level}
          getCurrentStep={getCurrentStep}
        />

        <Step2
          certificationInfo={certificationInfo}
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          progressData={progressData}
          done={isStepCompleted(2)}
          level={level}
          getCurrentStep={getCurrentStep}
        />

        <Step3
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          progressData={progressData}
          done={isStepCompleted(3)}
          getCurrentStep={getCurrentStep}
        />

        <Step4
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          resendSignRequest={resendSignRequest}
          progressData={progressData}
          done={isStepCompleted(4)}
          getCurrentStep={getCurrentStep}
        />

        <Step6
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          done={isStepCompleted(6)}
          progressData={progressData}
          stepNo={5}
          level={level}
          getCurrentStep={getCurrentStep}
        />

        {/* <Step7
          assessStep={assessStep}
          setCurrentStep={setCurrentStep}
          done={isStepCompleted(7)}
          stepNo={6}
          level={level}
        /> */}
      </Accordion>
    </div>
  );
}
