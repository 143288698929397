export function StepIconArrow() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_i_5252_3696)">
                <ellipse cx="16.8041" cy="16.8041" rx="16.8041" ry="16.8041" fill="#D1FFDC" />
            </g>
            <path d="M14 11L19.8333 16.8333L14 22.6667" stroke="#28A745" stroke-width="2.97744" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
                <filter id="filter0_i_5252_3696" x="0" y="0" width="33.6082" height="33.6081" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5252_3696" />
                </filter>
            </defs>
        </svg>

    )
}