import { API } from "aws-amplify";

export const getProductPrice = ({ item }) => {
  const myInit = {
    queryStringParameters: {
      item,
    },
  };

  return API.get("stripe", `/product`, myInit);
};

export const getProductStaticPrice = ({ item }) => {
  const myInit = {
    queryStringParameters: {
      item,
      staticPrice: true,
    },
  };

  return API.get("stripe", `/product`, myInit);
};

export const getProductsPrice = () => {
  const myInit = {
    queryStringParameters: {
      item: "ALL",
    },
  };

  return API.get("stripe", `/product`, myInit);
};
