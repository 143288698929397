import { showPasswd } from "assets";
import { passwordStrength } from "check-password-strength";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Asterisk } from "../required-asterisk";

import "./form-input.scss";

const passwordStrengthCheckConfigs = [
  {
    id: 0,
    value: "Weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: "Weak",
    minDiversity: 0,
    minLength: 4,
  },
  {
    id: 3,
    value: "Medium",
    minDiversity: 0,
    minLength: 8,
  },
  {
    id: 4,
    value: "Medium",
    minDiversity: 2,
    minLength: 8,
  },
  {
    id: 5,
    value: "Strong",
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 6,
    value: "Strong",
    minDiversity: 2,
    minLength: 10,
  },
  {
    id: 7,
    value: "Strong",
    minDiversity: 4,
    minLength: 10,
  },
];

const ACCEPTED_PASSWD_STRENGTH_ID = 3;

export function FormPasswordInput({
  controlId,
  label,
  name,
  placeholder,
  onChange,
  required,
  disabled,
  successTxt = "",
  errorTxt = "Field is required.",
  checkStrength,
  reportCheck = () => {},
  pValidation,
  pSetValidation,
  autoComplete,
  usePlainHtml = false,
}) {
  let [validation, setValidation] = useState("NA");
  const [showPwd, setShowPwd] = useState(false);

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  function toggleShowPwd(event) {
    event.preventDefault();
    setShowPwd(!showPwd);
  }

  const validateInput = (event) => {
    if (!!event.target.value || event?.target?.value === "") {
      if (checkStrength) {
        const check = passwordStrength(
          event.target.value,
          passwordStrengthCheckConfigs
        );
        reportCheck({
          ...check,
          percent:
            ((check.id * 1.0) / (passwordStrengthCheckConfigs.length - 1)) *
            100.0,
        });
        if (check.id < ACCEPTED_PASSWD_STRENGTH_ID) {
          setValidation(
            "error",
            "Password is not strong enough.",
            event.target.value
          );
          onChange({
            ...event,
            passwdCheckFail: true,
          });
          return;
        } else {
          setValidation("success", undefined, event.target.value);
        }
      } else {
        setValidation("success", undefined, event.target.value);
      }
    } else {
      setValidation("error", undefined, event.target.value);
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>

      {usePlainHtml === true ? (
        <div className="pass-wrap">
          <input
            type={showPwd ? "text" : "password"}
            required
            name={name}
            autoComplete={autoComplete}
            placeholder={placeholder}
            onChange={validateInput}
            onBlur={validateInput}
            disabled={disabled}
          />

          <span className="js-password-toggle" onClick={toggleShowPwd}>
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.24219 5.24219C8.73698 4.7474 9.32292 4.5 10 4.5C10.6771 4.5 11.263 4.7474 11.7578 5.24219C12.2526 5.73698 12.5 6.32292 12.5 7C12.5 7.67708 12.2526 8.26302 11.7578 8.75781C11.263 9.2526 10.6771 9.5 10 9.5C9.32292 9.5 8.73698 9.2526 8.24219 8.75781C7.7474 8.26302 7.5 7.67708 7.5 7C7.5 6.32292 7.7474 5.73698 8.24219 5.24219ZM7.03125 9.96875C7.86458 10.776 8.85417 11.1797 10 11.1797C11.1458 11.1797 12.1224 10.776 12.9297 9.96875C13.763 9.13542 14.1797 8.14583 14.1797 7C14.1797 5.85417 13.763 4.8776 12.9297 4.07031C12.1224 3.23698 11.1458 2.82031 10 2.82031C8.85417 2.82031 7.86458 3.23698 7.03125 4.07031C6.22396 4.8776 5.82031 5.85417 5.82031 7C5.82031 8.14583 6.22396 9.13542 7.03125 9.96875ZM4.41406 2.46875C6.08073 1.32292 7.94271 0.75 10 0.75C12.0573 0.75 13.9193 1.32292 15.5859 2.46875C17.2526 3.61458 18.4505 5.125 19.1797 7C18.4505 8.875 17.2526 10.3854 15.5859 11.5312C13.9193 12.6771 12.0573 13.25 10 13.25C7.94271 13.25 6.08073 12.6771 4.41406 11.5312C2.7474 10.3854 1.54948 8.875 0.820312 7C1.54948 5.125 2.7474 3.61458 4.41406 2.46875Z"
                fill="#C6C0C0"
              ></path>
            </svg>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="14"
              viewBox="0 0 448 448"
            >
              <path
                d="M138.75 333.75l19.5-35.25c-29-21-46.25-54.75-46.25-90.5 0-19.75 5.25-39.25 15.25-56.25-39 20-71.5 51.5-95.25 88.25 26 40.25 62.75 74 106.75 93.75zM236 144c0-6.5-5.5-12-12-12-41.75 0-76 34.25-76 76 0 6.5 5.5 12 12 12s12-5.5 12-12c0-28.75 23.5-52 52-52 6.5 0 12-5.5 12-12zM326.75 96.25c0 0.5 0 1.75-0.25 2.25-52.75 94.25-105 189-157.75 283.25l-12.25 22.25c-1.5 2.5-4.25 4-7 4-4.5 0-28.25-14.5-33.5-17.5-2.5-1.5-4-4-4-7 0-4 8.5-17.5 11-21.75-48.5-22-89.25-59.5-118-104.5-3.25-5-5-11-5-17.25 0-6 1.75-12.25 5-17.25 49.5-76 126.75-126.75 219-126.75 15 0 30.25 1.5 45 4.25l13.5-24.25c1.5-2.5 4-4 7-4 4.5 0 28 14.5 33.25 17.5 2.5 1.5 4 4 4 6.75zM336 208c0 46.5-28.75 88-72 104.5l70-125.5c1.25 7 2 14 2 21zM448 240c0 6.5-1.75 11.75-5 17.25-7.75 12.75-17.5 25-27.25 36.25-49 56.25-116.5 90.5-191.75 90.5l18.5-33c72.75-6.25 134.5-50.5 173.5-111-18.5-28.75-42.25-54-70.5-73.5l15.75-28c31 20.75 62.25 52 81.75 84.25 3.25 5.5 5 10.75 5 17.25z"
                fill="#C6C0C0"
              ></path>
            </svg>
          </span>
        </div>
      ) : (
        <Form.Group
          className="mb-3 full-input passwd-input-group"
          controlId={controlId}
        >
          <Form.Label className="form-label">
            <div>{label}</div>
            {required && <Asterisk />}
          </Form.Label>

          <div className="passwd-input">
            <Form.Control
              className="form-input"
              type={showPwd ? "text" : "password"}
              name={name}
              autoComplete={autoComplete}
              placeholder={placeholder}
              onChange={validateInput}
              onBlur={validateInput}
              disabled={disabled}
            />

            {disabled ? (
              <></>
            ) : (
              <a
                className="show-passwd-btn"
                onClick={toggleShowPwd}
                tabIndex="-1"
              >
                <img src={showPasswd} alt="cscau-show-password" />
                {/* Show */}
              </a>
            )}
          </div>
        </Form.Group>
      )}
    </div>
  );
}
