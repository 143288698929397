import { useEffect, useState } from "react";

import { getAssessmentQuestionnaire, getProductStaticPrice } from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import { AssessmentResultLevel, NavBreadcrumbList, Spinner } from "components";
import { useLoadingState } from "middleware";
import { CERTIFICATION_INFO, ROUTES } from "variables";

export function Upgrades() {
  const [currentLevel, setCurrentLevel] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [prices, setPrices] = useState([]);
  const { loading, setLoading } = useLoadingState();
  const [assessment, setAssessment] = useState("NOT-SET");
  const [howToQualifyLevel, setHowToQualifyLevel] = useState();

  useEffect(() => {
    (async () => {
      const a = await getAssessmentQuestionnaire();

      setAssessment(a);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const certInfo = await getCertificationInfo();
      const certProgress = certInfo?.customer?.certification?.progress;
      setCurrentLevel(certProgress?.level);
      setCurrentStatus(certProgress?.status);

      const staticPrices = (
        await Promise.all(
          // [1, 2, 3, 4, 5]
          [1, 2, 3].map((l) =>
            getProductStaticPrice({
              item: `LEVEL_${l}`,
            }),
          ),
        )
      ).map((p) => p.price);

      setPrices(staticPrices);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <div className="dashboard-header">
        <div className="container-md">
          <div className="df-row">
            <div className="col-wl">
              <div className="card-wl assessment">
                <NavBreadcrumbList
                  items={[
                    { to: ROUTES.ROOT, name: "Dashboard" },
                    {
                      to: ROUTES.UPGRADES,
                      name: "Upgrade Options",
                    },
                  ]}
                />
                {!!currentLevel && (
                  <div className="panel-quiz-header">
                    <div className="panel-header">
                      {/* <div className="progress-bar">
                        <span></span>
                      </div> */}

                      <>
                        <div className="panel-steps">
                          <div className="panel-steps-header">
                            <div className="heading">
                              <h4 className="upgrade-small-title">
                                UPGRADE YOUR CERTIFICATION
                              </h4>
                              <h1 className="mb-0-upgrade">
                                Your organisation currently has an active Level{" "}
                                {currentLevel} -{" "}
                                {
                                  CERTIFICATION_INFO[currentLevel]
                                    ?.marketingName
                                }{" "}
                                certification.
                              </h1>
                            </div>
                          </div>
                          <div className="label-block-holder">
                            <AssessmentResultLevel
                              howToQualifyLevel={howToQualifyLevel}
                              setHowToQualifyLevel={setHowToQualifyLevel}
                              level={currentLevel}
                              currentLevel={currentLevel}
                              currentStatus={currentStatus}
                              price={prices[currentLevel - 1]}
                            />
                          </div>
                        </div>
                        {/* {[1, 2, 3, 4, 5] */}
                        {[1, 2, 3].filter((l) => l > currentLevel).length >
                          0 && (
                          <div className="panel-steps">
                            <div className="subtitle-results">
                              <div className="heading">
                                <h1>Upgrade to a higher certification</h1>
                                <p className="font-normal">
                                  Click on "How to qualify" to see additional
                                  requirements you need to implement to qualify
                                  for the higher level certifications.
                                </p>
                              </div>
                            </div>
                            <div className="label-block-holder">
                              {/* {[1, 2, 3, 4, 5] */}
                              {[1, 2, 3]
                                .filter((l) => l > currentLevel)
                                .map((l) => (
                                  <AssessmentResultLevel
                                    howToQualifyLevel={howToQualifyLevel}
                                    setHowToQualifyLevel={setHowToQualifyLevel}
                                    result={assessment?.result}
                                    eligibleRequirements={
                                      assessment?.eligibleRequirements
                                    }
                                    level={l}
                                    currentLevel={currentLevel}
                                    currentStatus={currentStatus}
                                    price={prices[l - 1]}
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
