import { useEffect } from "react";

export function useClickOutsideHook({
  ref,
  clickedout = (ref, target) => !ref.current.contains(target),
  onClickedout = () => {},
}) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && clickedout(ref, event.target)) {
        onClickedout();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
