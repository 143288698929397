import { FormCheckboxInput } from "./form-input-checkbox";
import { FormInputCode } from "./form-input-code";
import { FormDropdownInput } from "./form-input-dropdown";
import { FormEmailInput } from "./form-input-email";
import { FormInputGroup } from "./form-input-group";
import { FormPasswordInput } from "./form-input-password";
import { FormInputSearch } from "./form-input-search";
import { FormTextInput } from "./form-input-text";
import { FormTextareaInput } from "./form-input-textarea";

export function FormInput({
  controlId,
  options,
  label,
  type,
  name,
  placeholder,
  length,
  maxLength,
  onChange = () => {},
  onBlur = () => {},
  required,
  value,
  children,
  disabled,
  searchFn,
  successTxt,
  errorTxt,
  pValidation,
  pSetValidation,
  cols,
  rows,
  autoComplete = "",
  setRef,
  onKeyPress,
  onPaste,
  setFocus,
  checkPasswdStrength,
  reportPasswdCheck,
  usePlainHtml = false,
}) {
  return (
    <>
      {
        {
          text: (
            <FormTextInput
              controlId={controlId}
              label={label}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              onChange={onChange}
              required={required}
              disabled={disabled}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
              autoComplete={autoComplete}
              setRef={setRef}
              onKeyPress={onKeyPress}
              onPaste={onPaste}
            />
          ),
          password: (
            <FormPasswordInput
              controlId={controlId}
              label={label}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              required={required}
              disabled={disabled}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
              autoComplete={autoComplete}
              checkStrength={checkPasswdStrength}
              reportCheck={reportPasswdCheck}
              usePlainHtml={usePlainHtml}
            />
          ),
          email: (
            <FormEmailInput
              controlId={controlId}
              label={label}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              onChange={onChange}
              onBlur={onBlur}
              required={required}
              disabled={disabled}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
              autoComplete={autoComplete}
              setFocus={setFocus}
            />
          ),
          checkbox: (
            <FormCheckboxInput
              controlId={controlId}
              name={name}
              onChange={onChange}
              disabled={disabled}
            >
              {children}
            </FormCheckboxInput>
          ),
          search: (
            <FormInputSearch
              controlId={controlId}
              label={label}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              required={required}
              searchFn={searchFn}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
              autoComplete={autoComplete}
              onKeyPress={onKeyPress}
              setRef={setRef}
            ></FormInputSearch>
          ),
          select: (
            <FormDropdownInput
              controlId={controlId}
              options={options}
              label={label}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              onChange={onChange}
              required={required}
              disabled={disabled}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
            ></FormDropdownInput>
          ),
          group: (
            <FormInputGroup
              controlId={controlId}
              onChange={onChange}
              required={required}
              children={children}
            />
          ),
          code: (
            <FormInputCode
              controlId={controlId}
              label={label}
              name={name}
              value={value}
              length={length}
              onChange={onChange}
              required={required}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
            />
          ),
          textarea: (
            <FormTextareaInput
              controlId={controlId}
              label={label}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              maxLength={maxLength}
              required={required}
              disabled={disabled}
              successTxt={successTxt}
              errorTxt={errorTxt}
              pValidation={pValidation}
              pSetValidation={pSetValidation}
              autoComplete={autoComplete}
              rows={rows}
              cols={cols}
            />
          ),
        }[type]
      }
    </>
  );
}
