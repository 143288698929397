import { useEffect, useState } from "react";

import { getCertificationInfo } from "api/functions/certification/info";
import { ArrowNext, ArrowPrev } from "assets";
import { AssessmentResultLevel } from "components";
import { useLoadingState } from "middleware";

export function UpgradeBlock({ prices = [], assessment }) {
  const [currentLevel, setCurrentLevel] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [showLevel, setShowLevel] = useState();
  const { setLoading } = useLoadingState();
  const [howToQualifyLevel, setHowToQualifyLevel] = useState();

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    setLoading(true);
    const certificationInfo = await getCertificationInfo();
    setCurrentLevel(
      certificationInfo?.customer?.certification?.progress?.level || 0,
    );
    setCurrentStatus(
      certificationInfo?.customer?.certification?.progress?.status,
    );
    setShowLevel(
      (certificationInfo?.customer?.certification?.progress?.level || 0) + 1,
    );
    setLoading(false);
  };

  return (
    <>
      {/* {currentLevel < 5 && ( */}
      {currentLevel < 3 && (
        <div className="container-md pb-56">
          <div className="upgrade-block-container">
            <div className="panel-header">
              {/* <div className="progress-bar">
                <span></span>
              </div> */}

              <>
                <div className="panel-steps">
                  <div className="panel-steps-header">
                    <div className="heading">
                      <div className="f-row-spread">
                        <div className="small-block-title">
                          <h4>UPGRADE YOUR CERTIFICATION</h4>
                        </div>
                        <div className="lvl-btns">
                          {showLevel > currentLevel + 1 && (
                            <img
                              src={ArrowPrev}
                              alt="prev"
                              onClick={() => {
                                setShowLevel(Math.max(0, showLevel - 1));
                                setHowToQualifyLevel(null);
                              }}
                            />
                          )}
                          {/* {showLevel < 5 && ( */}
                          {showLevel < 3 && (
                            <img
                              src={ArrowNext}
                              alt="prev"
                              onClick={() => {
                                setShowLevel(Math.min(5, showLevel + 1));
                                setHowToQualifyLevel(null);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <h1>Upgrade to a higher certification</h1>
                      <p className="font-normal">
                        Click on "How to qualify" to see additional requirements
                        you need to implement to qualify for the higher level
                        certifications.
                      </p>
                    </div>
                  </div>
                  <div className="label-block-holder">
                    {/* {[1, 2, 3, 4, 5] */}
                    {[1, 2, 3]
                      .filter((l) => l > currentLevel && l === showLevel)
                      .map((l, index) => (
                        <AssessmentResultLevel
                          key={index}
                          result={assessment?.result}
                          level={l}
                          currentLevel={currentLevel}
                          currentStatus={currentStatus}
                          eligibleRequirements={
                            assessment?.eligibleRequirements
                          }
                          price={prices[l - 1]}
                          showLevel={showLevel}
                          howToQualifyLevel={howToQualifyLevel}
                          setHowToQualifyLevel={setHowToQualifyLevel}
                        />
                      ))}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
