import "./badge.scss";

import { LEVEL_ICONS, LEVEL_NAMES } from "variables";

export function Badge({ level, bcolor }) {
  return (
    <div className="badge-holder">
      <div className="badge">
        {!!level && (
          <>
            <img
              src={LEVEL_ICONS[level - 1]}
              alt={`${LEVEL_NAMES[level - 1]}.png`}
            />

            <span>{LEVEL_NAMES[level - 1]}</span>
          </>
        )}
      </div>
    </div>
  );
}
