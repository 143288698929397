import { useEffect, useState } from "react";

import { getProductStaticPrice, openProductCheckoutSession } from "api";
import {
  difficultyLevel1,
  difficultyLevel2,
  difficultyLevel3,
  difficultyLevel4,
  difficultyLevel5,
  LockIcon,
  ReqIcon,
} from "assets";
import { AssessmentRequirementDetail } from "components";
import { useAppState, useLoadingState } from "middleware";
import { Button, Modal } from "react-bootstrap";
import { CertificationService, sortRequirements } from "services";
import { BADGES, CERTIFICATION_INFO } from "variables";

const difficulties = [
  "",
  difficultyLevel1,
  difficultyLevel2,
  difficultyLevel3,
  difficultyLevel4,
  difficultyLevel5,
];

const initialRequirements = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  total: 0,
};

export function AssessmentBlankStateLevel({
  level,
  howToQualifyLevel,
  setHowToQualifyLevel = () => {},
}) {
  const [requirements, setRequirements] = useState(initialRequirements);
  const [price, setPrice] = useState();
  const [showHow, setShowHow] = useState(false);
  const { setLoading } = useLoadingState();
  const [showExplainReq, setShowExplainReq] = useState(false);
  const [explainReq, setExplainReq] = useState();
  const { app } = useAppState();

  useEffect(() => {
    if (howToQualifyLevel !== level) {
      setShowHow(false);
    }
  }, [howToQualifyLevel]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (level >= 1 && level <= 3) {
        // <= 5
        let rs = await CertificationService.getRequirementsForLevel(level);

        let newRequirements = initialRequirements;
        rs.forEach((r) => {
          newRequirements = {
            ...newRequirements,
            [r.baseId.charAt(0)]: [
              ...newRequirements[r.baseId.charAt(0)],
              {
                requirementId: r.requirementId,
                title: r.title,
                baseId: r.baseId,
              },
            ],
          };
        });
        [1, 2, 3, 4, 5].forEach(
          (i) => (newRequirements[i] = sortRequirements(newRequirements[i])),
        );
        newRequirements.total = rs.length;
        setRequirements(newRequirements);

        setPrice(
          (
            await getProductStaticPrice({
              item: `LEVEL_${level}`,
            })
          )?.price,
        );
      }
      setLoading(false);
    })();
  }, []);

  const upgrade = async () => {
    setLoading(true);

    if (level < 4 || app?.current?.enableDirectPurchaseL45 === true) {
      const item = `LEVEL_${level}`;

      await openProductCheckoutSession({
        item,
        success_url: `${window.location.origin}?payment_intent=success`,
        cancel_url: window.location.origin,
      });
    }

    setLoading(false);
  };

  const explainRequirement = (req) => {
    setExplainReq(req);
    setShowExplainReq(true);
  };

  const handleCloseExplainReq = () => {
    setShowExplainReq(false);
    setExplainReq(null);
  };

  return (
    <>
      <div className={`label-block`}>
        <div className="inner cert-blocks-flex">
          {level === 3 && <div className="label normal">Most Popular</div>}

          <div className="text-side">
            <div className="logo-block">
              <img
                src={BADGES[level || 0]}
                width="878"
                height="117"
                alt="level 5"
              />
            </div>
            <div className="text">
              <h2 className="text-title">
                {CERTIFICATION_INFO[level]?.marketingName || ""}
              </h2>
              <span className="text-subtitle">
                <img src={ReqIcon} alt="Requirement count icon" />
                {requirements?.total || 0} REQUIREMENTS
              </span>
              <p>{CERTIFICATION_INFO[level]?.description || ""}</p>
            </div>
          </div>
          <div className="price-side">
            <div className="price-holder">
              <span className="price">${price || 0}</span>
              <span className="comment">Price excludes GST</span>
            </div>

            <HowToQualifyBtn
              setShowHow={() => {
                setShowHow(!showHow);
                setHowToQualifyLevel(level);
              }}
            />
          </div>
        </div>

        {showHow && (
          <div className="collapse-block">
            <div className="collapse-header">
              <div className="text">
                <h3>
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                      fill="black"
                    />
                  </svg>
                  How to qualify
                </h3>
                <p>
                  Implement the following{" "}
                  <strong>{requirements?.total} requirements</strong> (full
                  details in your workbook) in your organisation to qualify for
                  a {CERTIFICATION_INFO[level]?.marketingName} Certification.
                </p>
              </div>
              <ul className="tab-list-info">
                <li>
                  <div className="difficulty">
                    <p>Difficulty: </p>
                    <img
                      src={difficulties[level || 0]}
                      alt="Difficulty Level"
                    />
                  </div>
                </li>
                <li>
                  <p>Valid for</p>
                  <p>
                    <strong>
                      {CERTIFICATION_INFO[level]?.validFor} Months
                    </strong>
                  </p>
                </li>
                <li>
                  <p>Assurance level</p>
                  <p>
                    <strong>{CERTIFICATION_INFO[level]?.assuranceLevel}</strong>
                  </p>
                </li>
              </ul>
            </div>
            <div className="list-holder">
              {requirements[1].length > 0 && (
                <div className="list-item">
                  <strong>Technology Management</strong>
                  <ul>
                    {requirements[1].map((requirement) => (
                      <AssessmentRequirementDetail
                        explainRequirement={explainRequirement}
                        requirement={requirement}
                      />
                    ))}
                  </ul>
                </div>
              )}
              {requirements[2].length > 0 && (
                <div className="list-item">
                  <strong>Access Management</strong>
                  <ul>
                    {requirements[2].map((requirement) => (
                      <AssessmentRequirementDetail
                        explainRequirement={explainRequirement}
                        requirement={requirement}
                      />
                    ))}
                  </ul>
                </div>
              )}

              {requirements[3].length > 0 && (
                <div className="list-item">
                  <strong>Backup & Recovery</strong>
                  <ul>
                    {requirements[3].map((requirement) => (
                      <AssessmentRequirementDetail
                        explainRequirement={explainRequirement}
                        requirement={requirement}
                      />
                    ))}
                  </ul>
                </div>
              )}

              {requirements[4].length > 0 && (
                <div className="list-item">
                  <strong>Policies, Plans & Procedures</strong>
                  <ul>
                    {requirements[4].map((requirement) => (
                      <AssessmentRequirementDetail
                        explainRequirement={explainRequirement}
                        requirement={requirement}
                      />
                    ))}
                  </ul>
                </div>
              )}

              {requirements[5].length > 0 && (
                <div className="list-item">
                  <strong>Education & Training</strong>
                  <ul>
                    {requirements[5].map((requirement) => (
                      <AssessmentRequirementDetail
                        explainRequirement={explainRequirement}
                        requirement={requirement}
                      />
                    ))}
                  </ul>
                </div>
              )}

              {level < 4 || app?.current?.enableDirectPurchaseL45 === true ? (
                <a className="btn btn-success" onClick={upgrade}>
                  Get {CERTIFICATION_INFO[level]?.marketingName} Certified
                </a>
              ) : (
                <a className="l3-needed">
                  <img src={LockIcon} alt="Locked Icon" />
                  Complete Gold to unlock this level
                </a>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        className="attestation-modal"
        show={showExplainReq}
        onHide={handleCloseExplainReq}
      >
        <Modal.Header closeButton>
          <Modal.Title className="step-2-confirm-title">
            What does this mean?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="step2-modal">
          <p>
            Requirement {explainReq?.requirementId} is an upgraded version of
            Requirement {explainReq?.baseId}, encompassing a more intricate set
            of criteria that demands heightened security measures for attaining
            a higher level certification.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="step-2-confirm-btn"
            variant="secondary"
            onClick={handleCloseExplainReq}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function HowToQualifyBtn({ setShowHow }) {
  return (
    <a className="btn btn-blue" onClick={setShowHow}>
      How to qualify
      <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4375 0.71875L4.5 3.78125L7.5625 0.71875L8.5 1.65625L4.5 5.65625L0.5 1.65625L1.4375 0.71875Z"
          fill="white"
        />
      </svg>
    </a>
  );
}
