import { API } from "aws-amplify";

export const submitDeed = async ({ email }) => {
  const myInit = {
    body: {
      email,
    },
  };
  const result = await API.post("attestation", "/submit", myInit);
  return result;
};

export const getDeed = async (envelopeId) => {
  const myInit = {
    queryStringParameters: {
      envelopeId,
    },
  };

  const result = await API.get(
    "attestation",
    `/?envelopeId=${envelopeId}`,
    myInit,
  );
  return result;
};
