import spinnerJson from "./animations/json/spinner.json";
import Illustration404 from "./images/404-illustration.svg";
import ArrowNextSvg from "./images/arrow-next.svg";
import ArrowPrevSvg from "./images/arrow-prev.svg";
import ArrowForward from "./images/arrow_forward.svg";
import authBannerSvg from "./images/auth-banner.svg";
import avatarSvg from "./images/avatar.png";
import BadgeBlankPng from "./images/badge-level-0-blank.png";
import BadgeBronzePng from "./images/badge-level1-bronze.png";
import BadgeSilverPng from "./images/badge-level2-silver.png";
import BadgeGoldPng from "./images/badge-level3-gold.png";
import BadgePlatinumPng from "./images/badge-level4-platinum.png";
import BadgeDiamondPng from "./images/badge-level5-diamond.png";
import certificate1Svg from "./images/certificate-01.svg";
import certificate2Svg from "./images/certificate-02.svg";
import certificate3Svg from "./images/certificate-03.svg";
import certificationGuideSvg from "./images/certification-guide.jpg";
import checkIconSvg from "./images/check-icon.svg";
import checkInProgressSvg from "./images/check-in-progress-icon.svg";
import corporateIconSvg from "./images/corporate-icon.svg";
import CSCAULogoPng from "./images/cscau.png";
import difficultyLevel1Svg from "./images/difficulty-level-01.svg";
import difficultyLevel2Svg from "./images/difficulty-level-02.svg";
import difficultyLevel3Svg from "./images/difficulty-level-03.svg";
import difficultyLevel4Svg from "./images/difficulty-level-04.svg";
import difficultyLevel5Svg from "./images/difficulty-level-05.svg";
import FacebookIcon from "./images/facebook.png";
import facebookIcon from "./images/facebook.svg";
import failIconSvg from "./images/fail-icon.svg";
import flagSvg from "./images/flag.svg";
import GoogleIcon from "./images/google.png";
import googleIcon from "./images/google.svg";
import helpdeskIconSvg from "./images/helpdesk-icon.svg";
import IconBlankPng from "./images/icon-level-0-blank.png";
import IconBronzePng from "./images/icon-level-1-bronze.png";
import IconSilverPng from "./images/icon-level-2-silver.png";
import IconGoldPng from "./images/icon-level-3-gold.png";
import IconPlatinumPng from "./images/icon-level-4-platinum.png";
import IconDiamondPng from "./images/icon-level-5-diamond.png";
import LockedIcon from "./images/lock_outline.svg";
import logoDarkSvg from "./images/logo-secondary.svg";
import logoSvg from "./images/logo.svg";
import maintenanceIconPng from "./images/maintenance.png";
import noCertImageSvg from "./images/no-certification-image.svg";
import IllustrationNoMobile from "./images/nomobile-illustration.svg";
import NotifL1Img from "./images/notif-L1.png";
import NotifL2Img from "./images/notif-L2.png";
import NotifL3Img from "./images/notif-L3.png";
import NotifL4Img from "./images/notif-L4.png";
import NotifL5Img from "./images/notif-L5.png";
import OfflineImg from "./images/offline.svg";
import ReqCountIcon from "./images/req-count.svg";
import SampleWatermarkPng from "./images/sample-watermark.png";
import SearchAssessmentIcon from "./images/search-assessment.svg";
import searchIconSvg from "./images/search-icon.svg";
import selectArrowSvg from "./images/select-arrow.svg";
import showPwdSvg from "./images/show-passwd.svg";
import successIconSvg from "./images/success-icon.svg";
import tagAlertIconSvg from "./images/tag-alert-icon.svg";
import tagInfoIconSvg from "./images/tag-info-icon.svg";
import UpgradeSvg from "./images/upgrade.svg";
import workbookCoverBronzePng from "./images/workbook-cover-level1-bronze.png";
import workbookCoverSilverPng from "./images/workbook-cover-level2-silver.png";
import workbookCoverGoldPng from "./images/workbook-cover-level3-gold.png";
import workbookCoverPlatinumPng from "./images/workbook-cover-level4-platinum.png";
import workbookCoverDiamondPng from "./images/workbook-cover-level5-diamond.png";

// import bronzeLoopWebm from "./animations/bronze.webm";
// import silverLoopWebm from "./animations/silver.webm";
// import goldLoopWebm from "./animations/gold.webm";
// import platinumLoopWebm from "./animations/platinum.webm";
// import diamondLoopWebm from "./animations/diamond.webm";

export const logo = logoSvg;
export const logoDark = logoDarkSvg;
export const authBanner = authBannerSvg;
export const showPasswd = showPwdSvg;
export const corporateIcon = corporateIconSvg;
export const tagInfoIcon = tagInfoIconSvg;
export const tagAlertIcon = tagAlertIconSvg;
export const noCertImage = noCertImageSvg;
export const helpdeskIcon = helpdeskIconSvg;
export const searchIcon = searchIconSvg;
export const successIcon = successIconSvg;
export const failIcon = failIconSvg;
export const BadgeBlank = BadgeBlankPng;
export const BadgeBronze = BadgeBronzePng;
export const BadgeSilver = BadgeSilverPng;
export const BadgeGold = BadgeGoldPng;
export const BadgePlatinum = BadgePlatinumPng;
export const BadgeDiamond = BadgeDiamondPng;
export const certificate01 = certificate1Svg;
export const certificate02 = certificate2Svg;
export const certificate03 = certificate3Svg;
export const flag = flagSvg;
export const difficultyLevel1 = difficultyLevel1Svg;
export const difficultyLevel2 = difficultyLevel2Svg;
export const difficultyLevel3 = difficultyLevel3Svg;
export const difficultyLevel4 = difficultyLevel4Svg;
export const difficultyLevel5 = difficultyLevel5Svg;
export const IconBlank = IconBlankPng;
export const IconBronze = IconBronzePng;
export const IconSilver = IconSilverPng;
export const IconGold = IconGoldPng;
export const IconPlatinum = IconPlatinumPng;
export const IconDiamond = IconDiamondPng;
export const checkIcon = checkIconSvg;
export const selectArrow = selectArrowSvg;
export const certificationGuide = certificationGuideSvg;
export const avatar = avatarSvg;
export const checkInProgressIcon = checkInProgressSvg;
export const google = googleIcon;
export const facebook = facebookIcon;
export const Img404 = Illustration404;
export const ArrowFwd = ArrowForward;
export const Offline = OfflineImg;
export const spinner = spinnerJson;
export const ReqIcon = ReqCountIcon;
export const LockIcon = LockedIcon;
export const ImgNoMobile = IllustrationNoMobile;
export const GoogleLogo = GoogleIcon;
export const Fblogo = FacebookIcon;
export const CSCAULogo = CSCAULogoPng;
export const SampleWatermark = SampleWatermarkPng;
export const SearchAssessment = SearchAssessmentIcon;
export const NotifL1 = NotifL1Img;
export const NotifL2 = NotifL2Img;
export const NotifL3 = NotifL3Img;
export const NotifL4 = NotifL4Img;
export const NotifL5 = NotifL5Img;
export const ArrowNext = ArrowNextSvg;
export const ArrowPrev = ArrowPrevSvg;
export const UpgradeIcon = UpgradeSvg;
export const WorkbookCoverBronze = workbookCoverBronzePng;
export const WorkbookCoverSilver = workbookCoverSilverPng;
export const WorkbookCoverGold = workbookCoverGoldPng;
export const WorkbookCoverPlatinum = workbookCoverPlatinumPng;
export const WorkbookCoverDiamond = workbookCoverDiamondPng;
export const maintenanceIcon = maintenanceIconPng;
// export const bronzeLoop = bronzeLoopWebm;
// export const silverLoop = silverLoopWebm;
// export const goldLoop = goldLoopWebm;
// export const platinumLoop = platinumLoopWebm;
// export const diamondLoop = diamondLoopWebm;

export * from "./animations";
