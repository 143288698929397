import { useLoadingState } from "middleware";
import { Link } from "react-router-dom";
import { ROUTES } from "variables";

export function RankBlock({
  assessment,
  blankState = true,
  hideSkipBlock = false,
}) {
  const { setLoading } = useLoadingState();

  return (
    <>
      <div
        // className={blankState ? "panel-quiz-header mb-56" : "panel-quiz-header"}
        className="panel-quiz-header"
      >
        <div className="panel-header">
          <div
            className={blankState ? "progress-bar green-focus" : "progress-bar"}
          >
            <span></span>
          </div>
          <div className="panel-steps">
            <div className="panel-steps-header">
              <div className="heading">
                {blankState ? (
                  <>
                    <h4 className="text-green-focus">START HERE</h4>
                    <div className="icon-title">
                      {/* <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.1094 0C18.7988 0 24.2188 5.41992 24.2188 12.1094C24.2188 18.7988 18.7988 24.2188 12.1094 24.2188C5.41992 24.2188 0 18.7988 0 12.1094C0 5.41992 5.41992 0 12.1094 0ZM10.6982 7.01172L14.3848 10.5469H5.46875C4.81934 10.5469 4.29688 11.0693 4.29688 11.7188V12.5C4.29688 13.1494 4.81934 13.6719 5.46875 13.6719H14.3848L10.6982 17.207C10.2246 17.6611 10.2148 18.418 10.6787 18.8818L11.2158 19.4141C11.6748 19.873 12.417 19.873 12.8711 19.4141L19.3506 12.9395C19.8096 12.4805 19.8096 11.7383 19.3506 11.2842L12.8711 4.7998C12.4121 4.34082 11.6699 4.34082 11.2158 4.7998L10.6787 5.33203C10.2148 5.80078 10.2246 6.55762 10.6982 7.01172Z"
                          fill="#28a745"
                        />
                      </svg> */}
                      <h1>What certification is right for us?</h1>
                    </div>
                  </>
                ) : (
                  <>
                    <h4>CERTIFICATION RECOMMENDATION TOOL</h4>
                    <h1>See where you rank.</h1>
                  </>
                )}

                <p>
                  Complete a short recommendation tool to find out. It’s simple
                  and quick!
                </p>

                {!!assessment?.progress && assessment?.progress.length > 0 ? (
                  <Link
                    className="btn btn-warning pulse"
                    to={ROUTES.ASSESSMENT_TOOL}
                  >
                    Continue
                  </Link>
                ) : (
                  <Link
                    className="btn btn-warning pulse"
                    to={`${ROUTES.ASSESSMENT_TOOL}?start=true`}
                    onClick={() => setLoading(true)}
                  >
                    Start
                  </Link>
                )}
              </div>
            </div>

            {!hideSkipBlock && (
              <div className="label-block-holder mt-30">
                <div className="label-block">
                  <div className="inner">
                    <div className="options-heading">
                      <h4 className="text-green-focus">OR SKIP AHEAD</h4>

                      <h1 className="skipTitle">
                        Choose your certification level.
                      </h1>

                      <p className="skipBody">
                        Skip the recommendation tool and choose your
                        certification level.
                      </p>
                    </div>

                    <Link
                      className="skipSelect"
                      to={`${ROUTES.OPTIONS}`}
                      onClick={() => setLoading(true)}
                    >
                      Select
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
