// import { BuyDownload } from "./buydownload";
import "./progress.scss";

import { useEffect, useState } from "react";

import {
  getIssuedBadge,
  getSignedAttestationDownloadUrl,
  getSpecificLevelCertificateDownloadUrl,
  onUpdateProgress,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import { Badge, CertificateProgress, Spinner, StepCounter } from "components";
import { useDownloadUrlState } from "middleware";
import { isExpired, parseDate } from "services";
import { ROUTES } from "variables";

const initialState = {
  progress: undefined,
};
export function Progress({ viewDetails, viewProgress, stepComplete }) {
  const [progressState, setProgressState] = useState(initialState);
  const { downloadUrl, setDownloadUrl } = useDownloadUrlState();

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (!!progressState.progress) {
      return onUpdateProgress({
        id: progressState.progress.id,
        callbackFn: (progress) => {
          setProgressState({
            ...progressState,
            progress: {
              ...progressState?.progress,
              ...progress,
            },
          });
          if (progress.step >= 5 && downloadUrl.signedDeedLink === "/") {
            getSignedAttestationDownloadUrl().then((result) => {
              setDownloadUrl({
                signedDeedLink: result,
              });
            });
          }
          if (
            progress?.status === "COMPLETED" &&
            downloadUrl.certificateLink === "/"
          ) {
            getSpecificLevelCertificateDownloadUrl(progress?.level).then(
              (result) => {
                setTimeout(() => {
                  setDownloadUrl({
                    certificateLink: result,
                  });
                }, 2000);
              },
            );
          }
          if (progress?.status === "COMPLETED" && downloadUrl.badges === "/") {
            setTimeout(() => {
              getIssuedBadge({
                level: progress?.level,
              }).then((result) => {
                setDownloadUrl({
                  badges: result,
                });
              });
            }, 5000);
          }
        },
      });
    }
  }, [progressState]);

  const setup = async () => {
    const certificationInfo = await getCertificationInfo();
    setProgressState({
      ...progressState,
      progress: {
        ...certificationInfo?.customer?.certification?.progress,
        totalStep: certificationInfo?.customer?.certification?.totalStep,
      },
    });
  };

  const render = (progress) => {
    if (progress?.status === "COMPLETED" || stepComplete === true) {
      return (
        <div className="card-progress completed">
          <div className="card-text">
            <div className="progress-block">
              <strong>Certification progress</strong>
              <div className="number completed">100%</div>
              {/* <strong>Completed certification</strong>
              <strong className="level">
                <span className="bold">Level {progress?.level}</span>
                <div className="status-label-list">ADVANCED</div>
              </strong> */}
            </div>
            <div className="step">
              <div className="date">
                <strong>Valid to</strong>
                <time className="bold">
                  {parseDate(progress?.expiredDate) !== "NA" ? (
                    parseDate(progress?.expiredDate)
                  ) : (
                    <>
                      <Spinner />
                    </>
                  )}
                </time>
              </div>
            </div>
          </div>

          {isExpired(progress?.expiredDate) ? (
            <Badge level={progress?.level} bcolor={"red"}></Badge>
          ) : (
            <Badge level={progress?.level} bcolor={"#1D9C3A"} />
          )}

          {/* <BuyDownload guide={guide} /> */}
          <CertificateProgress
            heading={`Your certification ${
              isExpired(progress?.expiredDate) ? "has expired" : "is active"
            }`}
            status="View Details"
            link={() => {
              if (!!viewDetails) {
                viewDetails();
              } else {
                window.location.href = `${ROUTES.ROOT}#panel`;
              }
            }}
          />
        </div>
      );
    }

    let completionPercentage =
      progress?.step === 1
        ? 0
        : !!progress?.totalStep
          ? progress?.step < 4
            ? Math.round(
                (((progress?.step || 0) - 1) / progress?.totalStep) * 100,
              )
            : Math.round(((progress?.step || 0) / progress?.totalStep) * 100)
          : 0;

    if (progress?.step > 1) {
      return (
        <div className="card-progress">
          <div className="card-text">
            <div className="progress-block">
              <strong>Certification progress</strong>
              <div className="number in-progress">
                {/* {Math.round(
                  (Math.max(0, progress?.step) /
                    CertificationService.getActualTotalSteps(progress)) *
                  100,
                )} */}
                {completionPercentage}%
              </div>
            </div>
            <StepCounter progress={progress} />
          </div>

          <Badge level={progress?.level} bcolor={"#F2994A"} />

          {/* <BuyDownload guide={guide} /> */}
          <CertificateProgress
            heading="Continue your certification"
            status="Continue"
            link={() => {
              if (!!viewProgress) {
                viewProgress();
              } else {
                window.location.href = `${ROUTES.CERTIFICATION_MANAGER}#step`;
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="card-progress">
          <div className="card-text">
            <div className="progress-block">
              <strong>Certification progress</strong>
              <div className="number blank">0%</div>
            </div>
            <div className="step not-started">
              <strong>Current step</strong>
              <span>Not started</span>
            </div>
          </div>
          <Badge level={progress?.level} bcolor={"#8B9AAE"} />

          {/* <BuyDownload guide={guide} /> */}
          <CertificateProgress
            heading={
              !!progress?.status
                ? "Start your certification"
                : "Choose your certification"
            }
            status={!!progress?.status ? "Start" : "Get Started"}
            link={() => {
              if (!progress?.status) {
                const element = document.getElementById(
                  "dashboard-assessment-tool-panel",
                );
                if (!element) {
                  window.location.href = `${ROUTES.ROOT}?assessment_tool_panel=true`;
                } else {
                  element.scrollIntoView({ block: "start", inline: "center" });
                }
              } else if (!!viewProgress) {
                viewProgress();
              } else {
                window.location.href = `${ROUTES.CERTIFICATION_MANAGER}#step`;
              }
            }}
          />
        </div>
      );
    }
  };

  return <>{render(progressState.progress)}</>;
}
