import { DECISION_TREE, getQuestionaire } from "components";

const log = false;

export const produceAssessmentResult = (answers) => {
  consoleLog(`=== ANSWERS ${JSON.stringify(answers)}`);
  let eligibleRequirements = [];

  const l1 = testDecisionChecklist(
    answers,
    DECISION_TREE[1],
    eligibleRequirements,
  );
  const l2 = testDecisionChecklist(
    answers,
    DECISION_TREE[2],
    eligibleRequirements,
  );
  const l3 = testDecisionChecklist(
    answers,
    DECISION_TREE[3],
    eligibleRequirements,
  );
  // const l4 = testDecisionChecklist(
  //   answers,
  //   DECISION_TREE[4],
  //   eligibleRequirements
  // );
  // const l5 = testDecisionChecklist(
  //   answers,
  //   DECISION_TREE[5],
  //   eligibleRequirements
  // );

  eligibleRequirements = Array.from(new Set(eligibleRequirements));

  if (!l1) return { res: 1, eligibleRequirements };
  if (!l2) return { res: 1, eligibleRequirements };
  if (!l3) return { res: 2, eligibleRequirements };
  // if (!l4) return { res: 3, eligibleRequirements };
  // if (!l5) return { res: 4, eligibleRequirements };
  // return { res: 5, eligibleRequirements };
  return { res: 3, eligibleRequirements };
};

const testDecisionChecklist = (answers, checks, eligibleRequirements) => {
  let ok = true;
  checks.forEach((check) => {
    const { reqId, condition } = check;

    consoleLog(`=== REQUIREMENT ${reqId}`);

    const passed = condition.every((c) => {
      const ret = checkCondition(answers, c);
      consoleLog(`\t${JSON.stringify(c)} ${ret}`);
      return ret;
    });

    consoleLog(`\t=> PASSED = ${passed}`);

    if (passed) {
      eligibleRequirements.push(reqId);
    } else {
      ok = false;
    }
  });

  return ok;
};

/**
 *
 * @param {*} answers
 * @param {*} condition a single condition {...}, may have `or` (nested condition)
 * @returns
 */
const checkCondition = (answers, condition) => {
  // check value
  let ok = false;
  const answer = answers.filter((a) => a.id === condition.id)[0];

  if (!!condition.value) {
    ok = answer?.value === condition.value;
  } else if (!!condition?.mayValues) {
    ok = condition?.mayValues.includes(answer?.value);
  } else if (!!condition.notValues) {
    ok = !condition.notValues.includes(answer?.value);
  } else if (!!condition.notIncludes) {
    const values = answer?.value?.split(/[\r\n]+/) || [];
    ok = condition.notIncludes.every((c) => !values.includes(c));
  } else if (!!condition.mustIncludes) {
    const values = answer?.value?.split(/[\r\n]+/) || [];
    ok = condition.mustIncludes.every((c) => values.includes(c));
  } else if (!!condition.selectedAll) {
    const values = answer?.value?.split(/[\r\n]+/) || [];
    const all = getQuestionaire()
      .flatMap((q) => q)
      .filter((q) => q.id === condition.id)[0]?.options;
    ok = all?.every((c) => values.includes(c)) || false;
  } else if (!!condition.minSelected) {
    const values = answer?.value?.split(/[\r\n]+/) || [];
    ok = values.length >= condition.minSelected;
  } else {
    // only need to have answer
    ok = !!answer?.value;
  }

  if (!ok && !!condition.or) {
    return condition.or.every((c) => checkCondition(answers, c));
  }
  return ok;
};

const consoleLog = (msg) => {
  if (log === true) {
    console.log(msg);
  }
};
