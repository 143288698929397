import { getOrganisation as getAPI } from "graphql/queries";
import { makeGetFunction } from "../../utils";

export const getOrganisation = (ABN) =>
  makeGetFunction({
    name: "getOrganisation",
    query: getAPI,
    params: {
      ABN,
    },
  })();
