import "./certification-manager.scss";

import { useEffect, useRef, useState } from "react";

import {
  getIssuedBadge,
  getSpecificEnvelopeSignedAttestationUrl,
  getSpecificLevelCertificateDownloadUrl,
  onUpdateProgress,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import {
  CertificationProgress,
  Confetti,
  MetaDescription,
  NavBreadcrumbList,
  Progress,
  Spinner,
} from "components";
import { useDownloadUrlState, useLoadingState } from "middleware";
import { useConfettiState } from "middleware/redux/states/confetti";
import { useLocation } from "react-router-dom";
import { CertificationService } from "services/certification";
import { AUDIT_LEVEL, ROUTES } from "variables";

const headerDescription = {
  1: "It’s time to get started! Go to Step 1 to download your Certification Workbook and then implement your requirements.",
  2: "When you have completed all of the certification requirements for your organisation complete Step 2 – Complete Attestation.",
  3: "When you have completed your Attestation move to Step 3 – Create Attestation Letter.",
  4: "Your Attestation Letter has been sent for signing. When this has been completed we will issue your certificate.",
  5: "You’re almost there! Go to Step 5 to request your audit review. We will be in touch to schedule a time.",
};

const initialProgress = {
  progressData: undefined,
  step: 0,
  done: 0,
  loading: true,
  headerDescription: "",
};

export function CertificationManager({ user }) {
  const [certificationInfo, setCertificationInfo] = useState();
  const [progress, setProgress] = useState(initialProgress);
  const [requireAudit, setRequireAudit] = useState(false);
  const [guide, setGuide] = useState({});
  const [level, setLevel] = useState();
  const { loading, setLoading } = useLoadingState();
  const { confetti, setConfetti } = useConfettiState();
  const { setDownloadUrl } = useDownloadUrlState();
  const [location, setLocation] = useState(useLocation());
  const confettiRef = useRef();
  const [stepComplete, setStepComplete] = useState(false);

  useEffect(() => {
    if (progress.step > 0 && !!location) {
      if (location.hash === "#step") {
        setLocation(null);
        setTimeout(() => {
          scrollToCurrentStep();
        }, 1000);
      } else if (location.hash === "#stepComplete") {
        setLocation(null);
        if (canDisplayCompletedState()) {
          setTimeout(() => {
            scrollToCurrentStep();
          }, 1000);
        } else {
          setStepComplete(true);
          const currentConfetti = confetti.current;
          setConfetti(true);
          setTimeout(() => {
            onCompleteProgress(progress, currentConfetti);
          }, 1000);
        }
      }
    }
  }, [progress]);

  useEffect(() => {
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!progress.progressData) {
      return onUpdateProgress({
        id: progress.progressData.id,
        callbackFn: (prog) => {
          if (
            prog.attestationReviewed !==
            progress.progressData.attestationReviewed
          ) {
            if (
              prog.attestationReviewed === true &&
              prog.status === "COMPLETED"
            ) {
              onCompleteProgress(prog, confetti.current);
            } else {
              window.location.reload();
            }
          }
          CertificationService.periodicCheck(
            prog?.id,
            progress?.progressData?.level,
            CertificationService.getCertificationProgressNextStep(
              progress?.progressData?.level,
              progress?.done,
            ),
            updateProgress,
          );
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const setup = async () => {
    const certInfo = await getCertificationInfo();
    setCertificationInfo(certInfo);
    // retrieve current certification progress
    if (!!user?.ABN && progress?.loading) {
      const certificationProgress = certInfo?.customer?.certification?.progress;
      if (certificationProgress?.step >= 6) {
        setConfetti(true);
      } else {
        setConfetti(false);
      }

      setRequireAudit(certificationProgress?.level >= AUDIT_LEVEL);

      const prog = {
        ...initialProgress,
        progressData: certificationProgress,
        done: certificationProgress?.step - 1,
        step: certificationProgress?.step,
      };

      updateProgress({
        ...prog,
        loading: false,
      });

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      setLevel(certificationProgress?.level);
    }
    if (!!certInfo?.customer?.certification?.progress) {
      setGuide({
        purchased: certInfo?.customer?.guidePurchased,
        purchasedDate: certInfo?.customer?.guidePurchasedDate,
      });
    }
  };

  const onCompleteProgress = async (prog, currentConfetti) => {
    // setLoading(true);

    await updateDownloadUrls(prog);

    // setLoading(false);

    updateProgress({
      ...progress,
      done: 6,
      step: 6,
      loading: false,
      headerDescription: (
        <>
          <b>Congratulations!</b> 🎉
          <br /> Your {["Bronze", "Silver", "Gold"][prog?.level - 1]}{" "}
          Certification is active.
        </>
      ),
    }); // completed step = 6
    scrollToStep(6);
    if (!currentConfetti) {
      confettiRef.current.startAnimation();
      setConfetti(true);
    }
  };

  const updateDownloadUrls = async (prog) => {
    try {
      setDownloadUrl({
        certificateLink: "/",
        signedDeedLink: "/",
        badges: "/",
      });

      const certificateLink = await getSpecificLevelCertificateDownloadUrl(
        prog?.level,
      );
      const signedDeedLink = await getSpecificEnvelopeSignedAttestationUrl(
        prog?.envelopeId,
      );
      const badges = await getIssuedBadge({
        level: prog?.level,
      });

      setTimeout(() => {
        setDownloadUrl({
          certificateLink,
          signedDeedLink,
          badges,
        });
      }, 1000);

      if (certificateLink === "/" || signedDeedLink === "/" || badges === "/") {
        setTimeout(() => {
          updateDownloadUrls(prog);
        }, 2000);
      }
    } catch (e) {
      //
    }
  };

  const canDisplayCompletedState = () => {
    if (progress?.level >= 4 && progress.status !== "COMPLETED") {
      return false;
    }
    return progress?.step >= 4 || progress.status === "COMPLETED";
  };

  const scrollToCurrentStep = () => {
    scrollToStep(progress.step);
  };

  const scrollToStep = (step) => {
    if (step === 6) {
      setTimeout(() => {
        document
          .getElementById("certification-step-6-complete-panel")
          ?.scrollIntoView({ block: "center", inline: "center" });
      }, 500);
    } else {
      const e = document.getElementsByClassName(`cert-step-${step}`)[0];
      e?.scrollIntoView({ block: "center", inline: "center" });
    }
  };

  const getHeaderDescription = (level, current, status) => {
    if (!!current) {
      if (current > 5 || status === "COMPLETED") {
        return (
          <>
            <b>Congratulations!</b> 🎉
            <br /> Your {["Bronze", "Silver", "Gold"][level - 1]} Certification
            is active.
            {/* {console.log("level is" + level)} */}
          </>
        );
      } else {
        return headerDescription[current];
      }
    } else {
      return headerDescription[1];
    }
  };

  const getCurrentStep = () => {
    return progress?.step?.toString();
  };

  const setCurrentStep = (step) => {
    if (
      step === 0 ||
      step - progress.done <= 1 ||
      step === progress.progressData?.step
    )
      updateProgress({
        step,
      });
  };

  const assessStep = (step, data = {}) => {
    return CertificationService.assessCertificationStep(
      progress.progressData?.id,
      step,
      progress.done,
      data,
      (result) => {
        updateProgress({
          done: step,
          step: CertificationService.getCertificationProgressNextStep(
            progress.progressData?.level,
            step,
          ),
          progressData: result || progress.progressData,
        });
      },
      () =>
        console.error("Cannot proceed. Please provide required information."),
    );
  };

  const isStepCompleted = (step) => {
    if (step === 6 && progress.done >= 5) {
      return true;
    }
    return step <= progress.done;
  };

  const updateProgress = (newProgress) => {
    // console.log(newProgress?.step, progress?.step, confetti.current);
    if (newProgress?.step !== progress?.step && newProgress?.step === 6) {
      if (confetti.current === false) {
        updateDownloadUrls(newProgress?.progressData);
        confettiRef.current.startAnimation();
        setConfetti(true);
      }
    }
    setProgress({
      ...progress,
      ...newProgress,
      headerDescription: getHeaderDescription(
        newProgress?.progressData?.level,
        newProgress?.step,
        newProgress?.progressData?.status,
      ),
    });
  };

  const resendSignRequest = (email = undefined) => {
    return CertificationService.resendSignRequest(
      progress.progressData?.id,
      email,
    );
  };

  return (
    <>
      <MetaDescription
        pageTitle="Certification Manager"
        pageDescription="Manage your cyber security certificate using our Certification Manager in CyberCert"
      />
      <div className="certification-manager-container">
        {(progress?.loading || loading.loading) && (
          <div className="overlay white-bg">
            <Spinner svgTemplate={true} />
          </div>
        )}

        <div className="dashboard-header">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTES.ROOT, name: "Dashboard" },
                      {
                        to: ROUTES.CERTIFICATION_MANAGER,
                        name: "Certification Manager",
                      },
                    ]}
                  />

                  <div className="heading certmanager">
                    <h1 className="title">Certification Manager</h1>
                  </div>

                  <div className="text">
                    <p>{progress?.headerDescription}</p>
                  </div>

                  {/* <div className="footer">
                    <div className="btn-holder">
                      {progress?.progressData?.status === "COMPLETED" ? (
                        <span className="btn green">
                          Congratulations, you're certified!
                        </span>
                      ) : (
                        <span className="btn orange">
                          Step{" "}
                          {CertificationService.getActualCurrentStep(
                            progress?.progressData
                          )}
                          :{" "}
                          {CertificationService.getCertStepTitleFor(
                            progress?.progressData
                          )}
                        </span>
                      )}

                      <StatusBadge progress={progress?.progressData} />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-pg">
                <Progress
                  viewProgress={scrollToCurrentStep}
                  stepComplete={stepComplete}
                />
              </div>
            </div>
          </div>
        </div>

        {progress?.progressData && (
          <div className="certification-section">
            <div className="container-md">
              <div className="inner">
                <div className="content content-accordion">
                  <CertificationProgress
                    certificationInfo={certificationInfo}
                    requireAudit={requireAudit}
                    getCurrentStep={getCurrentStep}
                    setCurrentStep={setCurrentStep}
                    assessStep={assessStep}
                    isStepCompleted={isStepCompleted}
                    resendSignRequest={resendSignRequest}
                    progressData={progress.progressData}
                    level={level}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Confetti animation */}
      <Confetti ref={confettiRef} />
    </>
  );
}
