import { updateOrganisation } from "api";
import { useEffect, useState } from "react";
import {
  filterPhoneNoKeyPress,
  filterPhoneNoPaste,
  validatePhoneNumber,
} from "services";

const initialState = {
  name: "",
  email: "",
  ABN: "",
  phone: "",
  addressStreet: "",
  addressSuburb: "",
  addressState: "",
  addressPostcode: "",
};

const defaultErrorTxt = "Update failed. Please try again.";

export function OrganisationSettings({ user, certificationInfo }) {
  const [organisation, setOrganisation] = useState(initialState);
  const [updateStatus, setUpdateStatus] = useState("NA");
  const [errorTxt, setErrorTxt] = useState(defaultErrorTxt);

  useEffect(() => {
    setOrganisation({
      name: certificationInfo?.organisation?.name || "",
      email: certificationInfo?.organisation?.email || "",
      ABN: certificationInfo?.organisation?.ABN || "",
      phone: certificationInfo?.organisation?.phone || "",
      addressStreet: certificationInfo?.organisation?.addressStreet || "",
      addressSuburb: certificationInfo?.organisation?.addressSuburb || "",
      addressState: certificationInfo?.organisation?.addressState || "",
      addressPostcode: certificationInfo?.organisation?.addressPostcode || "",
    });
  }, [user, certificationInfo]);

  const onChange = (event) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };

  const onUpdate = (event) => {
    event.preventDefault();

    // validate phone number
    if (!!organisation?.phone && !validatePhoneNumber(organisation.phone)) {
      setUpdateStatus("error");
      setErrorTxt("Invalid phone number.");
      return;
    }

    setErrorTxt(defaultErrorTxt);

    updateOrganisation(organisation).then((result) => {
      if (!!result) {
        setUpdateStatus("success");
      } else {
        setUpdateStatus("error");
      }
    });
  };

  return (
    <>
      <h4 className="table-heading main-heading">
        <svg
          width="27"
          height="24"
          viewBox="0 0 27 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9155 5.22435V0H0.915527V23.5096H26.9155V5.22435H13.9155ZM6.11553 20.8974H3.51553V18.2852H6.11553V20.8974ZM6.11553 15.6731H3.51553V13.0609H6.11553V15.6731ZM6.11553 10.4487H3.51553V7.83653H6.11553V10.4487ZM6.11553 5.22435H3.51553V2.61218H6.11553V5.22435ZM11.3155 20.8974H8.71553V18.2852H11.3155V20.8974ZM11.3155 15.6731H8.71553V13.0609H11.3155V15.6731ZM11.3155 10.4487H8.71553V7.83653H11.3155V10.4487ZM11.3155 5.22435H8.71553V2.61218H11.3155V5.22435ZM24.3155 20.8974H13.9155V18.2852H16.5155V15.6731H13.9155V13.0609H16.5155V10.4487H13.9155V7.83653H24.3155V20.8974ZM21.7155 10.4487H19.1155V13.0609H21.7155V10.4487ZM21.7155 15.6731H19.1155V18.2852H21.7155V15.6731Z"
            fill="black"
          />
        </svg>
        Organisation
      </h4>

      <form className="tab-form" action="#">
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation Name</strong>
              </label>
              <input
                className="capitalize"
                type="text"
                value={organisation.name}
                disabled
              />
            </div>
          </div>
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation Email Address</strong>
              </label>
              <input
                type="email"
                defaultValue={organisation.email}
                name="email"
                onChange={onChange}
                onBlur={onChange}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation ABN</strong>
              </label>
              <input type="text" value={organisation.ABN} disabled />
            </div>
          </div>
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation Phone</strong>
              </label>
              <input
                type="text"
                defaultValue={organisation.phone}
                name="phone"
                onChange={onChange}
                onBlur={onChange}
                onKeyPress={filterPhoneNoKeyPress}
                onPaste={filterPhoneNoPaste}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation Address</strong>
              </label>
              <span>Street Name</span>
              <input
                type="text"
                defaultValue={organisation.addressStreet}
                name="addressStreet"
                onChange={onChange}
                onBlur={onChange}
              />
            </div>
          </div>
          <div className="col-form">
            <div className="form-item-row">
              <div className="form-item">
                <label>Suburb / City</label>
                <input
                  type="text"
                  defaultValue={organisation.addressSuburb}
                  name="addressSuburb"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
              <div className="form-item">
                <label>State</label>
                <input
                  type="text"
                  defaultValue={organisation.addressState}
                  name="addressState"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
              <div className="form-item">
                <label>Postcode</label>
                <input
                  type="text"
                  defaultValue={organisation.addressPostcode}
                  name="addressPostcode"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form-btn">
            <button
              className="btn btn-warning-gd"
              type="submit"
              onClick={onUpdate}
            >
              Update
            </button>
          </div>
        </div>
        <div className="col-form-btn">
          <div className={`form-validation ${updateStatus}`}>
            <div className="validation-txt">
              <p className="v-text success">Updated successfully.</p>
              <p className="v-text error">{errorTxt}</p>{" "}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
