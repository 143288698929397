import "./certification-progress.scss";

import { CertificationWithAudit } from "./certification-with-audit";
import { CertificationWithoutAudit } from "./certification-without-audit";

export function CertificationProgress({
  certificationInfo,
  requireAudit,
  getCurrentStep,
  setCurrentStep,
  assessStep,
  isStepCompleted,
  progressData,
  resendSignRequest,
  level,
}) {
  return (
    <>
      {requireAudit ? (
        <CertificationWithAudit
          certificationInfo={certificationInfo}
          getCurrentStep={getCurrentStep}
          setCurrentStep={setCurrentStep}
          assessStep={assessStep}
          isStepCompleted={isStepCompleted}
          progressData={progressData}
          resendSignRequest={resendSignRequest}
          level={level}
        />
      ) : (
        <CertificationWithoutAudit
          certificationInfo={certificationInfo}
          getCurrentStep={getCurrentStep}
          setCurrentStep={setCurrentStep}
          assessStep={assessStep}
          isStepCompleted={isStepCompleted}
          progressData={progressData}
          resendSignRequest={resendSignRequest}
          level={level}
        />
      )}
    </>
  );
}
