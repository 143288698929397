import Form from "react-bootstrap/Form";

import "./form-input.scss";

export function FormCheckboxInput({
  controlId,
  name,
  onChange,
  children,
  disabled,
}) {
  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <div className="f-row-center-left">
        <Form.Check
          type="checkbox"
          name={name}
          onChange={onChange}
          disabled={disabled}
        />

        <Form.Label className="checkbox-label">{children}</Form.Label>
      </div>
    </Form.Group>
  );
}
