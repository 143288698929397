import "./reset-password.scss";

import { useEffect, useState } from "react";

import { Spinner } from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AuthService, clearUrlParamsState } from "services";
import { ROUTES } from "variables";

import { VerificationCodePasswdInputForm } from "./code-passwd-input-form";
import { EmailInputForm } from "./email-input-form";
import { ResetPasswdInitiate } from "./reset-password-inititiate";

const INPUT_EMAIL = "INPUT_EMAIL";
const INPUT_CODE = "INPUT_CODE";
const INPUT_PASSWD = "INPUT_PASSWD";
const INPUT_CODE_PASSWD = "INPUT_CODE_PASSWD";
const RESET_SUCCESS = "RESET_SUCCESS";
const RESET_INITIATE = "RESET_INITIATE";

const initialState = {
  email: "",
  code: "",
  tmpPassword: "",
  password: "",
  confirmPassword: "",
};

const initialValidation = {
  code: "NA",
  email: "NA",
};

const initialErrorTxt = {
  code: "Your verification code is incorrect.",
  email: "Password reset failed. Please check if email address is correct.",
};

export function ResetPassword() {
  const { loading, setLoading } = useLoadingState();

  const location = useLocation();

  const { search } = location;
  let resetEmail = "";
  let resetCode = "";

  if (!!search) {
    const params = queryString.parse(search);
    if (!!params.email) {
      resetEmail = params.email;
    }
    if (!!params.resetCode) {
      resetCode = params.resetCode;
    }
  }

  const [resetState, setResetState] = useState({
    ...initialState,
    email: resetEmail,
    code: resetCode,
  });

  let initialStep = INPUT_EMAIL;

  if (!!resetEmail) {
    initialStep = INPUT_CODE_PASSWD;
  }

  const [step, setStep] = useState(initialStep);
  const [validation, setValidation] = useState(initialValidation);
  const [errorTxt, setErrorTxt] = useState(initialErrorTxt);

  clearUrlParamsState();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleEmail = async () => {
    setLoading(true);
    await AuthService.forgotPassword(
      resetState.email,
      () => setStep(RESET_INITIATE),
      (err) => {
        setResetState({
          ...resetState,
          email: "",
          code: "",
          password: "",
          confirmPassword: "",
        });
        setValidation({
          ...validation,
          email: "error",
        });
        setStep(INPUT_EMAIL);
      },
    );
    setLoading(false);
  };

  const handleInput = (event) => {
    setResetState({
      ...resetState,
      [event.target.name]: event.target.value,
    });
  };

  const handleForgotPwdSubmit = async () => {
    setLoading(true);

    await AuthService.forgotPasswordConfirmReset(
      resetState.email,
      resetState.code,
      resetState.password,
      () => {
        setStep(RESET_SUCCESS);
      },
      (err) => {
        console.log(err.code);
        setStep(INPUT_CODE_PASSWD);
        if (err.code === "CodeMismatchException") {
          setValidation({
            ...validation,
            code: "error",
          });
          setErrorTxt({
            ...errorTxt,
            code: "Your verification code is incorrect.",
          });
        } else if (err.code === "LimitExceededException") {
          setValidation({
            ...validation,
            code: "error",
          });
          setErrorTxt({
            ...errorTxt,
            code: "Attempt limit exceeded, please try after some time.",
          });
        } else if (err.code === "PasswordInUse") {
          setValidation({
            ...validation,
            password: "error",
          });
          setErrorTxt({
            ...errorTxt,
            password: "Please enter a password you haven't used before.",
          });
        }
      },
    );

    setLoading(false);
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <div className="resetp-container">
        {
          {
            INPUT_EMAIL: (
              <EmailInputForm
                handleInput={handleInput}
                onContinue={handleEmail}
                pValidation={validation.email}
                errorTxt={errorTxt.email}
              />
            ),
            RESET_INITIATE: (
              <ResetPasswdInitiate
                email={resetState.email}
                resendFn={handleEmail}
              />
            ),
            INPUT_CODE_PASSWD: (
              <VerificationCodePasswdInputForm
                email={resetEmail || resetState.email}
                state={resetState}
                handleInput={handleInput}
                onResendClick={handleEmail}
                onResetConfirm={handleForgotPwdSubmit}
                validation={validation}
                setValidation={setValidation}
                errorTxt={errorTxt}
                setErrorTxt={setErrorTxt}
              />
            ),
            RESET_SUCCESS: (
              <>
                <div className="auth-title">Your password is updated</div>
                <div className="subtitle">
                  Your password has been reset successfully.
                  <br />
                  Access your account by logging in.
                </div>

                <Button
                  className="btn btn-warning-gd"
                  onClick={() => {
                    window.location.href = ROUTES.LOGIN;
                  }}
                >
                  Login
                </Button>
              </>
            ),
          }[step]
        }
      </div>
    </>
  );
}
