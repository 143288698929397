import "./issued.scss";

import { CSCAULogo, SampleWatermark } from "assets";
import {
  CertificationService,
  displayABNFormat,
  isExpired,
  parseDate,
  parseDateSlash,
} from "services";
import {
  BADGE_ANIMS,
  BADGES,
  CERTIFICATION_INFO,
  DATE_PLACEHOLDER,
} from "variables";

export function Issued({
  ABN,
  id,
  level,
  name,
  reqs,
  status,
  completedDate,
  expiredDate,
}) {
  return (
    <>
      <div
        className={`issued-container bg-${level}${expiredDate !== DATE_PLACEHOLDER ? (isExpired(expiredDate) ? " expired" : "") : ""}`}
        style={
          completedDate === DATE_PLACEHOLDER
            ? {
                backgroundImage: `url('${SampleWatermark}')`,
                backgroundPosition: "center",
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
              }
            : undefined
        }
      >
        <div className="issued-wrapper container">
          {level ? (
            <div className="certified-details">
              <div>
                {!status ? (
                  <div className="certified none">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10.6758C7.27604 10.6758 8.36979 10.2201 9.28125 9.30859C10.2109 8.37891 10.6758 7.27604 10.6758 6C10.6758 5.5625 10.5755 5.0612 10.375 4.49609C10.1927 3.93099 9.96484 3.47526 9.69141 3.12891L3.12891 9.69141C3.94922 10.3477 4.90625 10.6758 6 10.6758ZM1.32422 6C1.32422 6.4375 1.41536 6.9388 1.59766 7.50391C1.79818 8.06901 2.03516 8.52474 2.30859 8.87109L8.87109 2.30859C8.05078 1.65234 7.09375 1.32422 6 1.32422C4.72396 1.32422 3.62109 1.78906 2.69141 2.71875C1.77995 3.63021 1.32422 4.72396 1.32422 6ZM1.87109 1.89844C3.01953 0.75 4.39583 0.175781 6 0.175781C7.60417 0.175781 8.97135 0.75 10.1016 1.89844C11.25 3.02865 11.8242 4.39583 11.8242 6C11.8242 7.60417 11.25 8.98047 10.1016 10.1289C8.97135 11.2591 7.60417 11.8242 6 11.8242C4.39583 11.8242 3.01953 11.2591 1.87109 10.1289C0.740885 8.98047 0.175781 7.60417 0.175781 6C0.175781 4.39583 0.740885 3.02865 1.87109 1.89844Z"
                        fill="currentColor"
                      />
                    </svg>
                    in progress
                  </div>
                ) : expiredDate !== DATE_PLACEHOLDER &&
                  isExpired(expiredDate) ? (
                  <div className="certified expired">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10.6758C7.27604 10.6758 8.36979 10.2201 9.28125 9.30859C10.2109 8.37891 10.6758 7.27604 10.6758 6C10.6758 5.5625 10.5755 5.0612 10.375 4.49609C10.1927 3.93099 9.96484 3.47526 9.69141 3.12891L3.12891 9.69141C3.94922 10.3477 4.90625 10.6758 6 10.6758ZM1.32422 6C1.32422 6.4375 1.41536 6.9388 1.59766 7.50391C1.79818 8.06901 2.03516 8.52474 2.30859 8.87109L8.87109 2.30859C8.05078 1.65234 7.09375 1.32422 6 1.32422C4.72396 1.32422 3.62109 1.78906 2.69141 2.71875C1.77995 3.63021 1.32422 4.72396 1.32422 6ZM1.87109 1.89844C3.01953 0.75 4.39583 0.175781 6 0.175781C7.60417 0.175781 8.97135 0.75 10.1016 1.89844C11.25 3.02865 11.8242 4.39583 11.8242 6C11.8242 7.60417 11.25 8.98047 10.1016 10.1289C8.97135 11.2591 7.60417 11.8242 6 11.8242C4.39583 11.8242 3.01953 11.2591 1.87109 10.1289C0.740885 8.98047 0.175781 7.60417 0.175781 6C0.175781 4.39583 0.740885 3.02865 1.87109 1.89844Z"
                        fill="currentColor"
                      />
                    </svg>
                    expired
                  </div>
                ) : (
                  <div className="certified">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.82422 8.92578L10.0742 3.67578L9.25391 2.82812L4.82422 7.25781L2.74609 5.17969L1.92578 6L4.82422 8.92578ZM1.87109 1.89844C3.01953 0.75 4.39583 0.175781 6 0.175781C7.60417 0.175781 8.97135 0.75 10.1016 1.89844C11.25 3.02865 11.8242 4.39583 11.8242 6C11.8242 7.60417 11.25 8.98047 10.1016 10.1289C8.97135 11.2591 7.60417 11.8242 6 11.8242C4.39583 11.8242 3.01953 11.2591 1.87109 10.1289C0.740885 8.98047 0.175781 7.60417 0.175781 6C0.175781 4.39583 0.740885 3.02865 1.87109 1.89844Z"
                        fill="currentColor"
                      />
                    </svg>
                    certified
                  </div>
                )}

                <div className="authorized">
                  {!!status ? (
                    <>
                      Authorised certificate issued on{" "}
                      {completedDate !== DATE_PLACEHOLDER
                        ? parseDateSlash(completedDate)
                        : completedDate}
                      .
                    </>
                  ) : (
                    <>Certificate in progress. Not issued yet.</>
                  )}
                </div>
              </div>

              <div>
                <div className="issuedto">
                  {!!status ? "Issued to" : "In progress by"}
                </div>
                <div className="org-name">{name}</div>
                <div className="org-abn">
                  <b>ABN</b> {displayABNFormat(ABN)}
                </div>
              </div>
            </div>
          ) : (
            <div className="certified-details">
              <div>
                <div className="issuedto">Issued to</div>
                <div className="org-name">N/A</div>
                <div className="org-abn">
                  <b>This certification does not exist</b>
                </div>
              </div>
            </div>
          )}

          <div className="card-lvl">
            {!!level ? (
              <>
                <video
                  autoPlay
                  loop
                  playsInline
                  muted
                  width="100%"
                  height="100%"
                  src={BADGE_ANIMS[level]}
                >
                  <span style={{ color: "white" }}>Hello</span>
                </video>
                <h4 className="level-name">
                  {/* Level {level} -{" "} */}
                  {CERTIFICATION_INFO[level]?.marketingName}
                </h4>
                {/* <p>SMB1001:2023 Level {level}</p> */}
                Issued by{" "}
                <a
                  href="https://www.cybercert.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  CyberCert{" "}
                </a>
              </>
            ) : (
              <>
                <img
                  src={BADGES[0]}
                  className="badgeSeal"
                  alt={"Blank Badge"}
                />
                <div>
                  Visit{" "}
                  <a
                    href="https://www.cybercert.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    CyberCert{" "}
                  </a>
                  for more info!
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="details-container"
        style={
          completedDate === DATE_PLACEHOLDER
            ? {
                backgroundImage: `url('${SampleWatermark}')`,
                backgroundPosition: "center",
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
              }
            : undefined
        }
      >
        <div className="details-wrapper container">
          <div className="cert-list">
            <div className="section-title">Certification Details</div>

            <div className="cert-item">
              <div className="label">ID</div>
              <div className="value">{id || "N/A"}</div>
            </div>
            <div className="cert-item">
              <div className="label">Standard</div>
              <div className="value">SMB1001:2023</div>
            </div>
            <div className="cert-item">
              <div className="label">Level</div>
              <div className="value">
                Level {level} -{" "}
                {CERTIFICATION_INFO[level]?.marketingName || "N/A"}
              </div>
            </div>
            <div className="cert-item">
              <div className="label">Issue Date</div>
              <div className="value">
                {!!completedDate
                  ? completedDate !== DATE_PLACEHOLDER
                    ? parseDate(completedDate)
                    : "X Month 2024"
                  : "N/A"}
              </div>
            </div>
            <div className="cert-item">
              <div className="label">Expiry Date</div>
              <div className="value">
                {!!expiredDate
                  ? expiredDate !== DATE_PLACEHOLDER
                    ? parseDate(expiredDate)
                    : "X Month 2025"
                  : "N/A"}
              </div>
            </div>
            <div className="cert-item">
              <div className="label">Attested</div>
              <div className="value">
                {!!reqs?.length ? `${reqs?.length} Requirements` : "N/A"}
              </div>
            </div>
            <div className="cert-item">
              <div className="label">Valid for</div>
              <div className="value">
                {!!CertificationService.getCertificationDurationInMonth(level)
                  ? `${CertificationService.getCertificationDurationInMonth(
                      level,
                    )} Months`
                  : "N/A"}
              </div>
            </div>
            {/* <div className="cert-item">
              <div className="label">Difficulty</div>
              <div className="value">
                {level ? (
                  CERTIFICATION_INFO[level].difficulty
                ) : (
                  <>N/A</>
                )}
              </div>
            </div> */}
            <div className="cert-item">
              <div className="label">Assurance</div>
              <div className="value">
                {level ? CERTIFICATION_INFO?.[level]?.assuranceLevel : <>N/A</>}
              </div>
            </div>
          </div>
          <div className="cert-about">
            {!!level && (
              <div className="mb-50">
                <div className="section-title">About Certification</div>
                {!!status ? (
                  <p>
                    <span className="level">
                      {/* Level {level} -{" "} */}
                      {CERTIFICATION_INFO[level]?.marketingName}
                    </span>{" "}
                    certificate is issued to{" "}
                    <span className="capitalised">{name}</span> (Organisation)
                    by CyberCert in reliance on the Letter of Attestation
                    provided by the organisation dated{" "}
                    {completedDate !== DATE_PLACEHOLDER
                      ? parseDateSlash(completedDate)
                      : completedDate}
                    .
                  </p>
                ) : (
                  <p>
                    <span className="level">
                      {/* Level {level} -{" "} */}
                      {CERTIFICATION_INFO[level]?.marketingName}
                    </span>{" "}
                    certificate is currently in progress by{" "}
                    <span className="capitalised">{name}</span> (Organisation)
                  </p>
                )}
              </div>
            )}

            <div>
              <div className="section-title">Standards Authority</div>
              <div className="issuing-partner">
                <div className="logo">
                  <a
                    href="https://cscau.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={CSCAULogo} alt="CSCAU" />
                  </a>
                  <a
                    href="https://cscau.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cscau.com.au
                  </a>
                </div>
                <div className="description">
                  The certifications and prerequisites are provided by Cyber
                  Security Certifications Australia (CSCAU).
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
