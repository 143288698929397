import {
  certificationProgressesByDate as listByDateAPI,
  getCertificationProgress as getAPI,
  listCertificationProgresses,
} from "graphql/queries";
import { makeGetFunction } from "../../utils";

export const getCertificationProgress = (id) =>
  makeGetFunction({
    name: "getCertificationProgress",
    query: getAPI,
    params: {
      id,
    },
  })();

export const getCertificationProgressesByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "listCertificationProgresses",
        query: listCertificationProgresses,
        params: {
          filter: {
            ABN: {
              eq: ABN,
            },
          },
          limit: 1000,
        },
      })()
    : undefined;

export const getCompletedCertificationProgressesByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "listCertificationProgresses",
        query: listCertificationProgresses,
        params: {
          filter: {
            ABN: {
              eq: ABN,
            },
            status: {
              eq: "COMPLETED",
            },
          },
          limit: 1000,
        },
      })()
    : undefined;

export const getSortedByDateCompletedCertificationProgressesByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "certificationProgressesByDate",
        query: listByDateAPI,
        params: {
          self: "CertificationProgress",
          filter: {
            ABN: {
              eq: ABN,
            },
            status: {
              eq: "COMPLETED",
            },
          },
          limit: 1000,
          sortDirection: "DESC",
        },
      })()
    : undefined;

export const getSortedByDateCertificationProgressesByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "certificationProgressesByDate",
        query: listByDateAPI,
        params: {
          self: "CertificationProgress",
          filter: {
            ABN: {
              eq: ABN,
            },
          },
          limit: 1000,
          sortDirection: "DESC",
        },
      })()
    : undefined;
