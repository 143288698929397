import { useEffect, useState } from "react";

import { Spinner } from "components";
import { useAppSessionState } from "middleware";
import { AuthService } from "services";
import { ROUTES } from "variables";

export function RequireNoAuth({ children, navigate = () => {} }) {
  const { setSession } = useAppSessionState();
  const [ok, setOk] = useState(false);

  const checkAccountState = () => {
    AuthService.checkLoginState().then((sess) => {
      setSession(sess);
      if (!!sess) {
        navigate(ROUTES.ROOT);
      }
      setOk(true);
    });
  };

  useEffect(() => {
    checkAccountState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ok ? (
        children
      ) : (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}
    </>
  );
}
