import { Link } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

export function ResetPasswdInitiate({ email, resendFn = () => {} }) {
  return (
    <>
      <div className="auth-title heading2">Reset password</div>

      <div className="subtitle">
        <Link to={ROUTES.LOGIN}>Back to Login</Link>
      </div>

      <div className="subtitle no-p">
        <p>
          An email containing further instructions to reset your password has
          been sent to {email}
        </p>

        <div>
          If you did not receive this email, please{" "}
          <span className="link" onClick={resendFn}>
            resend
          </span>{" "}
          or{" "}
          <span className="link" onClick={openZEWidget}>
            contact our support
          </span>{" "}
          for assistance.
        </div>
      </div>
    </>
  );
}
