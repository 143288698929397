import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  certificateLink: "/",
  signedDeedLink: "/",
  badges: "/",
};

export const downloadUrlSlice = createSlice({
  name: "downloadUrl",
  initialState,
  reducers: {
    setDownloadUrl: (state, action) => {
      if (!!action.payload.certificateLink) {
        state.certificateLink = action.payload.certificateLink;
      }
      if (!!action.payload.signedDeedLink) {
        state.signedDeedLink = action.payload.signedDeedLink;
      }
      if (!!action.payload.badges) {
        state.badges = action.payload.badges;
      }
    },
  },
});

const { setDownloadUrl } = downloadUrlSlice.actions;

export const setDownloadUrlAction = setDownloadUrl;

export default downloadUrlSlice.reducer;
