import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApp: (state, action) => {
      state.current = action.payload;
    },
  },
});

const { setApp } = appSlice.actions;

export const setAppAction = setApp;

export default appSlice.reducer;
