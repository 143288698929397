import { useLoadingState } from "middleware";

import "./loading-link.scss";

export function LoadingLink({
  href,
  className = "",
  download = true,
  target = "_blank",
  rel = "noreferrer",
  children,
  disabledChidren,
}) {
  const { loading, setLoading } = useLoadingState();

  const loadLink = () => {
    if (href === "/") {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        checkAndNavigate();
      }, 5000);
    } else {
      window.open(href, "_blank");
    }
  };

  const checkAndNavigate = () => {
    if (loading.loading && href !== "/") {
      window.open(href, "_blank");
    }
  };

  return (
    <>
      {href !== "/" ? (
        <a
          href={href}
          className={className}
          download={download}
          target={target}
          rel={rel}
        >
          {children}
        </a>
      ) : (
        <a className={`${className} disabled`}>
          {!!disabledChidren ? disabledChidren : children}
        </a>
      )}
    </>
  );
}
