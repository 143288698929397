import { createAssessmentQuestionnaire } from "./mutations";
import { makeGetFunction } from "api/utils";
import { listRecommendationTools as listAPI } from "graphql/queries";

export const getAssessmentQuestionnaire = async () => {
  let questionnaire = (await listAssessmentQuestionnaire())[0];
  if (!questionnaire) {
    questionnaire = await createAssessmentQuestionnaire({});
  }
  console.log(questionnaire);
  return questionnaire;
};

const listAssessmentQuestionnaire = async () => {
  return (
    (
      await makeGetFunction({
        name: "listRecommendationTools",
        query: listAPI,
        params: {},
      })()
    )?.items || []
  );
};
