import { getLandingPage } from "./destinations";

export const configureAuthStorage = (storage) => {
 // pass
};

export const checkAuthErrorState = ({ onPass = () => {} }) => {
  const authErr = localStorage.getItem("AUTH_ERROR");

  if (
    !!localStorage.getItem("AUTH_ERROR") &&
    !!localStorage.getItem("AUTH_ERROR_STATE")
  ) {
    localStorage.setItem("AUTH_ERROR", "");
    switch (authErr) {
      case "SSO_NOT_FOUND":
        window.location.href = `${getLandingPage()}/login?sso_fail=true`;
        return;
      case "PARTNER_SSO_NOT_FOUND":
        window.location.href = `${getLandingPage()}/login?sso_fail=true`;
      default:
    }
  } else if (
    !localStorage.getItem("AUTH_ERROR") &&
    !!localStorage.getItem("AUTH_ERROR_STATE")
  ) {
    localStorage.setItem("AUTH_ERROR_STATE", "");
  } else {
    onPass();
  }
};

export const configureAuthStorageByRememberOption = (rememberMe) => {
  // configureAuthStorage(rememberMe ? localStorage : sessionStorage);
};
