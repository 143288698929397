import { makeSubscriptionFunction } from "api/utils";
import { onProgressUpdateByID } from "graphql/subscriptions";
import { getCertificationProgress } from "./queries";

export const onUpdateProgress = ({ id, callbackFn }) =>
  makeSubscriptionFunction({
    query: onProgressUpdateByID,
    params: {
      id,
    },
    onAction: (provider, value) => {
      const progress = provider.value.data.onProgressUpdateByID;
      if (!progress) {
        getCertificationProgress(id).then((prog) => callbackFn(prog));
      } else {
        callbackFn(progress);
      }
    },
  })();
