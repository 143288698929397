import { Helmet } from "react-helmet";

export function MetaDescription({ pageDescription, pageTitle }) {
  return (
    <Helmet>
      <title> CyberCert | {pageTitle}</title>
      <meta name="description" content={pageDescription} />
    </Helmet>
  );
}
