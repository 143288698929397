import { useEffect, useState } from "react";

import { ArrowNext, ArrowPrev } from "assets";
import { AssessmentResultLevel } from "components";

export function AssessmentDoneDowngrade({
  result,
  howToQualifyLevel,
  setHowToQualifyLevel,
  currentLevel,
  currentStatus,
  eligibleRequirements,
  prices,
}) {
  const [showLevel, setShowLevel] = useState();

  useEffect(() => {
    if (!!result) {
      setShowLevel(1);
    }
  }, [result]);

  return (
    <>
      <div className="panel-quiz-header mt-50 assessment-result-block">
        <div className="panel-header">
          {/* <div className="progress-bar">
            <span></span>
          </div> */}
          <div className="panel-steps">
            <div className="panel-steps-header">
              <div className="heading">
                <div className="f-row-spread">
                  <div>
                    <h4 className="h4-downgrade">DOWNGRADE CERTIFICATION</h4>
                  </div>
                  <div className="lvl-btns">
                    {showLevel > 1 && (
                      <img
                        src={ArrowPrev}
                        alt="prev"
                        onClick={() => {
                          setShowLevel(Math.max(0, showLevel - 1));
                          setHowToQualifyLevel(null);
                        }}
                      />
                    )}
                    {showLevel < result - 1 && (
                      <img
                        src={ArrowNext}
                        alt="prev"
                        onClick={() => {
                          setShowLevel(Math.min(5, showLevel + 1));
                          setHowToQualifyLevel(null);
                        }}
                      />
                    )}
                  </div>
                </div>
                <h1>Downgrade to a cheaper certification</h1>
                <p className="font-normal">
                  Click on "How to qualify" to see requirements you need to
                  implement to qualify for a lower level certification.
                </p>
              </div>
            </div>

            <div className="label-block-holder">
              {[1, 2, 3, 4, 5]
                .filter((l) => l < result && l === showLevel)
                .map((l) => (
                  <AssessmentResultLevel
                    howToQualifyLevel={howToQualifyLevel}
                    showLevel={showLevel}
                    setHowToQualifyLevel={setHowToQualifyLevel}
                    result={result}
                    level={l}
                    currentLevel={currentLevel}
                    currentStatus={currentStatus}
                    eligibleRequirements={eligibleRequirements}
                    price={prices[l - 1]}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
