import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Asterisk } from "../required-asterisk";

import "./form-input.scss";

export function FormEmailInput({
  controlId,
  label,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  maxLength,
  disabled,
  successTxt = "",
  errorTxt = "Please enter a valid email address.",
  pValidation,
  pSetValidation,
  autoComplete,
  setFocus,
}) {
  let [validation, setValidation] = useState("NA");
  const [refInternal, setRefInternal] = useState(null);

  useEffect(() => {
    if (!value && !!refInternal) {
      refInternal.value = "";
    }
    if (setFocus && !!refInternal) {
      refInternal.focus();
    }
  }, [refInternal]);

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const validateEmail = (email) => {
    const emailMatch =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (String(email).toLowerCase().match(emailMatch)) {
      setValidation("success");
      return true;
    } else {
      setValidation("error");
      return false;
    }
  };

  const validateInput = (event) => {
    let ret = true;
    if (!!event.target.value) {
      ret = validateEmail(event.target.value);
    } else if (!required) {
      setValidation("success");
      ret = true;
    } else {
      setValidation("error");
      ret = false;
    }
    onChange(event);
    return ret;
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>

      <Form.Group className="mb-3 full-input" controlId={controlId}>
        {!!label && (
          <Form.Label className="form-label ">
            <div>{label}</div>
            {required && <Asterisk />}
          </Form.Label>
        )}

        <Form.Control
          className="form-input"
          type="email"
          name={name}
          autoComplete="off"
          defaultValue={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={validateInput}
          onBlur={(event) => {
            if (validateInput(event)) {
              onBlur();
            }
          }}
          ref={(r) => {
            setRefInternal(r);
          }}
          disabled={disabled}
        />
      </Form.Group>
    </div>
  );
}
