import { produceAssessmentResult } from "components";

export const ALLOW_DEV_TESTING = true;

export const SAMPLE_TEST_PROGRESS = {
  All: {
    state: "in-progress",
    answers: [],
  },
  answers: [
    {
      id: "1.0",
      value: "No",
    },
    {
      id: "1.1",
      value: "Yes",
    },
    {
      id: "2.0",
      value: "Yes",
    },
    {
      id: "2.1",
      value: "Yes",
    },
    {
      id: "3.0",
      value: "Yes",
    },
    {
      id: "3.1",
      value: "Yes",
    },
    {
      id: "4.0",
      value: "No",
    },
    {
      id: "4.1",
      value: "Yes",
    },
    {
      id: "5.0",
      value: "Yes",
    },
    {
      id: "6.0",
      value: "Yes",
    },
    {
      id: "7.0",
      value: "Yes",
    },
    {
      id: "8.0",
      value: "Yes",
    },
    {
      id: "9.0",
      value: "Yes",
    },
    {
      id: "9.1",
      value: "Yes",
    },
    {
      id: "10.0",
      value: "Yes",
    },
    {
      id: "11.0",
      value: "No",
    },
    {
      id: "12.0",
      value: "Yes",
    },
    {
      id: "13.0",
      value: "Yes",
    },
    {
      id: "13.1",
      value: "Yes",
    },
    {
      id: "13.2",
      value: "Yes",
    },
    {
      id: "14.0",
      value: "Yes",
    },
    {
      id: "15.0",
      value: "Yes",
    },
    {
      id: "16.0",
      value: "Yes",
    },
    {
      id: "17.0",
      value: "Yes",
    },
    {
      id: "18.0",
      value: "Yes",
    },
    {
      id: "18.1",
      value: "Yes",
    },
    {
      id: "19.0",
      value: "Yes",
    },
    {
      id: "19.1",
      value: "Yes",
    },
    {
      id: "20.0",
      value: "Yes",
    },
    {
      id: "21.0",
      value: "Yes",
    },
    {
      id: "21.1",
      value: "Yes",
    },
    {
      id: "22.0",
      value: "Yes",
    },
    {
      id: "23.0",
      value: "Yes",
    },
    {
      id: "24.0",
      value: "Yes",
    },
    {
      id: "25.0",
      value: "Yes",
    },
  ],
};

export const TEST_ASSESSMENT_SUBMIT = (stepProgress) => {
  return produceAssessmentResult(
    stepProgress?.answers || SAMPLE_TEST_PROGRESS.answers,
  );
};
