import { API } from "aws-amplify";

export const getIssuedCertification = (id) => {
  const myInit = {
    queryStringParameters: {
      item: id,
    },
  };
  return API.get("certification", `/issuedCertificate?item=${id}`, myInit);
};
