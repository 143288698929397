import { API } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js";

export const getProductCheckoutSession = ({
  item,
  success_url,
  cancel_url,
  renewal,
}) => {
  const myInit = {
    queryStringParameters: {
      item,
      success_url,
      cancel_url,
      renewal,
      operation: "SINGLE_CERT_PRIVATE",
    },
  };

  return API.get("stripe", `/checkout`, myInit);
};

export const openProductCheckoutSession = async ({
  item,
  success_url,
  cancel_url,
  renewal = false,
}) => {
  const { sessionId, publishableKey, err } = await getProductCheckoutSession({
    item,
    success_url,
    cancel_url,
    renewal,
  });

  if (err === "FREE") {
    console.log("This item is free :)");
    window.location.reload();
  } else {
    const stripe = await loadStripe(publishableKey);

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
  }
};
