import "./index.scss";

import { ImgNoMobile } from "assets";
import { MetaDescription } from "components";

export function NoMobile() {
  return (
    <>
      <MetaDescription
        pageTitle="Not Mobile Ready"
        pageDescription="Switch to Desktop device."
      />

      <div className="nf-super-container f-row-center">
        <div className="nf-container f-row-center">
          <div className="content">
            <div className="nf-title">
              <h2 className="heading2">Switch!</h2>
              <p>
                Our mobile app isn’t far away. Please use a desktop device to
                access your CyberCert dashboard. Thanks!
              </p>
            </div>
            {/* <Link to={ROUTE.ROOT}>
                            <Button className="btn btn-warning-gd">
                                Back to Home
                            </Button>
                        </Link> */}
          </div>
          <div className="nf-illustration">
            <img src={ImgNoMobile} alt="Not Mobile Ready" />
          </div>
        </div>
      </div>
    </>
  );
}
