import { API } from "aws-amplify";

export const getGuideDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "GUIDE",
    },
  };

  return API.get("privateDoc", "/user?item=GUIDE", myInit);
};

export const getBadgesDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "CERTIFICATION_BADGES",
    },
  };

  return API.get("privateDoc", "/user?item=CERTIFICATION_BADGES", myInit);
};

export const getSpreadsheetDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "CERTIFICATION_SPREADSHEET",
    },
  };

  return API.get("privateDoc", "/user?item=CERTIFICATION_SPREADSHEET", myInit);
};

export const getWorkbookDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "CERTIFICATION_WORKBOOK",
    },
  };

  return API.get("privateDoc", "/user?item=CERTIFICATION_WORKBOOK", myInit);
};

export const getSignedAttestationDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "SIGNED_ATTESTATION",
    },
  };
  return API.get("privateDoc", "/user?item=SIGNED_ATTESTATION", myInit);
};

export const getCertificateDownloadUrl = () => {
  const myInit = {
    queryStringParameters: {
      item: "CERTIFICATE",
    },
  };
  return API.get("privateDoc", "/user?item=CERTIFICATE", myInit);
};

export const getPublicCertificateURL = ({ certificate }) => {
  const myInit = {
    queryStringParameters: {
      item: "PUBLIC_CERTIFICATE",
      certificate,
    },
  };
  return API.get(
    "publicDoc",
    `/?item=PUBLIC_CERTIFICATE&certificate=${certificate}`,
    myInit
  );
};

export const getSpecificLevelCertificateDownloadUrl = (level) => {
  const myInit = {
    queryStringParameters: {
      item: "CERTIFICATE",
      level,
    },
  };
  return API.get("privateDoc", "/user?item=CERTIFICATE", myInit);
};

export const getSpecificEnvelopeSignedAttestationUrl = (envelopeId) => {
  const myInit = {
    queryStringParameters: {
      item: "SIGNED_ATTESTATION",
      envelopeId,
    },
  };
  return API.get("privateDoc", "/user?item=SIGNED_ATTESTATION", myInit);
};

export const getSpecificCertAndSignedAttestationUrl = (level, envelopeId) => {
  const myInit = {
    queryStringParameters: {
      items: ["SIGNED_ATTESTATION", "CERTIFICATE"],
      envelopeId,
      level,
    },
  };
  return API.get("privateDoc", "/user", myInit);
}
