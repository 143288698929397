export const parseDate = (d) => {
  if (!d) {
    return "N/A";
  }
  const date = new Date(d);
  return `${date.getDate()} ${date.toLocaleString("en-US", {
    month: "short",
  })} ${date.getFullYear()}`;
};

export const parseDateTime12Format = (d) => {
  const aestTime = new Date(d).toLocaleString("en-US", {
    timeZone: "Australia/Brisbane",
  });
  const aestDate = new Date(aestTime);
  const hours = aestDate.getHours();
  return `${pad0left(hours % 12 || 12)}:${pad0left(
    aestDate.getMinutes(),
  )} ${hours >= 12 ? "PM" : "AM"}, ${aestDate.getDate()} ${aestDate.toLocaleString(
    "default",
    {
      month: "short",
    },
  )} ${aestDate.getFullYear()}`;
};

export const parseDateTime = (d) => {
  const aestTime = new Date(d).toLocaleString("en-US", {
    timeZone: "Australia/Brisbane",
  });
  const aestDate = new Date(aestTime);
  return `${pad0left(aestDate.getHours())}:${pad0left(
    aestDate.getMinutes(),
  )}, ${aestDate.getDate()} ${aestDate.toLocaleString("default", {
    month: "short",
  })} ${aestDate.getFullYear()}`;
};

export const pad0left = (num, size = 2) => {
  var s = "0".repeat(size) + num;
  return s.substring(s.length - size);
};

export const parseDateSlash = (d) => {
  if (!d) {
    return "NA";
  }
  const date = new Date(d);
  const pad = (v) => ("0" + v).slice(-2);
  return `${pad(date.getDate())}/${pad(
    date.getMonth() + 1,
  )}/${date.getFullYear()}`;
};

export const openZEWidget = () => {
  window.zE.activate();
};

export const sanitizeInputWithSpace = (s) => {
  return s.replace(/ /g, "");
};

// Utility function to convert a string to title case
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const versionCompare = (a, b) => {
  let result = 0;

  const version1 = a.toString().split(".");
  const version2 = b.toString().split(".");

  for (let i = 0; i < 4; ++i) {
    if (!version1[i]) {
      version1[i] = 0;
    }
    if (!version2[i]) {
      version2[i] = 0;
    }

    if (Number(version1[i]) < Number(version2[i])) {
      result = -1;
      break;
    }
    if (Number(version1[i]) > Number(version2[i])) {
      result = 1;
      break;
    }
  }

  return result;
};

export const isUpgradedRequirement = (requirement) => {
  const version = requirement.toString().split(".");
  return version[2] > 0;
};

export const sortRequirements = (requirements) =>
  requirements.sort((a, b) => versionCompare(a.requirementId, b.requirementId));

export const isExpired = (expiredDate) => {
  if (!expiredDate) {
    return false;
  }
  const expired = new Date(expiredDate);
  const today = new Date();
  return today.getTime() - expired.getTime() > 0;
};

export const displayABNFormat = (ABN) => {
  if (!ABN || ABN.length !== 11) {
    return "";
  }
  return ABN.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
};

export const clearUrlParamsState = () => {
  window.history.replaceState(
    null,
    "",
    `${window.location.origin}${window.location.pathname}`,
  );
};

export const validatePhoneNumber = (v) => {
  // Digital Mobile services
  return /^0[47]\d{8}$/.test(v);
};
