import "./index.scss";

import { useEffect, useState } from "react";

import { getAssessmentQuestionnaire } from "api";
import {
  AssessmentDone,
  AssessmentNotification,
  NavBreadcrumbList,
  Progress,
  Spinner,
  WelcomeText,
} from "components";
import { useLoadingState } from "middleware";
import { ROUTES } from "variables";

export function AssessmentResults({ user }) {
  const { loading, setLoading } = useLoadingState();
  const [assessment, setAssessment] = useState();

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    setLoading(true);

    const a = await getAssessmentQuestionnaire();
    setAssessment(a);

    if (!a?.result) {
      window.location.href = ROUTES.ASSESSMENT_TOOL;
    }

    window.scrollTo(0, 0);
    setLoading(false);
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <div className="dashboard-header">
        <div className="container-md">
          <div className="df-row mb-56-results">
            <div className="col-wl">
              <div className="card-wl">
                <div>
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTES.ROOT, name: "Dashboard" },
                      {
                        to: ROUTES.ASSESSMENT_RESULTS,
                        name: "Results",
                      },
                    ]}
                  />

                  <WelcomeText user={user} />
                </div>

                <div className="progress-notif">
                  <div className="text mt-14">
                    <p>Select your certification to get started.</p>
                  </div>

                  <AssessmentNotification />
                </div>
              </div>
            </div>

            <div className="col-pg">
              <Progress viewDetails={() => {}} />
            </div>
          </div>

          <AssessmentDone
            result={assessment?.result}
            eligibleRequirements={assessment?.eligibleRequirements}
          />
        </div>
      </div>
    </>
  );
}
