import "./password-strength.scss";

export function PasswordStrength({ passwdStrength }) {
  const getColor = () => {
    const percentage = passwdStrength?.percent || 0;
    if (percentage <= 40) {
      return "#dc3545";
    } else if (percentage <= 70) {
      return "#ffa412";
    } else {
      return "#28a745";
    }
  };

  const getStrength = () => {
    const percentage = passwdStrength?.percent || 0;
    if (percentage <= 40) {
      return "Weak";
    } else if (percentage <= 70) {
      return "Medium";
    } else {
      return "Strong";
    }
  };

  return (
    <>
      <div className="f-row-spread">
        <div>
          Password Strength:{" "}
          <b style={{ color: getColor() }}> {getStrength()} </b>
        </div>
        <div className="minimum">Minimum of 8 characters</div>
      </div>
      <div className="indicator">
        <div
          className="bar"
          style={{
            width: `${passwdStrength?.percent || 0}%`,
            backgroundColor: getColor(),
          }}
        ></div>
      </div>
    </>
  );
}
