import { forwardRef, useEffect, useImperativeHandle } from "react";

import { getAssessmentQuestionnaire } from "api";
import { AssessmentToolQuestionInput, OTHERS_TAG } from "components";

import { getQuestionaire } from "./questionaire";

export const QuestionaireStep = forwardRef((props, ref) => {
  const {
    title,
    quizCounter,
    setQuizCounter,
    pagination = 10,
    pages,
    setPages,
    stepProgress,
    setStepProgress,
    shouldShow,
    checkAllQuestionsAnswered,
  } = props;
  const questions = getQuestionaire(title);
  const count = questions.flatMap((q) => q).length;

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    const questionPages = updatePages();
    gotoPageFn(quizCounter?.page, questionPages, "", true);
  }, [stepProgress]);

  const setup = async () => {
    await setupAnswers();
    const questionPages = updatePages();
    gotoPageFn(1, questionPages);
  };

  const setupAnswers = async () => {
    const assessment = await getAssessmentQuestionnaire();

    let initialAnswers = questions
      .flatMap((q) => q)
      .map((q) => ({
        id: q.id,
      }));

    for (let i = 0; i < initialAnswers.length; ++i) {
      const existed = assessment?.progress?.filter(
        (a) => a.id === initialAnswers[i].id,
      )[0];

      if (!!existed) {
        initialAnswers[i] = existed;
      }
    }

    console.log(initialAnswers);

    setStepProgress({
      ...stepProgress,
      answers: initialAnswers,
    });
  };

  const updatePages = () => {
    let questionPages = [[]];
    let curPage = 1;
    let i = 0;
    for (let idx = 0; idx < questions.length; ++idx) {
      if (i >= pagination) {
        questionPages.push([]);
        i = 0;
        curPage++;
      }
      let questionGroup = questions[idx];
      // if (i + questionGroup.length > pagination) {
      //   questionPages.push([]);
      //   i = 0;
      //   curPage++;
      // }
      if (shouldShow(questionGroup[0])) {
        const displayId = i + 1 + (curPage - 1) * 10;
        questionGroup = questionGroup.map((question) => ({
          ...question,
          displayId,
        }));
        questionPages[curPage - 1].push(questionGroup);
        i += 1;
      }
    }
    setPages(questionPages);
    return questionPages;
  };

  const gotoPageFn = (
    p,
    questionPages = pages,
    scrollToElement,
    noScroll = false,
  ) => {
    if (!p || p > questionPages.length) {
      return;
    }
    let lo = 1;
    for (let i = 0; i < p - 1; ++i) {
      const page = questionPages[i];
      lo += page.length;
    }
    const hi = questionPages[p - 1].length + lo - 1;

    setQuizCounter({
      lo,
      hi,
      total: questions.length,
      displayTotal: questions.filter((q) => shouldShow(q[0]))?.length || 0,
      page: p,
    });

    if (!noScroll) {
      setTimeout(() => {
        if (!scrollToElement) {
          window.scrollTo(0, 0);
        } else {
          document
            .getElementById(scrollToElement)
            ?.scrollIntoView({ block: "center", inline: "center" });
        }
      }, 200);
    }
  };

  const gotoQuestionFn = (q) => {
    let i = 0;
    for (i = 0; i < pages.length; ++i) {
      const page = pages[i];
      if (page.flatMap((a) => a).filter((a) => a.id === q).length > 0) {
        break;
      }
    }
    gotoPageFn(i + 1, pages, `assessment-input-${q}`);
  };

  useImperativeHandle(ref, () => ({
    gotoPage(n) {
      gotoPageFn(n);
    },

    gotoSubmitPage() {
      gotoPageFn(pages.length, pages, "assessment-btn-submit");
    },

    gotoLastPage() {},

    goToQuestion(q) {
      gotoQuestionFn(q);
    },
  }));

  const onQuestionInput = (event) => {
    switch (event.target.type) {
      case "radio":
        onRadioQuestionInput(event);
        checkAllQuestionsAnswered();
        break;
      case "checkbox":
        onCheckboxQuestionInput(event);
        break;
      case "text":
        onTextQuestionInput(event);
        break;
      case "checkbox-others":
        onCheckboxOthersInput(event);
        break;
      default:
    }
  };

  const onCheckboxOthersInput = (event) => {
    const qid = event.currentTarget?.getAttribute("data-id");
    const value = event.target?.value;
    const checked = event.target?.checked;
    let answer = stepProgress?.answers.filter((a) => a.id === qid)[0];

    const checkedOptions = (
      stepProgress.answers.filter((q) => q?.id === qid)[0].value || ""
    ).split(/[\r\n]+/);

    if (checked === false) {
      answer.value = "";
    } else {
      answer.value = value;
    }
    const newStepProgress = {
      ...stepProgress,
      answers: stepProgress.answers.map((a) => {
        if (a.id === qid) {
          if (
            checked === true &&
            !checkedOptions.filter((o) => o.includes(OTHERS_TAG))[0]
          ) {
            checkedOptions.push(value);
            return {
              ...a,
              value: checkedOptions.filter(Boolean).join("\r\n"),
            };
          } else if (checked === false) {
            return {
              ...a,
              value: checkedOptions
                .filter((o) => !o.includes(OTHERS_TAG))
                .filter(Boolean)
                .join("\r\n"),
            };
          } else {
            return {
              ...a,
              value: checkedOptions
                .map((o) => {
                  if (o.includes(OTHERS_TAG)) {
                    return value;
                  } else {
                    return o;
                  }
                })
                .filter(Boolean)
                .join("\r\n"),
            };
          }
        } else {
          return a;
        }
      }),
    };
    setStepProgress(newStepProgress);
  };

  const onRadioQuestionInput = (event) => {
    const qid = event.target?.getAttribute("data-id");
    const option = event.target?.getAttribute("data-option");
    // console.log(qid, option);
    const newStepProgress = {
      ...stepProgress,
      answers: stepProgress.answers.map((q) => {
        if (q.id === qid) {
          return {
            ...q,
            value: option,
          };
        } else {
          return q;
        }
      }),
    };
    setStepProgress(newStepProgress);
  };

  const onCheckboxQuestionInput = (event) => {
    const qid = event.target?.getAttribute("data-id");
    const option = event.target?.getAttribute("data-option");
    const checked = event.target.checked;
    // console.log(qid, option, checked);
    const checkedOptions = (
      stepProgress.answers.filter((q) => q?.id === qid)[0].value || ""
    ).split(/[\r\n]+/);
    const newStepProgress = {
      ...stepProgress,
      answers: stepProgress.answers.map((q) => {
        if (q.id === qid) {
          if (checked && !checkedOptions.includes(option)) {
            checkedOptions.push(option);
            return {
              ...q,
              value: checkedOptions.filter(Boolean).join("\r\n"),
            };
          } else if (!checked) {
            return {
              ...q,
              value: checkedOptions
                .filter((o) => o !== option)
                .filter(Boolean)
                .join("\r\n"),
            };
          } else {
            return q;
          }
        } else {
          return q;
        }
      }),
    };
    setStepProgress(newStepProgress);
  };

  const onTextQuestionInput = (event) => {
    const qid = event.target?.getAttribute("data-id");
    const value = event.target.value;
    const newStepProgress = {
      ...stepProgress,
      answers: stepProgress.answers.map((q) => {
        if (q.id === qid) {
          return {
            ...q,
            value,
          };
        } else {
          return q;
        }
      }),
    };
    setStepProgress(newStepProgress);
  };

  return (
    <>
      {!!pages &&
        pages.length > 0 &&
        pages[quizCounter?.page - 1]
          ?.filter(
            // should show at least first question
            (questionGroup) =>
              !!questionGroup[0] && shouldShow(questionGroup[0]),
          )
          ?.map((questionGroup) => (
            <div className="quiz-item">
              {questionGroup.map((question, i) => (
                <div
                  id={`assessment-input-${question.id}`}
                  className={`${shouldShow(question) && "show"} ${
                    i > 0
                      ? "slide-block sub-question question"
                      : "top-question question"
                  }`}
                >
                  <AssessmentToolQuestionInput
                    group={question?.displayId}
                    groupId={question.groupId}
                    id={question.id}
                    type={question.type}
                    title={question.title}
                    options={question.options}
                    others={question.others}
                    stepProgress={stepProgress}
                    inheritOptions={question.inheritOptions}
                    dependsOn={question.dependsOn}
                    onChange={onQuestionInput}
                  />
                </div>
              ))}
            </div>
          ))}
    </>
  );
});
