import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  session: undefined,
};

export const appSessionSlice = createSlice({
  name: "appSession",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
  },
});

const { setSession } = appSessionSlice.actions;

export const setSessionAction = setSession;

export default appSessionSlice.reducer;
