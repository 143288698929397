export function WelcomeText({ user, progress, certificationInfo }) {
  const orgNameLimit = 30;
  return (
    <div className="heading mt-24">
      <h1 className="title">
        {progress !== "NOT-SET" ? (
          <>
            {progress?.status === "COMPLETED" ? (
              <>
                Congratulations,{" "}
                <span>
                  {certificationInfo?.user?.firstName || user?.firstName}!{" "}
                  <i className="completed"></i>
                </span>
              </>
            ) : (
              <>
                Hi,{" "}
                <span>
                  {certificationInfo?.user?.firstName || user?.firstName}!{" "}
                  {/*<i>👋</i>*/}
                </span>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </h1>

      <h3
        className={`f-row-gap-12 subtitle mt-14 ${(
          certificationInfo?.organisation ||
          user?.organisation ||
          user?.organisation
        )?.name?.length > 80 && "long"
          }`}
      >
        <svg
          width="19"
          height="16"
          viewBox="0 0 19 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M9.76299 3.55556V0H0.915527V16H18.6104V3.55556H9.76299ZM4.45451 14.2222H2.68502V12.4444H4.45451V14.2222ZM4.45451 10.6667H2.68502V8.88889H4.45451V10.6667ZM4.45451 7.11111H2.68502V5.33333H4.45451V7.11111ZM4.45451 3.55556H2.68502V1.77778H4.45451V3.55556ZM7.99349 14.2222H6.224V12.4444H7.99349V14.2222ZM7.99349 10.6667H6.224V8.88889H7.99349V10.6667ZM7.99349 7.11111H6.224V5.33333H7.99349V7.11111ZM7.99349 3.55556H6.224V1.77778H7.99349V3.55556ZM16.841 14.2222H9.76299V12.4444H11.5325V10.6667H9.76299V8.88889H11.5325V7.11111H9.76299V5.33333H16.841V14.2222ZM15.0715 7.11111H13.302V8.88889H15.0715V7.11111ZM15.0715 10.6667H13.302V12.4444H15.0715V10.6667Z"
            fill="#6C757D"
          />
        </svg>
        <div>
          {(certificationInfo?.organisation || user?.organisation)?.name?.length >
            orgNameLimit
            ? (
              certificationInfo?.organisation || user?.organisation
            )?.name?.substring(0, orgNameLimit - 3) + "..."
            : (certificationInfo?.organisation || user?.organisation)?.name}
        </div>
      </h3>
    </div>
  );
}
