import "./account-certification.scss";

import { useEffect, useState } from "react";

import { getCertificationInfo } from "api/functions/certification/info";
import {
  AccountText,
  DashboardTabContainer,
  MetaDescription,
  NavBreadcrumbList,
  Progress,
  Spinner,
} from "components";
import { useLoadingState } from "middleware";
import { ROUTES } from "variables";

export function AccountCertification({ user }) {
  const [progress, setProgress] = useState({});
  const [certificationInfo, setCertificationInfo] = useState();
  const [guide, setGuide] = useState({});
  const [internalLoading, setInternalLoading] = useState(true);
  const { loading, setLoading } = useLoadingState();

  useEffect(() => {
    setup();
  }, [user]);

  const setup = async () => {
    if (!!user?.firstName) {
      setInternalLoading(false);
      setLoading(false);
    }
    const info = await getCertificationInfo();
    setCertificationInfo(info);
    setProgress(info?.customer?.certification?.progress);
    if (!!info?.customer?.certification?.progress) {
      setGuide({
        purchased: info?.customer?.guidePurchased?.guidePurchased,
        purchasedDate: info?.customer?.guidePurchasedDate,
      });
    }
    if (!!user) {
      const interval = setInterval(
        () =>
          getCertificationInfo().then((result) => {
            setProgress(result?.customer?.certification?.progress);
          }),
        5000,
      );
      return () => {
        clearInterval(interval);
      };
    }
  };

  return (
    <>
      <MetaDescription
        pageTitle="Account"
        pageDescription="Edit your account preferences"
      />
      <div className="account-certification-container">
        {(loading.loading || internalLoading) && (
          <div className="overlay white-bg">
            <Spinner svgTemplate={true} />
          </div>
        )}

        <div className="dashboard-header">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTES.ROOT, name: "Dashboard" },
                      { to: ROUTES.ACCOUNT, name: "Account" },
                    ]}
                  />

                  <AccountText
                    user={user}
                    certificationInfo={certificationInfo}
                  />

                  {/* <WelcomeText user={user} />

                  <ProgressStatus progress={progress} /> 

                  <NotificationBox />*/}
                </div>
              </div>

              <div className="col-pg">
                <Progress />
              </div>
            </div>
          </div>
        </div>

        <DashboardTabContainer
          userData={user}
          progress={progress}
          certificationInfo={certificationInfo}
        />
      </div>
    </>
  );
}
