export const filterAlphaKeyPress = (event) => {
  if (!/[a-zA-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};

export const filterAlphaPaste = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!/^[A-Za-z\s]+$/.test(value)) {
    event.preventDefault();
  }
};

export const filterPhoneNoKeyPress = (event) => {
  if (!/[+0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const filterPhoneNoPaste = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!/^[+0-9]+$/.test(value)) {
    event.preventDefault();
  }
};

export const filterNumberKeyPress = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const filterNumberPaste = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!/^[0-9]+$/.test(value)) {
    event.preventDefault();
  }
};
