import "../legal.scss";

import { useEffect } from "react";

import { Spinner } from "components";
import { useLoadingState } from "middleware";
import { getLandingPage } from "services";

export function PrivacyPolicy() {
  const { loading } = useLoadingState();

  useEffect(() => {
    window.location.href = `${getLandingPage()}/privacy-policy`;
  }, []);

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}
    </>
  );
}

// export function PrivacyPolicy() {
//   const updatedDate = "August 28 2023";

//   return (
//     <>
//       <MetaDescription
//         pageTitle="Privacy Policy"
//         pageDescription="Please read the Privacy Policy set by CyberCert"
//       />
//       <div className="main-dashboard-container">
//         <div className="dashboard-header">
//           <div className="container-md">
//             <div className="df-row">
//               <div className="col-wl">
//                 <div className="card-wl w-100">
//                   <NavBreadcrumbList
//                     items={[
//                       { to: ROUTE.ROOT, name: "Dashboard" },
//                       {
//                         to: ROUTE.PRIVACY_POLICY,
//                         name: "Privacy Policy",
//                       },
//                     ]}
//                   />

//                   <div className="heading">
//                     <h1 className="title">Privacy Policy</h1>
//                   </div>

//                   <div className="text">
//                     This policy was last updated on {updatedDate}
//                   </div>
//                 </div>
//               </div>

//               <div className="col-pg"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="legal-container">
//         <div className="legal-section">
//           <div className="container-md">
//             <div className="text-block">
//               <p>
//                 This privacy policy applies to CyberCert Pty Ltd and our
//                 handling of personal information. CyberCert (ABN 87 662 681 423)
//                 is a technology company that provides Software as a Service
//                 (SaaS) and Platform as a Service solutions for governments and
//                 organizations (i.e. our customers).
//               </p>
//               <p>
//                 Due to the commercial nature of our services, CyberCert, for the
//                 most part, collects the information of individuals in their
//                 professional capacity. For example, we collect information of
//                 the person who registers or activates the organization's
//                 administrator or user account, as part of their role within the
//                 customer organization.
//               </p>
//               <p>
//                 That being said, we recognize and value the protection of your
//                 personal information, which is an important part of our
//                 relationship with customers.
//               </p>
//               <p>
//                 This Privacy Policy explains how we handle personal information.
//                 As an Australian company, we apply the privacy principles set
//                 out in the Australian Privacy Act 1988 (Cth), which guide how we
//                 collect and manage personal information. Importantly, we:
//               </p>
//               <ul>
//                 <li>
//                   give you clear information about our personal information
//                   handling practices,
//                 </li>
//                 <li>
//                   only collect personal information that is necessary for our
//                   functions,
//                 </li>
//                 <li>
//                   understand the purpose of our services, and restrict our use
//                   and disclosure of personal information in that regard, and
//                 </li>
//                 <li>
//                   take reasonable steps to keep the personal information we have
//                   secure.
//                 </li>
//               </ul>
//             </div>
//             <div className="text-block">
//               <h3>Personal information</h3>
//               <p>
//                 Personal information is information that identifies a person (or
//                 could reasonably lead to them being identified).
//               </p>
//               <p>
//                 When providing our services, we collect some personal
//                 information. The types of personal information we collect and
//                 process depends on our relationship with you. We generally
//                 collect and process personal information from the following
//                 groups of people:
//               </p>
//               <ul>
//                 <li>
//                   <strong>Website visitors</strong> &ndash; We collect some
//                   personal information when people visit this website.
//                 </li>
//                 <li>
//                   <strong>Customers and prospective customers</strong> &ndash;
//                   Our customers are generally organizations, companies,
//                   corporations and government departments or agencies. The
//                   personal information we collect includes, for example, details
//                   of the contact person registered on the organization's
//                   administrator or user account.
//                 </li>
//                 <li>
//                   <strong>Customers that use our SaaS and PaaS services</strong>{" "}
//                   &ndash; When our customers activate their administrator or
//                   user account, we require some personal information to register
//                   the account. This information is limited and relates to
//                   details of the person in their professional capacity, i.e.
//                   business email and phone.
//                 </li>
//                 <li>
//                   <strong>
//                     People who are interested in working for CyberCert
//                   </strong>{" "}
//                   &ndash; We collect personal information of job applicants, and
//                   (where relevant) an applicant's referees.
//                 </li>
//               </ul>
//             </div>
//             <div className="text-block">
//               <h3>Personal information we collect</h3>
//               <p>
//                 The types of personal information we collect and process are set
//                 out below. We generally collect this information when you
//                 interact with our platform and website. We may also collect this
//                 information in person, via email, mail or phone.
//               </p>
//               <div className="open-close-holder">
//                 <div className="open-close">
//                   <Accordion
//                     title="Analytics data"
//                     content={
//                       <>
//                         <p>
//                           We collect and use analytics and cookie data from a
//                           person's use of the website (
//                           <a
//                             className="link-nounderline"
//                             href="https://www.cybercert.com.au/"
//                             target="_blank"
//                             rel="noreferrer noopener"
//                           >
//                             www.cybercert.com.au
//                           </a>
//                           ), to understand how people interact with our site.
//                         </p>
//                         <p>
//                           We generally collect this information through the use
//                           of cookies. More information about this is contained
//                           in our <Link to={ROUTE.COOKIE}>Cookie Notice</Link>.
//                         </p>
//                         <p>
//                           Information we collect and process when you use our
//                           website and platforms includes:
//                         </p>
//                         <ul className="bullet-list">
//                           <li>Your device's IP address</li>
//                           <li>
//                             The date and time that you visited our website
//                           </li>
//                           <li>Whether you have been to our website before</li>
//                           <li>What site referred you to our website</li>
//                           <li>
//                             Approximate location of the device you used to
//                             interact with our website
//                           </li>
//                         </ul>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Inquiries information"
//                     content={
//                       <>
//                         <p>
//                           If you are a contact person for an organization, we
//                           collect and process minimal personal information when
//                           you ask about our platforms and services. Inquiries
//                           can be made via email, post, or when you submit an
//                           online 'contact us' form.
//                         </p>
//                         <p>
//                           Information we collect and process may include the
//                           name of an organization's contact person, the
//                           organization's preferred phone, email and address
//                           details, and details of the inquiry or correspondence
//                           with us.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Customer contact information"
//                     content={
//                       <>
//                         <p>
//                           We may use the details of a customer's nominated
//                           contact person to communicate with our customer,
//                           including in regards to the platform, our services,
//                           sales, and accounts. Information we collect and
//                           process may include the name of the customer's contact
//                           person, the organization's preferred phone, email and
//                           address details.
//                         </p>
//                         <p>
//                           We may send promotional emails to a customer's
//                           nominated contact person, where they have opted to
//                           receive marketing material. Whilst these emails may be
//                           sent to a customer's contact person, they are intended
//                           for our customers, i.e. organizations. If at any time
//                           the customer wishes not to receive promotional
//                           communication from us, they may unsubscribe using the
//                           unsubscribe method described in the email.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Customer registration information"
//                     content={
//                       <>
//                         <p>
//                           We collect and process limited personal information to
//                           register or activate our customer's CyberCert account.
//                           We collect details of the customer's nominated person
//                           via our platform when you register your organization's
//                           account, including:
//                         </p>
//                         <ul className="bullet-list">
//                           <li>First name</li>
//                           <li>Last name</li>
//                           <li>Country location</li>
//                           <li>Business mobile number</li>
//                           <li>Business email</li>
//                           <li>Password</li>
//                           <li>Role within the customer organization</li>
//                           <li>
//                             Profile picture (where it includes an image of a
//                             person)
//                           </li>
//                         </ul>
//                         <p>
//                           When a customer uses our platform, they may provide us
//                           with contact information or other personal information
//                           that relates to an individual in their professional
//                           capacity, working for our customer's suppliers or
//                           customers. We collect and use this information for the
//                           purpose of providing our cyber security services to
//                           our customers.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Help and support information"
//                     content={
//                       <>
//                         <p>
//                           A customer may submit a question or request support by
//                           submitting a form located within the customer's
//                           administrator or user account. When the customer
//                           requests support, we collect the name of the
//                           customer's contact person, business email address and
//                           details of the question, issue or support request, and
//                           our correspondence with you in regard to the request.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Payment information"
//                     content={
//                       <>
//                         <p>
//                           We collect and process payment information to provide
//                           our products and services. When customers provide
//                           payment information to us, they will usually provide
//                           the payment information of the customer organization;
//                           not payment information of an individual.
//                         </p>
//                         <p>
//                           There may be limited circumstances where personal
//                           payment information is provided to and processed by us
//                           (such as name on card, card type, amount purchased).
//                           We do not knowingly receive payment information of a
//                           person; rather, we ask our customers to use their
//                           approved corporate payment facilities.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Feedback information"
//                     content={
//                       <>
//                         <p>
//                           We collect and process feedback information to improve
//                           the types and quality of services offered, and the
//                           manner in which those services are provided to
//                           customers. We may collect your opinions and feedback
//                           by conducting surveys or market research, or by
//                           seeking other information from you on a periodic
//                           basis.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Access and correction request or privacy complaint information"
//                     content={
//                       <>
//                         <p>
//                           When you submit a request to access or correct your
//                           personal information, or submit a privacy complaint,
//                           we use this information to process your request or
//                           investigate your concern, and to communicate with you.
//                           We collect and use your name, contact information and
//                           details regarding your request or concern.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Recruitment information"
//                     content={
//                       <>
//                         <p>
//                           When you apply for a job with us, we collect and
//                           process your personal information as part of the
//                           application, and potentially, the hiring process.
//                           Recruitment information we collect and process may
//                           include name, email, phone, address, resume, cover
//                           letter details and references.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="text-block">
//               <h3>Why we collect and use personal information</h3>
//               <p>
//                 We collect and process personal information for a number of
//                 reasons, which have been described in the table below:
//               </p>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>We collect and use...</th>
//                     <th>Purpose</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Analytics data</td>
//                     <td>
//                       To analyze the usage of our website and platform,
//                       including through the use of cookies, to improve your user
//                       experience.
//                       {/* If you do not wish for us to use cookies, you
// can follow the process set out in our{" "}
// <Link to={ROUTE.COOKIE}>Cookie Notice</Link>. */}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Inquiries information</td>
//                     <td>
//                       To answer your questions about our cyber security maturity
//                       assessment platform and services
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Customer contact information</td>
//                     <td>
//                       To enter into a contract with you (e.g. Terms of Use);
//                       <br />
//                       To provide you with access to the CyberCert platform{" "}
//                       <br /> To communicate with you as part of our cyber
//                       security services <br /> To send our customers promotional
//                       emails. Whilst these emails may be sent to a customer's
//                       contact person, they are intended for our customers
//                       organizations.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Help and support information</td>
//                     <td>
//                       To provide help and support in our customer's use of the
//                       CyberCert platform
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Payment information</td>
//                     <td>
//                       To accept payment for your purchase of our products or
//                       services
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Feedback information</td>
//                     <td>
//                       To improve the types and quality of services offered, and
//                       the manner in which those services are provided to
//                       customers
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       Access and correction requests, and privacy complaint
//                       information
//                     </td>
//                     <td>
//                       To process your request or investigate your concern, and
//                       communicate with you
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Recruitment information</td>
//                     <td>
//                       To process your job application and, if you are
//                       successful, to offer you a job and commence your
//                       employment
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div className="text-block">
//               <h3>Who we share your personal information with</h3>
//               <p>
//                 CyberCert uses external parties (i.e. vendors) to provide
//                 services and functions on our behalf. In order for vendors to
//                 provide these services, we may share personal information that
//                 relates to the services being provided. We ensure that vendors
//                 only process personal information for the purpose it was
//                 provided to them, and not for any other purpose.
//               </p>
//               <p>
//                 <strong>
//                   Our primary vendors that provide services on our behalf
//                   include:
//                 </strong>
//               </p>
//               <ul>
//                 <li>
//                   <a
//                     href="https://marketingplatform.google.com/about/analytics/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>Google Analytics</strong>
//                   </a>{" "}
//                   &ndash; provide analytics services, refer to the{" "}
//                   <a
//                     href="https://policies.google.com/privacy?hl=en"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Google Privacy Policy
//                   </a>
//                   .
//                 </li>
//                 <li>
//                   <a
//                     href="https://workspace.google.com/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>Google</strong>
//                   </a>{" "}
//                   &ndash; provides data storage and SaaS related services, refer
//                   to the{" "}
//                   <a
//                     href="https://policies.google.com/privacy?hl=en"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Google Privacy Policy
//                   </a>
//                   .
//                 </li>
//                 <li>
//                   <a
//                     href="https://aws.amazon.com/ru/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>Amazon Web Services (AWS)</strong>
//                   </a>{" "}
//                   &ndash; provides public cloud and IaaS related services, refer
//                   to the{" "}
//                   <a
//                     href="https://aws.amazon.com/privacy/?nc1=f_pr"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     AWS Privacy Notice
//                   </a>
//                   .
//                 </li>
//                 <li>
//                   <a
//                     href="https://www.hubspot.com/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>HubSpot</strong>
//                   </a>{" "}
//                   &ndash; provides our cloud based CRM services, refer to the{" "}
//                   <a
//                     href="https://legal.hubspot.com/privacy-policy?_ga=2.79006228.1086938271.1620356416-426976951.1620356416"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     HubSpot Privacy Policy
//                   </a>
//                   .
//                 </li>
//                 <li>
//                   <a
//                     href="https://www.zendesk.com/au/#georedirect"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>Zendesk</strong>
//                   </a>{" "}
//                   &ndash; provides our customers efficient help desk and
//                   customer service, refer to the{" "}
//                   <a
//                     href="https://www.zendesk.com/au/company/agreements-and-terms/privacy-notice/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Zendesk Privacy Policy
//                   </a>
//                   .
//                 </li>
//                 <li>
//                   <a
//                     href="https://stripe.com/au"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <strong>Stripe</strong>
//                   </a>{" "}
//                   &ndash; provides our customers a secure payment portal, refer
//                   to the{" "}
//                   <a
//                     href="https://stripe.com/au/privacy"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Stripe Privacy Policy
//                   </a>
//                   .
//                 </li>
//               </ul>
//               <p>
//                 We also have contracts with other domain specialists, subject
//                 matter experts, management consultants and information
//                 technology professionals to assist in providing our services.
//                 There may be limited instances where we share your information
//                 with these external parties for the purpose of providing
//                 services on our behalf.
//               </p>
//               <p>
//                 We do not sell or share personal information with any
//                 advertisers, sponsors, content providers, media outlets, law
//                 enforcement or other person or entity, unless:
//               </p>
//               <ul>
//                 <li>We have your express permission, or</li>
//                 <li>
//                   There is a lawful ability or requirement for us to do so.
//                 </li>
//               </ul>
//             </div>
//             <div className="text-block">
//               <h3>Anonymity</h3>
//               <p>
//                 If you contact us with a general question, we may interact with
//                 you anonymously or through the use of pseudonym.
//               </p>
//               <p>
//                 However, due to the nature of our business, we are unable to
//                 provide our cyber security maturity assessment platform
//                 anonymously, as we require factual information in order to
//                 register customer accounts and provide our cyber security
//                 maturity assessment services.
//               </p>
//             </div>
//             <div className="text-block">
//               <h3>How we manage personal information</h3>
//               <p>
//                 At CyberCert, we securely manage and dispose of personal
//                 information that we collect and process, as outlined below:
//               </p>
//               <div className="open-close-holder">
//                 <div className="open-close">
//                   <Accordion
//                     title="Storage"
//                     content={
//                       <>
//                         <p>
//                           We store personal information that we collect using
//                           public cloud services that are secured using best
//                           practice authentication techniques to protect your
//                           data from unauthorized access, modification or
//                           disclosure. CyberCert stores data, including personal
//                           information, with Google and Amazon Web Services. The
//                           information is stored in the jurisdiction in which we
//                           collected it from (for example, where personal
//                           information is collected from within the US, the data
//                           is stored on servers located int the US) when it is
//                           possible to do so.
//                         </p>
//                         <p>
//                           We also use vendors to store specific types of
//                           personal information (such as analytics and accounting
//                           information) on our behalf. The personal information
//                           stored by vendors relates directly to their functions
//                           and services, and is stored in accordance with our
//                           contract with these providers.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Security"
//                     content={
//                       <>
//                         <p>
//                           CyberCert have implemented a range of data security
//                           practices and controls, including (but not limited
//                           to):
//                         </p>
//                         <ul>
//                           <li>
//                             Use of access controls, including multi-factor
//                             authentication,
//                           </li>
//                           <li>Security awareness training,</li>
//                           <li>
//                             Encryption of personal information during transit
//                             and at rest where possible,
//                           </li>
//                           <li>
//                             Personal information is stored and backed up in
//                             secure offsite locations,
//                           </li>
//                           <li>
//                             Back-ups of data is regularly tested for full
//                             operational recovery,
//                           </li>
//                           <li>
//                             Use of anti-virus on all company workstations and
//                             laptops, and
//                           </li>
//                           <li>
//                             Installation of firewalls where our network connects
//                             to the public internet.
//                           </li>
//                         </ul>
//                         <p>
//                           Protection of personal information from unauthorized
//                           access, disclosure, alteration or loss is a priority
//                           for us. Any concerns about the security of personal
//                           information collected or processed by CyberCert should
//                           be reported to us at using our contact details below.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//                 <div className="open-close">
//                   <Accordion
//                     title="Retention"
//                     content={
//                       <>
//                         <p>
//                           We keep your personal information for different
//                           periods, depending on the purpose that it was
//                           collected for. Where we no longer require personal
//                           information for the purpose it was collected, we will
//                           securely destroy that data.
//                         </p>
//                       </>
//                     }
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="text-block">
//               <h3>Accessing and correcting your personal information</h3>
//               <p>CyberCert supports your right to:</p>
//               <ol>
//                 <li>
//                   <strong>
//                     Access personal information we hold about you, or
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     Correct your personal information, where you think that it
//                     is inaccurate, incomplete or out of date.
//                   </strong>
//                 </li>
//               </ol>
//               <p>
//                 If you would like to access personal information we hold about
//                 you, we are happy to tell you what it is. We will not, however,
//                 tell someone else what personal information we hold about you
//                 (unless you permit us or there is a lawful ability or
//                 requirement for us to do so).
//               </p>
//               <p>
//                 If you think the personal information we hold about you is
//                 incorrect, out of date or misleading, we are happy to correct
//                 it.
//               </p>
//             </div>

//             <div className="text-block">
//               <h3>Your Legal Rights</h3>

//               <p>Your rights include the following:</p>

//               <ol>
//                 <li>
//                   <strong>
//                     You may request us to stop sending you marketing.
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     You may request us to stop using your personal information
//                     where we are doing so under legitimate interests unless it
//                     is needed for dealing with legal claims or we have other
//                     compelling legitimate reasons that override your rights.
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     You may access the personal information we hold on you.
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     There are some limited exceptions to this right, such as
//                     information relating to others who have not consented to the
//                     disclosure of their information and information which is
//                     legally privileged.
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     You may ask us to correct the personal information that you
//                     have shared with us if that information is inaccurate. We
//                     are not able to amend any data that has been created as a
//                     result of a transaction with us.
//                   </strong>
//                 </li>
//                 <li>
//                   <strong>
//                     You may ask for personal information which identifies you to
//                     be removed (anonymized). To do this the information that
//                     identifies you will be removed from our active systems.
//                     However, a separate and restricted copy of the identifying
//                     information may be kept for 7 years to meet the obligations
//                     we have to law enforcement, national authorities and legal
//                     proceedings. This right only applies under data protection
//                     laws in the European Union, the UK and some other countries.
//                   </strong>
//                 </li>
//               </ol>

//               <p>
//                 We will handle all requests in accordance with applicable law.
//                 However, depending on the right you wish to exercise, and the
//                 nature of the personal information involved, there may be legal
//                 reasons why we cannot grant your request.
//               </p>

//               <p>
//                 Subject to law, your personal data will be deleted upon request
//                 by you.
//               </p>

//               <p>
//                 To exercise any of these rights, including to delete your
//                 personal data, please contact us via the “Help” button located
//                 on this website.
//               </p>
//             </div>

//             <div className="text-block">
//               <h3>Questions and concerns?</h3>
//               <p>
//                 If you have a question about this Privacy Policy or are
//                 concerned about how we handle personal information, please
//                 contact us at:
//               </p>
//             </div>
//             <div className="text-block">
//               <h4>Online</h4>
//               <p>Via the Contact Form on the footer of our website.</p>
//             </div>
//             <div className="text-block">
//               <h4>Address</h4>
//               <p>
//                 CyberCert Pty Ltd <br /> 60 Martin Place <br /> Sydney, NSW 2000{" "}
//               </p>
//               <p>
//                 If you have made a privacy compliant and are not happy with how
//                 we responded to your concern, you are able to contact the Office
//                 of the Australian Information Commissioner (OAIC). The OAIC’s
//                 process is available{" "}
//                 <a
//                   href="https://www.oaic.gov.au/privacy/privacy-complaints/"
//                   target="_blank"
//                   rel="noreferrer noopener"
//                 >
//                   here.
//                 </a>
//               </p>
//             </div>
//             <div className="text-block">
//               <h3>Updates to this Privacy Policy</h3>
//               <p>
//                 We may decide to update this Privacy Policy to ensure that our
//                 personal information handling practices are correctly reflected.
//                 If we make a significant change to this policy, we will notify
//                 you by publishing a notice on our website.
//               </p>
//               <p>This policy was last revised on {updatedDate}.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
