import { useEffect, useState } from "react";

import { getAssessmentQuestionnaire } from "api";
import { AssessmentDone, RankBlock } from "components";

export function DashboardAssessmentTool() {
  const [assessment, setAssessment] = useState("NOT-SET");

  useEffect(() => {
    (async () => {
      const a = await getAssessmentQuestionnaire();

      setAssessment(a);
    })();
  }, []);

  return (
    <>
      <div
        className="certification-section assessment-tool"
        id="dashboard-assessment-tool-panel"
      >
        <div className="container-md">
          {!(assessment?.result > 0) && (
            <div className="assessment-panel">
              <RankBlock assessment={assessment} />
            </div>
          )}

          {!!assessment?.result && (
            <AssessmentDone
              result={assessment?.result}
              eligibleRequirements={assessment?.eligibleRequirements}
            />
          )}
        </div>
      </div>
    </>
  );
}
