import "./auth-layout.scss";

import { logoDark } from "assets";
import { CopyrightTag } from "components";
import { Link } from "react-router-dom";
import { ROUTES } from "variables";

export function AuthLayout({ metaDescription, children }) {
  return (
    <>
      {metaDescription}

      <div className="auth-super-container">
        <div className="auth-container">
          <div className="logo beta">
            <Link to={ROUTES.ROOT}>
              <img src={logoDark} alt="Logo" />
            </Link>
            {/* <ul className="status-label-list beta-blue"><li>BETA</li></ul> */}
          </div>

          {children}
        </div>

        <div className="small-footer">
          <div>
            <p>Release 20230601</p>
            <p>
              <CopyrightTag release={false} /> ABN 87 662 681 423
            </p>
          </div>
          <div className="sf-links">
            <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
            {" | "}
            <Link to={ROUTES.TERMS}>Terms of use</Link>
          </div>
        </div>
      </div>
    </>
  );
}
