import { store } from "middleware";
import { Provider } from "react-redux";
import { Router } from "routes";
import { useEffect, useState } from "react";
import "./App.scss";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const setFavicon = () => {
      const faviconLink = document.querySelector("link[rel='icon']");
      const appleTouchIconLink = document.querySelector("link[rel='apple-touch-icon']");

      if (isDarkMode) {
        faviconLink.href = `${process.env.PUBLIC_URL}/favicon32.png`;
        appleTouchIconLink.href = `${process.env.PUBLIC_URL}/logo192.png`;
      } else {
        faviconLink.href = `${process.env.PUBLIC_URL}/favicon32-dark.png`;
        appleTouchIconLink.href = `${process.env.PUBLIC_URL}/logo192-dark.png`;
      }
    };

    setFavicon();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [isDarkMode]);

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
