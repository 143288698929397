import { getAssessmentQuestionnaire } from "api/graphql/assessment-tool/queries";
import { getUser } from "api/graphql/user";
import { makeCreateFunction, makeUpdateFunction } from "api/utils";
import {
  createRecommendationTool as createAPI,
  updateRecommendationTool as updateAPI,
} from "graphql/mutations";
import { AuthService } from "services";

export const createAssessmentQuestionnaire = async (input) => {
  const user = await getUser((await AuthService.getCurrentAuthUserInfo())?.id);

  if (!user) {
    console.error("No user found?");
    return;
  }

  return makeCreateFunction({
    name: "createRecommendationTool",
    query: createAPI,
    params: {
      input: {
        ...input,
        ABN: user.ABN,
      },
    },
  })();
};

export const updateAssessmentQuestionaire = async (input) => {
  const questionnaire = await getAssessmentQuestionnaire();

  return makeUpdateFunction({
    name: "updateRecommendationTool",
    query: updateAPI,
    params: {
      input: {
        id: questionnaire.id,
        result: input?.result,
        progress: input?.progress,
        eligibleRequirements: input?.eligibleRequirements,
      },
    },
  })();
};
