import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
};

export const confettiSlice = createSlice({
  name: "confetti",
  initialState,
  reducers: {
    setConfetti: (state, action) => {
      state.current = action.payload;
    },
  },
});

const { setConfetti } = confettiSlice.actions;

export const setConfettiAction = setConfetti;

export default confettiSlice.reducer;
