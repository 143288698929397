import { useEffect, useState } from "react";

import {
  AttestationLink,
  CertificateLink,
  CertificationDates,
  CredlyLink,
  StatusBadge,
} from "components";
import { useDownloadUrlState } from "middleware";
import { Link } from "react-router-dom";
import { parseDate } from "services";
import { BADGE_ANIMS, BADGES, CERTIFICATION_INFO, ROUTES } from "variables";

export function DashboardSummaryComplete({ progress }) {
  const [purchasedDate, setPurchasedDate] = useState("");
  const [completedDate, setCompletedDate] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const { downloadUrl } = useDownloadUrlState();

  useEffect(() => {
    if (!!progress) {
      setPurchasedDate(parseDate(progress.purchasedDate || progress.createdAt));
      if (!!progress?.completedDate) {
        setCompletedDate(parseDate(progress.completedDate));
      }
      if (!!progress?.expiredDate) {
        setExpiredDate(parseDate(progress.expiredDate));
      }
    }
  }, [progress]);

  return (
    <div className="card-compl">
      <div className="custom-table-holder">
        <h4 className="table-heading">
          <svg
            width="19"
            height="24"
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 24V2.25C0 1.00734 1.06331 0 2.375 0H16.625C17.9367 0 19 1.00734 19 2.25V24L9.5 18.75L0 24Z"
              fill="black"
            />
          </svg>
          Level {progress?.level} -{" "}
          {!!progress?.level &&
            CERTIFICATION_INFO[progress?.level].marketingName}
        </h4>
        <ul className="custom-table">
          {/* <li>
            <strong className="tc-title">CERTIFICATE ID</strong>
            <span>{progress?.id}</span>
          </li> */}
          <li>
            <strong className="tc-title">STATUS</strong>
            <ul className="status-label-list success">
              <li>
                <StatusBadge progress={progress} />
              </li>
            </ul>
          </li>
          <CertificationDates
            progress={progress}
            completedDate={completedDate}
            expiredDate={expiredDate}
            purchasedDate={purchasedDate}
          />
          {/* <li>
            <strong className="tc-title">TOTAL</strong>
            <span>${PRICING[progress?.level]} + gst</span>
          </li> */}
        </ul>
        {/* <div className="compl-text">        
          <p>
              <strong>Congratulations! 🎉</strong> {" "}
              You have completed your Level {progress?.level} Cyber Security Certification.
          </p>         
          <p>
            Download your certificate and then share it with your
            partners, clients and customers.
          </p>
        </div>        */}
      </div>

      <div className="card-lvl card-lvl-animation">
        <video
          autoPlay
          loop
          playsInline
          muted
          width="100%"
          height="100%"
          src={BADGE_ANIMS[progress?.level]}
        >
          <img
            src={BADGES[progress?.level]}
            className="badgeSeal"
            alt={`Level ${progress?.level} Badge`}
          />
        </video>

        <h4 className="card-title">Certification Documents</h4>
        <ul className="download-list">
          <li>
            <CertificateLink
              href={downloadUrl.certificateLink}
              progress={progress}
            />
          </li>
          <li>
            <AttestationLink
              href={downloadUrl.signedDeedLink}
              progress={progress}
            />
          </li>
          <li>
            <CredlyLink href={downloadUrl.badges} progress={progress} />
          </li>
          {/* <li>
            <a href="/" download>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
                  fill="black"
                />
              </svg>
              Download Invoice (.pdf)
            </a>
          </li> */}
        </ul>
        <Link
          className="btn btn-warning"
          to={`${ROUTES.CERTIFICATION_MANAGER}#step`}
        >
          View Details
        </Link>
      </div>
    </div>
  );
}
