import { getAssessmentQuestionnaire } from "api";
import { useEffect, useState } from "react";
import { isExpired } from "services";

const marketingNames = {
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond",
};

export function ProgressStatus({ progress }) {
  const [assessment, setAssessment] = useState("NOT-SET");

  useEffect(() => {
    (async () => {
      const a = await getAssessmentQuestionnaire();

      setAssessment(a);
    })();
  }, []);

  return (
    <>
      {!!progress ? (
        progress.status === "COMPLETED" ? (
          <>
            {isExpired(progress?.expiredDate) ? (
              <div className="text mt-14">
                <p>
                  Your {marketingNames[progress?.level]} Certification is
                  expired.
                </p>
              </div>
            ) : (
              <div className="text mt-14">
                <p>
                  Your {marketingNames[progress?.level]} certification is
                  active.
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="text mt-14">
            <p>
              Your {marketingNames[progress?.level]} certification is in
              progress.
            </p>
          </div>
        )
      ) : (
        <>
          {!!assessment && (
            <>
              {!!assessment?.result ? (
                <div className="text mt-14">
                  <p>Select your certification to get started.</p>
                </div>
              ) : (
                <div className="text">
                  <p className="blank-text">Welcome to CyberCert!</p>
                  <p>
                    Complete a short recommendation tool to find out what certification
                    is right for you.
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
