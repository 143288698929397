import { Navigate } from "react-router-dom";
import { ROUTES } from "variables";

/**
 * Check if user logged in
 * so app can route user to target element
 *
 * @param {JSX.Element} element
 * @param {boolean} loggedIn
 * @returns
 */
const requireLoggedInElement = (element, loggedIn) => {
  return loggedIn ? element : <Navigate to={ROUTES.LOGIN} />;
};

/**
 * Check if user logged out
 * so app can route user to target element
 *
 * @param {JSX.element} element
 * @param {boolean} loggedIn
 * @returns
 */
const requireLoggedOutElement = (element, loggedIn) => {
  return loggedIn ? <Navigate to={ROUTES.ROOT} /> : element;
};

/**
 * Check if user logged in and has
 * a profile so app can route user
 * to target element
 *
 * @param {JSX.element} element
 * @param {boolean} loggedIn
 * @param {object | undefined} profile
 * @returns
 */
const requireLoggedInWithProfileElement = (element, loggedIn, profile) => {
  if (!loggedIn) {
    return <Navigate to={ROUTES.LOGIN} />;
  }
  if (!profile) {
    return <Navigate to={ROUTES.PROFILE_SETUP} />;
  }
  return element;
};

/**
 * Check if user logged in and has
 * no profile so app can route user
 * to target element
 *
 * @param {JSX.element} element
 * @param {boolean} loggedIn
 * @param {object | undefined} profile
 * @returns
 */
const requireLoggedInWithoutProfileElement = (element, loggedIn, profile) => {
  if (!loggedIn) {
    return <Navigate to={ROUTES.LOGIN} />;
  }
  if (!profile) {
    return element;
  }
  return <Navigate to={ROUTES.ROOT} />;
};

export const RouteService = {
  requireLoggedInElement,
  requireLoggedOutElement,
  requireLoggedInWithProfileElement,
  requireLoggedInWithoutProfileElement,
};
