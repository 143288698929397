// Landing app
export const DEV_LANDING_HOSTNAME = "cmxdev.cybercert.ai";
export const DEMO_LANDING_HOSTNAME = "demo.cybercert.ai";
export const LANDING_HOSTNAME = "cybercert.ai";
export const WWW_LANDING_HOSTNAME = "www.cybercert.ai";
// Partners MSP
export const DEV_PARTNERS_MSP_HOSTNAME = "dev.partners.cybercert.ai";
export const PARTNERS_MSP_HOSTNAME = "partners.cybercert.ai";
// Dashboard app
export const DEV_DASHBOARD_HOSTNAME = "dev.dashboard.cybercert.ai";
export const DASHBOARD_HOSTNAME = "dashboard.cybercert.ai";
export const PARTNER_DASHBOARD_HOSTNAME = "partner.dashboard.cybercert.ai";
// Certification app
export const DEV_CERTIFICATION_HOSTNAME = "dev.certification.cybercert.ai";
export const BETA_CERTIFICATION_HOSTNAME = "beta.certification.cybercert.ai";
export const DEMO_CERTIFICATION_HOSTNAME = "demo.certification.cybercert.ai";
export const CERTIFICATION_HOSTNAME = "certification.cybercert.ai";
