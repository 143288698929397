import { getRequirement as getAPI, listRequirements } from "graphql/queries";
import { makeGetFunction } from "../../utils";

export const getRequirement = (id) =>
  makeGetFunction({
    name: "getRequirement",
    query: getAPI,
    params: {
      id,
    },
  })();

export const getRequirementsByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "listRequirements",
        query: listRequirements,
        params: {
          filter: {
            forABN: {
              eq: ABN,
            },
          },
          limit: 100000,
        },
      })()
    : undefined;

export const getOrganisationRequirementById = (id, ABN) =>
  !!ABN && !!id
    ? makeGetFunction({
        name: "listRequirements",
        query: listRequirements,
        params: {
          filter: {
            forABN: {
              eq: ABN,
            },
            requirementId: {
              eq: id,
            },
          },
        },
      })()
    : undefined;
