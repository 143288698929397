import { API } from "aws-amplify";

export const getCertificationInfo = async () => {
  let result;
  try {
    const myInit = {
      queryStringParameters: {},
    };
    result = await API.get("certification", `/getCertificationInfo`, myInit);
  } catch (e) {
    console.log(e);
  }
  return result;
};
