import { useEffect, useState } from "react";

import { getProductStaticPrice, getUser } from "api";
import {
  AssessmentDoneDowngrade,
  AssessmentDoneUpgrade,
  AssessmentResultLevel,
  Spinner,
} from "components";
import { useLoadingState } from "middleware";
import { AuthService } from "services";
import { CERTIFICATION_INFO } from "variables";

export function AssessmentDone({ result, eligibleRequirements }) {
  const [currentLevel, setCurrentLevel] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [prices, setPrices] = useState([]);
  const { loading, setLoading } = useLoadingState();
  const [internalLoading, setInternalLoading] = useState(true);
  const [howToQualifyLevel, setHowToQualifyLevel] = useState();

  useEffect(() => {
    setupPrices();
  }, []);

  const setupPrices = async () => {
    setLoading(true);
    setInternalLoading(true);

    const staticPrices = (
      await Promise.all(
        // [1, 2, 3, 4, 5]
        [1, 2, 3].map((l) =>
          getProductStaticPrice({
            item: `LEVEL_${l}`,
          }),
        ),
      )
    ).map((p) => p.price);

    setPrices(staticPrices);

    setLoading(false);
    setInternalLoading(false);
  };

  useEffect(() => {
    AuthService.getCurrentAuthUserInfo().then((info) => {
      getUser(info?.id).then((user) => {
        setCurrentLevel(user?.organisation?.certificationProgress?.level);
        setCurrentStatus(user?.organisation?.certificationProgress?.status);
      });
    });
  }, []);

  return (
    <>
      {(loading.loading || internalLoading) && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      {!!result ? (
        <>
          <div className="panel-quiz-header assessment-done">
            <div className="panel-header">
              <div className="progress-bar green-focus">
                <span></span>
              </div>
              <div className="panel-steps">
                <div className="panel-steps-header">
                  <div className="heading">
                    <h4 className="suggested-h4">YOUR ASSESSMENT RESULTS</h4>
                    <h1>
                      Your organisation may immediately qualify for a <br />
                      Level {result} -{" "}
                      {CERTIFICATION_INFO[result].marketingName} certification.
                    </h1>
                    {/* <p>
                      We have evaluated your results and assessed your current
                      level of cyber maturity. We have also set out what
                      additional requirements you will need to implement to
                      qualify for higher certification levels.
                    </p> */}
                  </div>
                </div>
                <div className="label-block-holder">
                  <AssessmentResultLevel
                    howToQualifyLevel={howToQualifyLevel}
                    setHowToQualifyLevel={setHowToQualifyLevel}
                    result={result}
                    level={result}
                    currentLevel={currentLevel}
                    currentStatus={currentStatus}
                    price={prices[result - 1]}
                    eligibleRequirements={eligibleRequirements}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {[1, 2, 3, 4, 5] */}
          {[1, 2, 3].filter((l) => l > result).length > 0 && (
            <AssessmentDoneUpgrade
              result={result}
              howToQualifyLevel={howToQualifyLevel}
              setHowToQualifyLevel={setHowToQualifyLevel}
              currentLevel={currentLevel}
              currentStatus={currentStatus}
              eligibleRequirements={eligibleRequirements}
              prices={prices}
            />
          )}
          {/* {[1, 2, 3, 4, 5] */}
          {[1, 2, 3].filter((l) => l < result).length > 0 && (
            <AssessmentDoneDowngrade
              result={result}
              howToQualifyLevel={howToQualifyLevel}
              setHowToQualifyLevel={setHowToQualifyLevel}
              currentLevel={currentLevel}
              currentStatus={currentStatus}
              eligibleRequirements={eligibleRequirements}
              prices={prices}
            />
          )}
        </>
      ) : (
        // <div className="panel-quiz-header">
        //   <div className="panel-header">
        //     <div className="panel-steps">
        //       <div className="panel-steps-header">
        //         <div className="heading">
        //           <h4>OPTION 2</h4>

        //           <h1>Select and start.</h1>

        //           <p className="pb-0">
        //             To start, purchase the certification level you want to
        //             certify your organisation. We’ll give you an easy-to-use
        //             workbook with all the requirements you need to implement to
        //             qualify. When they’re all implemented, you’re ready for
        //             immediate certification. It’s that simple.
        //           </p>
        //         </div>
        //       </div>
        //       <div className="label-block-holder">
        //         {[1, 2, 3, 4, 5].map((l) => (
        //           <AssessmentBlankStateLevel
        //             howToQualifyLevel={howToQualifyLevel}
        //             setHowToQualifyLevel={setHowToQualifyLevel}
        //             level={l}
        //           />
        //         ))}
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <></>
      )}
    </>
  );
}
