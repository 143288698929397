import "./dashboard-cert-summary.scss";

import { isExpired } from "services";
import { LEVEL_ICONS, LEVEL_NAMES } from "variables";

import { DashboardSummaryComplete, DashboardSummaryInProgress } from "./";

export function DashboardCertSummary({ progress, setRef = () => {} }) {
  return (
    <div className="level-certification-block" ref={(r) => setRef(r)}>
      <div className="container-md">
        {/* <div className="progress-bar">
          <span></span>
        </div> */}
        <div
          className={
            progress?.status === "COMPLETED"
              ? `content completed ${
                  isExpired(progress?.expiredDate) && "expired"
                }`
              : "content"
          }
        >
          <h4 className="item-title">CERTIFICATION</h4>
          <div className="heading">
            <h2
              className={
                progress?.status === "COMPLETED"
                  ? `block-title completed-title ${
                      isExpired(progress?.expiredDate) && "expired"
                    }`
                  : "block-title"
              }
            >
              <img
                src={LEVEL_ICONS[progress?.level - 1]}
                alt={`${LEVEL_NAMES[progress?.level - 1]}.png`}
                width={32}
                height={32}
              />

              <div className="h2-cert-title">
                {progress?.status === "COMPLETED" ? (
                  <>
                    {/* SMB1001:2023 Level {progress?.level} (
                    {["Bronze", "Silver", "Gold"][progress?.level - 1]}) is {" "} */}
                    Your {["Bronze", "Silver", "Gold"][progress?.level - 1]}{" "}
                    certification is{" "}
                    {isExpired(progress?.expiredDate) ? (
                      <>expired</>
                    ) : (
                      <>active</>
                    )}
                  </>
                ) : (
                  <>
                    {/* {progress?.current > 1 ? "Continue" : "Start"} your Level{" "} */}
                    {/* SMB1001:2023 Level {progress?.level} ( */}
                    {["Bronze", "Silver", "Gold"][progress?.level - 1]}
                  </>
                )}

                <div className="standard-subtitle">
                  SMB1001:2023 Level {progress?.level}
                </div>
              </div>
            </h2>
          </div>

          {progress?.status === "COMPLETED" ? (
            <DashboardSummaryComplete progress={progress} />
          ) : (
            <DashboardSummaryInProgress progress={progress} />
          )}
        </div>
      </div>
    </div>
  );
}
