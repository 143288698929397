import "./platform-switcher.scss";

import React from "react";

import { BsBookmarkFill } from "react-icons/bs";
import { HiOfficeBuilding } from "react-icons/hi";
import { RiShieldFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getDashboardPage } from "services";

export function PlatformSwitcher() {
  return (
    <div className="platform-switcher">
      <Link className="platform-switcher-partner" to={getDashboardPage()}>
        <div className="icon-container">
          <RiShieldFill className="shield" />
          <HiOfficeBuilding className="shield-inner" />
        </div>
        <span className="text-btn">Partner</span>
      </Link>

      <div className="platform-switcher-cert">
        <div className="icon-container">
          <BsBookmarkFill className="icon" />
        </div>
        <span className="text-icon">Certification</span>
      </div>
    </div>
  );
}
