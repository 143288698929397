import {
  AssessmentToolQuestionInputCheckbox,
  AssessmentToolQuestionInputRadio,
  AssessmentToolQuestionInputText,
} from "components";

export function AssessmentToolQuestionInput({
  group,
  groupId,
  id,
  type,
  title,
  options,
  onChange,
  stepProgress,
  inheritOptions,
  others,
  dependsOn,
}) {
  return (
    <>
      {
        {
          radio: (
            <AssessmentToolQuestionInputRadio
              qid={id}
              title={title}
              options={options}
              onChange={onChange}
              stepProgress={stepProgress}
              group={group}
              groupId={groupId}
            />
          ),
          checkbox: (
            <AssessmentToolQuestionInputCheckbox
              qid={id}
              title={title}
              options={options}
              onChange={onChange}
              stepProgress={stepProgress}
              others={others}
              inheritOptions={inheritOptions}
              dependsOn={dependsOn}
              group={group}
              groupId={groupId}
            />
          ),
          text: (
            <AssessmentToolQuestionInputText
              qid={id}
              title={title}
              onChange={onChange}
              stepProgress={stepProgress}
              group={group}
              groupId={groupId}
            />
          ),
        }[type]
      }
    </>
  );
}
