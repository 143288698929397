import { CertificationService } from "services/certification";
import "./index.scss";

export function StepCounter({ progress }) {
  // if (progress?.current !== undefined && progress?.current !== null) {
  if (progress?.step > 0) {
    return (
      <div className="step">
        <strong>Current step</strong>
        <span className="step-counter">
          <div className="number">{progress?.step}</div>of
          <div className="number">
            {CertificationService.getActualTotalSteps(progress)}
          </div>
          steps
        </span>
      </div>
    );
  } else {
    return (
      <div className="step">
        <strong>Current step</strong>
        <span>Not started</span>
      </div>
    );
  }
}
