import { updateUser } from "api";
import {} from "assets";
import { useEffect, useState } from "react";
import {
  filterAlphaKeyPress,
  filterAlphaPaste,
  filterPhoneNoKeyPress,
  filterPhoneNoPaste,
  validatePhoneNumber,
} from "services";

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  role: "",
  email: "",
};

const defaultErrorTxt = "Update failed. Please try again.";

export function Profile({ user,certificationInfo }) {
  const [state, setState] = useState(initialState);
  const [updateStatus, setUpdateStatus] = useState("NA");
  const [errorTxt, setErrorTxt] = useState(defaultErrorTxt);

  useEffect(() => {
    setState({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.phone || "",
      role: user?.role || "",
      email: user?.email || "",
    });
  }, [user]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    // validate phone number
    if (!!state?.phone && !validatePhoneNumber(state.phone)) {
      setUpdateStatus("error");
      setErrorTxt("Invalid phone number.");
      return;
    }

    setErrorTxt(defaultErrorTxt);

    updateUser({
      id: user.id,
      ...state,
    }).then((result) => {
      if (!!result) {
        setUpdateStatus("success");
      } else {
        setUpdateStatus("error");
      }
    });
  };

  return (
    <>
      <h4 className="table-heading main-heading">
        <svg
          width="26"
          height="21"
          viewBox="0 0 26 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.10036 10.214C11.9727 10.214 14.3006 7.92785 14.3006 5.10702C14.3006 2.28619 11.9727 0 9.10036 0C6.22806 0 3.90015 2.28619 3.90015 5.10702C3.90015 7.92785 6.22806 10.214 9.10036 10.214ZM12.7405 11.4908H12.062C11.1601 11.8978 10.1566 12.1292 9.10036 12.1292C8.04406 12.1292 7.04465 11.8978 6.13868 11.4908H5.46021C2.44572 11.4908 0 13.8927 0 16.8532V18.513C0 19.5703 0.873472 20.4281 1.95008 20.4281H13.1183C13.0208 20.1568 12.9802 19.8695 13.0127 19.5782L13.289 17.1484L13.3377 16.7055L13.6587 16.3903L16.7991 13.3062C15.8037 12.201 14.3615 11.4908 12.7405 11.4908ZM14.5809 17.2881L14.3046 19.7219C14.2599 20.1288 14.6093 20.472 15.0197 20.4241L17.4938 20.1528L23.0962 14.6508L20.1833 11.79L14.5809 17.2881ZM25.7166 10.7287L24.1769 9.21658C23.7991 8.84552 23.1815 8.84552 22.8037 9.21658L21.268 10.7247L21.1015 10.8883L24.0184 13.7491L25.7166 12.0813C26.0945 11.7063 26.0945 11.1038 25.7166 10.7287Z"
            fill="black"
          />
        </svg>
        Profile
      </h4>
      <form className="tab-form">
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>First Name</strong>
              </label>
              <input
                type="text"
                name="firstName"
                defaultValue={state.firstName}
                onChange={handleChange}
                onBlur={handleChange}
                onKeyDown={filterAlphaKeyPress}
                onPaste={filterAlphaPaste}
              />
            </div>
          </div>
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Email Address</strong>
              </label>
              <input type="email" defaultValue={state.email} disabled />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Last Name</strong>
              </label>
              <input
                type="text"
                name="lastName"
                defaultValue={state.lastName}
                onChange={handleChange}
                onBlur={handleChange}
                onKeyDown={filterAlphaKeyPress}
                onPaste={filterAlphaPaste}
              />
            </div>
          </div>
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Phone</strong>
              </label>
              <input
                type="text"
                name="phone"
                defaultValue={state.phone}
                onChange={handleChange}
                onBlur={handleChange}
                onKeyPress={filterPhoneNoKeyPress}
                onPaste={filterPhoneNoPaste}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form">
            <div className="form-item">
              <label>
                <strong>Organisation Role</strong>
              </label>
              <span>Employee Role</span>
              <input
                type="text"
                name="role"
                defaultValue={state.role}
                onChange={handleChange}
                onBlur={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-form-btn">
            <button
              className="btn btn-warning-gd"
              type="submit"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </div>

        <div className="col-form-btn">
          <div className={`form-validation ${updateStatus}`}>
            <div className="validation-txt">
              <p className="v-text success">Updated successfully.</p>
              <p className="v-text error">{errorTxt}</p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
