import { useEffect, useState } from "react";

import { Spinner } from "components";
import { useAppSessionState, useLoadingState } from "middleware";
import { useLocation } from "react-router-dom";
import { AuthService } from "services";
import { ROUTES } from "variables";

export function RequireAuth({ children, navigate = () => {} }) {
  const { appSession, setSession } = useAppSessionState();
  const { setLoadingMsg } = useLoadingState();
  const [check, setCheck] = useState(false);
  const location = useLocation();

  const checkAccountState = () => {
    AuthService.checkLoginState().then((sess) => {
      // console.log(sess);
      if (!sess) {
        if (!!localStorage.getItem("ACCESS_HISTORY")) {
          console.log(location.search);
          navigate(`${ROUTES.LOGIN}${location.search || ""}`);
        } else {
          console.log(localStorage.getItem("AUTH_ERROR"));
          navigate(`${ROUTES.REGISTER}${location.search || ""}`);
        }
      } else {
        AuthService.checkUserRegisteredDetails({
          setSession: () => {
            setSession(sess);
            setCheck(true);
          },
          userNotFoundFn: () => {
            localStorage.setItem("AUTH_ERROR", "SSO_NOT_FOUND");
            localStorage.setItem("AUTH_ERROR_STATE", 1);
          },
          doubleChecked: false,
        });
      }
    });
  };

  useEffect(() => {
    checkAccountState();
  }, []);

  return (
    <>
      {check ? (
        <>{!!appSession.session && children}</>
      ) : (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}
    </>
  );
}
