const YES_NO_QUESTION = (groupId, id, title, ext = {}) => ({
  ...ext,
  groupId,
  id,
  type: "radio",
  title,
  options: ["Yes", "No"],
});

const SINGLE_SELECT_QUESTION = (groupId, id, title, options, ext = {}) => ({
  ...ext,
  groupId,
  id,
  type: "radio",
  title,
  options,
});

const SELECT_QUESTION = (groupId, id, title, options, ext = {}) => ({
  ...ext,
  groupId,
  id,
  type: "checkbox",
  title,
  options,
});

const TEXT_QUESTION = (groupId, id, title, ext = {}) => ({
  ...ext,
  groupId,
  id,
  type: "text",
  title,
});

export const QUESTIONAIRE = {
  All: [
    [
      YES_NO_QUESTION(
        "0",
        "1.0",
        "Does your business have an internal IT department?",
      ),
      YES_NO_QUESTION(
        "1",
        "1.1",
        "Does your business have an external IT provider that can help you manage your business’ day-to-day IT related cyber security requirements?",
        {
          dependsOn: "1.0",
          condition: "No",
        },
      ),
      // YES_NO_QUESTION(
      //   "2",
      //   "1.2",
      //   "Do you have a Service Level Agreement with your IT Provider that includes a minimum response time of 8 working hours for their key incident response employees to be available to assist you and your organisation respond to a cyber related incident?",
      //   {
      //     dependsOn: "1.0",
      //     condition: "No",
      //   }
      // ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "2.0",
        "Does your business network/s always connect to the Internet through a firewall?",
      ),
      YES_NO_QUESTION(
        "1",
        "2.1",
        "Have you had your firewall configuration checked by a specialist other than the person that originally set it up, to confirm it has been configured correctly?",
        {
          dependsOn: "2.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "3.0",
        "Do you have anti-virus software installed on all of the devices within your business? (e.g. workstations, laptops, servers)",
      ),
      YES_NO_QUESTION(
        "1",
        "3.1",
        "Is your anti-virus software set to update automatically?",
        {
          dependsOn: "3.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "4.0",
        "Are all of the devices in your business, and the software installed on them, set to update automatically?",
      ),
      YES_NO_QUESTION(
        "1",
        "4.1",
        "Do you have an IT provider or department that manages the updating of devices and the software installed on them?",
        {
          dependsOn: "4.0",
          condition: "No",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "5.0",
        "Are the passwords for new devices reset when first installed or used?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "6.0",
        "Do you change the password on your firewall at least every twelve (12) months?",
        {
          dependsOn: "2.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "7.0",
        "Do you change your Wi-Fi password/s at least every twelve (12) months?",
      ),
    ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "8.0",
    //     "Do you change the password on Wi-Fi access points at least every twelve (12) months?"
    //   ),
    // ],
    [
      YES_NO_QUESTION(
        "0",
        "8.0",
        "Do you back-up the digital assets and systems that are of greatest importance to your business operations?",
      ),
      // YES_NO_QUESTION(
      //   "1",
      //   "9.1",
      //   "Do you backup your digital assets based on a digital asset register?",
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),
      // Y"ES_NO_QUESTION(
      //   "2",
      //   "9.2",
      //   "Do you have a register of where your backups are stored?",
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),
      // YES_NO_QUESTION(
      //   "3",
      //   "9.3",
      //   "Do you have a list of the people who have access to your backups?",
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),
      // SINGLE_SELECT_QUESTION(
      //   "4",
      //   "9.4",
      //   "How frequently do you backup these digital assets?",
      //   [
      //     "Daily",
      //     "Weekly",
      //     "Monthly",
      //     "Bimonthly",
      //     "Every 6 months",
      //     "Anually",
      //   ],
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),
      // YES_NO_QUESTION(
      //   "5",
      //   "9.5",
      //   "Do you test that your backups can be restored successfully and in a timely manner?",
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),
      // SINGLE_SELECT_QUESTION(
      //   "6",
      //   "9.6",
      //   "How long do you keep your backups?",
      //   [
      //     "Daily",
      //     "Weekly",
      //     "Monthly",
      //     "6 Monthly",
      //     "Yearly",
      //     "More than a year",
      //   ],
      //   {
      //     dependsOn: "9.0",
      //     condition: "Yes",
      //   }
      // ),"
    ],
    [
      YES_NO_QUESTION(
        "0",
        "9.0",
        "Does your business have any public websites?",
      ),
      YES_NO_QUESTION(
        "1",
        "9.1",
        "Do you have SSL certificates installed for each public website?",
        {
          dependsOn: "9.0",
          condition: "Yes",
        },
      ),
      // YES_NO_QUESTION(
      //   "2",
      //   "10.2",
      //   "Do you, or your service provider, regularly scan your webserver for malware or other threats and vulnerabilities?",
      //   {
      //     dependsOn: "10.0",
      //     condition: "Yes",
      //   }
      // ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "10.0",
        "Do you, or your external service providers, update your server infrastructure (including web servers if applicable), and the software installed on the infrastructure, regularly?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "11.0",
        "Are employees able to install software or apps onto their own workstations or laptops? (e.g. do they have local Administrator privileges)?",
      ),
    ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "12.0",
    //     "Are employees able to make changes to the network or software settings on their workstations or laptops, e.g. disabling automatic updates?"
    //   ),
    // ],
    [
      YES_NO_QUESTION(
        "0",
        "12.0",
        "Does every employee within your business have their own username and password?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "13.0",
        "Do you have a password manager implemented?",
      ),
      YES_NO_QUESTION(
        "1",
        "13.1",
        "Is the password manager available to employees with administrator accounts?",
        {
          dependsOn: "13.0",
          condition: "Yes",
        },
      ),
      YES_NO_QUESTION(
        "2",
        "13.2",
        "Is the password manager available to all employees?",
        {
          dependsOn: "13.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "14.0",
        "Do you have Multi-Factor Authentication (MFA) enabled on all email accounts used within your business?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "15.0",
        "Do you have a confidentiality agreement that all of your employees are required to read and sign when they commence work?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "16.0",
        "Do you have a written policy that outlines the steps your employees are required to take in order to change a customer’s bank details so as to avoid invoice fraud?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "17.0",
        "Are visitors and contractors required to “sign in” when they enter your office or “employees only” areas?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "18.0",
        "Do you use cloud-based business applications (SaaS) within your business? (e.g. Office365, Slack, Salesforce, Miro)",
      ),
      YES_NO_QUESTION(
        "1",
        "18.1",
        "Do you have MFA enabled on all user accounts in these applications?",
        {
          dependsOn: "18.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "19.0",
        "Do you have social media accounts that you use for marketing and/or communication purposes?",
      ),
      YES_NO_QUESTION(
        "1",
        "19.1",
        "Do you have MFA enabled on these accounts?",
        {
          dependsOn: "19.0",
          condition: "Yes",
        },
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "20.0",
        // "Do you have a cyber security policy that all of your employees are required to read and sign annually?"
        "Do you have a cyber security policy that all of your employees are required to read?",
      ),
      // SELECT_QUESTION(
      //   "1",
      //   "22.1",
      //   "Select what your cyber security policy contains",
      //   [
      //     "the responsibilities of employees",
      //     "the responsibilities of contractors",
      //     "the cyber security requirements for the organisation",
      //     "the technical controls that are required to secure and protect the organisation",
      //     "the cyber security procedures that employees and contractors should follow",
      //   ],
      //   {
      //     dependsOn: "22.0",
      //     condition: "Yes",
      //   }
      // ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "21.0",
        "Do you have a documented incident response plan for dealing with a cyber incident that can be shared with your employees?",
      ),
      YES_NO_QUESTION(
        "1",
        "21.1",
        "Do you have a list of contact details for the people and businesses that you will need to engage in the event of a cyber incident?",
        {
          dependsOn: "21.0",
          condition: "Yes",
        },
      ),
      // YES_NO_QUESTION(
      //   "2",
      //   "21.2",
      //   "Does your incident response plan include templates, scripts or playbooks that can assist management to quickly and efficiently communicate with customers and potentially the media?",
      //   {
      //     dependsOn: "23.0",
      //     condition: "Yes",
      //   }
      // ),
      // YES_NO_QUESTION(
      //   "3",
      //   "21.3",
      //   "Does your incident response plan include guidance on how to address a data exposure event, and the subsequent reporting requirements to the OAIC under Australia’s Notifiable Data Breach scheme?",
      //   {
      //     dependsOn: "23.0",
      //     condition: "Yes",
      //   }
      // ),
      // SINGLE_SELECT_QUESTION(
      //   "4",
      //   "21.4",
      //   "How frequently do you review and update your incident response plan?",
      //   [
      //     "Monthly",
      //     "Bimonthly",
      //     "Every 6 months",
      //     "Anually",
      //     "After an incident occurs",
      //     "Never",
      //   ],
      //   {
      //     dependsOn: "23.0",
      //     condition: "Yes",
      //     required: true,
      //   }
      // ),
      // SINGLE_SELECT_QUESTION(
      //   "5",
      //   "21.5",
      //   "How frequently do you run training exercises to verify the performance of your incident response plan?",
      //   [
      //     "Monthly",
      //     "Bimonthly",
      //     "Every 6 months",
      //     "Anually",
      //     "After the response plan changes/incident occurs",
      //     "More infrequently",
      //     "Never",
      //   ],
      //   {
      //     dependsOn: "23.0",
      //     condition: "Yes",
      //     required: true,
      //   }
      // ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "22.0",
        "Does your business securely destroy sensitive or confidential documents (e.g. through use of a shredder).",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "23.0",
        "Does your business securely dispose of or destroy devices, including external hard drives that are no longer used in the business?",
      ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "24.0",
        "Do you have a digital asset register that records where all of your important, confidential, and sensitive data (digital assets) is stored? (e.g. servers, cloud storage, etc)",
      ),
      // YES_NO_QUESTION(
      //   "1",
      //   "24.1",
      //   "Does your digital register identify which data contains personally identifiable data? (e.g. HR records)",
      //   {
      //     dependsOn: "26.0",
      //     condition: "Yes",
      //   }
      // ),
      // YES_NO_QUESTION(
      //   "2",
      //   "24.2",
      //   "Does your digital asset register include a list of who has access to each of the systems?",
      //   {
      //     dependsOn: "26.0",
      //     condition: "Yes",
      //   }
      // ),
      // SINGLE_SELECT_QUESTION(
      //   "3",
      //   "24.3",
      //   "How frequently do you review your digital assets and update any changes in your digital asset register?",
      //   [
      //     "Monthly",
      //     "Bimonthly",
      //     "Every 6 months",
      //     "Anually",
      //     "Major changes in digital assets",
      //     "More infrequently",
      //     "Never",
      //   ],
      //   {
      //     required: true,
      //     dependsOn: "26.0",
      //     condition: "Yes",
      //   }
      // ),
    ],
    [
      YES_NO_QUESTION(
        "0",
        "25.0",
        "Have all employees, including owners and management, received cyber security awareness training?",
      ),
      // SINGLE_SELECT_QUESTION(
      //   "1",
      //   "27.1",
      //   "How frequently do they receive this training?",
      //   ["Once", "Once per year", "Twice per year", "More frequently"],
      //   {
      //     dependsOn: "27.0",
      //     condition: "Yes",
      //   }
      // ),
      // SELECT_QUESTION(
      //   "1",
      //   "27.2",
      //   "Select all of the following topics that your employees have had specific training on",
      //   [
      //     "Social engineering",
      //     "Phishing",
      //     "Vishing",
      //     "Email safety and security",
      //     "Business email compromise",
      //     "Invoice fraud",
      //     "Physical security",
      //     "Use of any tools or software required for cyber security",
      //     "Incident response",
      //   ],
      //   {
      //     dependsOn: "27.0",
      //     condition: "Yes",
      //   }
      // ),
      // YES_NO_QUESTION(
      //   "2",
      //   "27.3",
      //   "Are the cyber security procedures and policies for employees reviewed at least, annually?",
      //   {
      //     dependsOn: "27.0",
      //     condition: "Yes",
      //   }
      // ),
    ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "29.0",
    //     "Do you use cloud computing services in your business? (e.g. AWS, Azure, Google Cloud)"
    //   ),
    //   YES_NO_QUESTION(
    //     "1",
    //     "29.1",
    //     "Do you create separate Identity and Access Management roles with minimised privileges to secure access to your remote cloud computing resources?",
    //     {
    //       dependsOn: "29.0",
    //       condition: "Yes",
    //     }
    //   ),
    //   SELECT_QUESTION(
    //     "2",
    //     "29.2",
    //     "Where do you store the SSH keys that you use to access your remote cloud computing resources?",
    //     [
    //       "User device such as computer",
    //       "Key vault",
    //       "Offline environment",
    //       "USB or removable hard drive",
    //       "We do not use SSH keys",
    //     ],
    //     {
    //       dependsOn: "29.0",
    //       condition: "Yes",
    //       others: true,
    //     }
    //   ),
    //   YES_NO_QUESTION(
    //     "3",
    //     "29.3",
    //     // "Do you have MFA enabled on remote cloud computing services?",
    //     "Do you have MFA enabled on all remote cloud computing accounts?",
    //     {
    //       dependsOn: "29.0",
    //       condition: "Yes",
    //     }
    //   ),
    // ],
    // [
    //   SELECT_QUESTION(
    //     "0",
    //     "30.0",
    //     "Where do you store your business’ digital assets? (e.g. critical, important, confidential, sensitive, and personal data)? Select all that apply.",
    //     [
    //       "User device",
    //       "Internal network with in-house servers",
    //       "Cloud-based internal network",
    //       "Off-site data storage",
    //     ],
    //     {
    //       others: true,
    //     }
    //   ),
    //   SELECT_QUESTION(
    //     "1",
    //     "30.1",
    //     // "Which of these systems are encrypting the digital asset data while it is stored (at rest)? Select all that apply. (only populate with their systems selected)",
    //     "Which of these systems are encrypting the digital asset data while it is stored (at rest)? Select all that apply.",
    //     [],
    //     {
    //       dependsOn: "30.0",
    //       inheritOptions: true,
    //     }
    //   ),
    //   SELECT_QUESTION(
    //     "2",
    //     "30.2",
    //     "Which of these systems or devices have MFA enabled?",
    //     [],
    //     {
    //       dependsOn: "30.0",
    //       inheritOptions: true,
    //     }
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "31.0",
    //     "Do you use VPN and/or RDP connections within your business network? "
    //   ),
    //   YES_NO_QUESTION(
    //     "1",
    //     "31.1",
    //     "Do you have MFA enabled on all of these connections?",
    //     {
    //       dependsOn: "31.0",
    //       condition: "Yes",
    //     }
    //   ),
    // ],
    // [YES_NO_QUESTION("0", "32.0", "Do you have cyber insurance?")],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "33.0",
    //     "Do you have agreements in place with your suppliers about maintaining minimum cyber security standards?"
    //   ),
    //   SELECT_QUESTION(
    //     "1",
    //     "33.2",
    //     "For suppliers without ISO/IEC 27001 certification, does the agreement include the following information (select all that apply):",
    //     [
    //       "requirement to notify you in the event the supplier is involved in a cyber related incident",
    //       "cyber hygiene requirements",
    //     ],
    //     {
    //       dependsOn: "33.0",
    //       condition: "Yes",
    //     }
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "34.0",
    //     "Does your business use application whitelisting on all of your workstations and laptops?"
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "35.0",
    //     "Does your business disable unnecessary Microsoft Office macros on all workstations, laptops and servers?"
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "36.0",
    //     "Do you have cleaning contractors or other contractors that have access to your offices or workplace outside of work hours?"
    //   ),
    //   YES_NO_QUESTION(
    //     "1",
    //     "36.1",
    //     "Have you conducted police checks on all of these contractors?",
    //     {
    //       dependsOn: "36.0",
    //       condition: "Yes",
    //     }
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "37.0",
    //     "Have you engaged an external specialist to conduct vulnerability tests on the security of your IT infrastructure?"
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "38.0",
    //     "Have you engaged an external specialist to conduct penetration tests on the security of your IT infrastructure?"
    //   ),
    // ],
    // [
    //   YES_NO_QUESTION(
    //     "0",
    //     "39.0",
    //     "Have you engaged an external specialist to conduct social engineering tests on your employees?"
    //   ),
    // ],
  ],
};

export const DECISION_TREE = {
  1: [
    {
      reqId: "1.1.0.0",
      condition: [
        {
          id: "1.0",
          value: "Yes",
          or: [
            {
              id: "1.1",
              value: "Yes",
            },
          ],
        },
      ],
    },
    {
      reqId: "1.2.0.0",
      condition: [
        {
          id: "2.0",
          value: "Yes",
        },
        {
          id: "2.1",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "1.3.0.0",
      condition: [
        {
          id: "3.0",
          value: "Yes",
        },
        {
          id: "3.1",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "1.4.0.0",
      condition: [
        {
          id: "4.0",
          value: "Yes",
          or: [
            {
              id: "4.1",
              value: "Yes",
            },
          ],
        },
      ],
    },
    {
      reqId: "2.1.0.0",
      condition: [
        {
          id: "5.0",
          value: "Yes",
        },
        {
          id: "6.0",
          value: "Yes",
        },
        {
          id: "7.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "3.1.0.0",
      condition: [
        {
          id: "8.0",
          value: "Yes",
        },
      ],
    },
  ],
  2: [
    {
      reqId: "1.5.0.0",
      condition: [
        {
          id: "9.1",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "2.2.0.0",
      condition: [
        {
          id: "11.0",
          value: "No",
        },
      ],
    },
    {
      reqId: "2.3.0.0",
      condition: [
        {
          id: "12.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "2.4.0.0",
      condition: [
        {
          id: "13.1",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "2.5.0.0",
      condition: [
        {
          id: "14.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.1.0.0",
      condition: [
        {
          id: "15.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.2.0.0",
      condition: [
        {
          id: "16.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.3.0.0",
      condition: [
        {
          id: "17.0",
          value: "Yes",
        },
      ],
    },
  ],
  3: [
    {
      reqId: "1.6.0.0",
      condition: [
        {
          id: "9.0",
          value: "Yes",
        },
        {
          id: "10.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "2.4.1.0",
      condition: [
        {
          id: "13.2",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "2.6.0.0",
      condition: [
        {
          id: "18.1",
          value: "Yes",
          or: [
            {
              id: "19.1",
              value: "Yes",
            },
          ],
        },
      ],
    },
    {
      reqId: "4.4.0.0",
      condition: [
        {
          id: "20.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.5.0.0",
      condition: [
        {
          id: "21.0",
          value: "Yes",
        },
        {
          id: "21.1",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.6.0.0",
      condition: [
        {
          id: "22.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.7.0.0",
      condition: [
        {
          id: "23.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "4.8.0.0",
      condition: [
        {
          id: "24.0",
          value: "Yes",
        },
      ],
    },
    {
      reqId: "5.1.0.0",
      condition: [
        {
          id: "25.0",
          value: "Yes",
        },
      ],
    },
  ],
  // 4: [
  //   {
  //     reqId: "1.1.1.0",
  //     condition: [
  //       {
  //         id: "1.2",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "3.1.1.0",
  //     condition: [
  //       {
  //         id: "8.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "9.1",
  //         value: "Yes",
  //       },
  //       {
  //         id: "9.2",
  //         value: "Yes",
  //       },
  //       {
  //         id: "9.3",
  //         value: "Yes",
  //       },
  //       {
  //         id: "9.4",
  //         mayValues: ["Daily", "Weekly", "Monthly", "Every 6 months"],
  //       },
  //       {
  //         id: "9.5",
  //         value: "Yes",
  //       },
  //       {
  //         id: "9.6",
  //         mayValues: ["Daily", "Weekly", "Monthly", "Every 6 months"],
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "1.7.0.0",
  //     condition: [
  //       {
  //         id: "10.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "10.2",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.5.1.0",
  //     condition: [
  //       {
  //         id: "14.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "5.1.1.0",
  //     condition: [
  //       {
  //         id: "27.2",
  //         minSelected: 4,
  //       },
  //       {
  //         id: "27.3",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.7.0.0",
  //     condition: [
  //       {
  //         id: "29.1",
  //         value: "Yes",
  //       },
  //       {
  //         id: "29.3",
  //         value: "Yes",
  //       },
  //       {
  //         id: "29.2",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.8.0.0",
  //     condition: [
  //       {
  //         id: "30.0",
  //       },
  //       {
  //         id: "30.2",
  //         selectedAll: true,
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.9.0.0",
  //     condition: [
  //       {
  //         id: "31.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.10.0.0",
  //     condition: [
  //       {
  //         id: "31.2",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.9.1.0",
  //     condition: [
  //       {
  //         id: "31.1",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "3.2.0.0",
  //     condition: [
  //       {
  //         id: "32.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  // ],
  // 5: [
  //   {
  //     reqId: "4.5.1.0",
  //     condition: [
  //       {
  //         id: "21.2",
  //         value: "Yes",
  //       },
  //       {
  //         id: "21.3",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "5.2.0.0",
  //     condition: [
  //       {
  //         id: "21.4",
  //         mayValues: ["Monthly", "Bimonthly", "Every 6 months", "Anually"],
  //       },
  //       {
  //         id: "21.5",
  //         mayValues: ["Monthly", "Bimonthly", "Every 6 months", "Anually"],
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "4.8.1.0",
  //     condition: [
  //       {
  //         id: "24.1",
  //         value: "Yes",
  //       },
  //       {
  //         id: "24.2",
  //         value: "Yes",
  //       },
  //       {
  //         id: "24.3",
  //         notValues: ["Never"],
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "1.8.0.0",
  //     condition: [
  //       {
  //         id: "30.1",
  //         selectedAll: true,
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.8.1.0",
  //     condition: [
  //       {
  //         id: "14.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "30.2",
  //         selectedAll: true,
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "2.6.1.0",
  //     condition: [
  //       {
  //         id: "14.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "18.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "18.1",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "4.9.0.0",
  //     condition: [
  //       {
  //         id: "33.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "1.9.0.0",
  //     condition: [
  //       {
  //         id: "34.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "1.10.0.0",
  //     condition: [
  //       {
  //         id: "35.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "4.10.0.0",
  //     condition: [
  //       {
  //         id: "36.1",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  //   {
  //     reqId: "1.11.0.0",
  //     condition: [
  //       {
  //         id: "37.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "38.0",
  //         value: "Yes",
  //       },
  //       {
  //         id: "39.0",
  //         value: "Yes",
  //       },
  //     ],
  //   },
  // ],
};

export const getQuestionaire = (key = "All") => {
  let result = JSON.parse(JSON.stringify(QUESTIONAIRE[key])); // quick deepcopy

  // for (let i = 0; i < result.length; ++i) {
  //   const groupId = i + 1;
  //   const questionGroup = result[i];

  //   let mainQuestion = questionGroup[0];
  //   mainQuestion.groupId = `${groupId}.${mainQuestion.groupId}`;

  //   let evaluatedQuestions = [mainQuestion];

  //   for (let j = 1; j < questionGroup.length; ++j) {
  //     let subQuestion = questionGroup[j];
  //     subQuestion.groupId = `${groupId}.${subQuestion.groupId}`;

  //     if (!!subQuestion.dependsOn) {
  //       subQuestion.dependsOn = `${groupId}.${subQuestion.dependsOn}`;
  //     }

  //     evaluatedQuestions.push(subQuestion);
  //   }

  //   result[i] = evaluatedQuestions;
  // }
  return result;
};
