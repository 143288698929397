import { Spinner } from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkAuthErrorState, getLandingPage } from "services";
import "./login.scss";

export function Login({ onSignin = () => {} }) {
  const { loading } = useLoadingState();
  const location = useLocation();

  useEffect(() => {
    checkAuthErrorState({
      onPass: () => {
        const params = queryString.parse(location.search);
        if (!params?.c) {
          window.location.href = `${getLandingPage()}/login${
            location.search || ""
          }`;
        }
      },
    });
  }, []);

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}
    </>
  );
}
