import { getUser as getAPI, listUsers } from "graphql/queries";
import { makeGetFunction } from "../../utils";

export const getUser = (id) =>
  makeGetFunction({
    name: "getUser",
    query: getAPI,
    params: {
      id,
    },
  })();

export const getUserByEmail = (email) =>
  !!email
    ? makeGetFunction({
        name: "listUsers",
        query: listUsers,
        params: {
          filter: {
            email: {
              eq: email,
            },
          },
        },
      })()
    : undefined;
