import { InfoBar } from "components";
import { Footer, Header } from "layouts";
import { useAppState } from "middleware";
import { parseDateTime, parseDateTime12Format } from "services";

export function LayoutWrapper({ children }) {
  const { app } = useAppState();

  const parseMaintenanceDate = () => {
    if (app?.current?.maintenanceScheduleDisplayTimeFormat === 24) {
      return parseDateTime(app?.current?.maintenanceSchedule);
    } else {
      return parseDateTime12Format(app?.current?.maintenanceSchedule);
    }
  };

  return (
    <>
      <Header />

      {!!app?.current?.maintenanceSchedule && (
        <>
          <InfoBar
            title="Maintenance Alert!"
            content={`A maintenance is scheduled at ${parseMaintenanceDate()}.`}
            level="warn"
          />
        </>
      )}

      {children}

      <div className="footer">
        <Footer />
      </div>
    </>
  );
}
