import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Asterisk } from "../required-asterisk";

import "./form-input.scss";

export function FormTextInput({
  controlId,
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  required,
  disabled,
  successTxt = "",
  errorTxt = "Field is required.",
  pValidation,
  pSetValidation,
  autoComplete,
  setRef = () => {},
  onKeyPress = () => {},
  onPaste = () => {},
}) {
  let [validation, setValidation] = useState("NA");
  const [refInternal, setRefInternal] = useState(null);

  useEffect(() => {
    if (!value && !!refInternal) {
      refInternal.value = "";
    }
  }, [refInternal]);

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const validateInput = (event) => {
    if (!required || !!event.target.value) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>
      <Form.Group className="mb-3 full-input" controlId={controlId}>
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <Form.Control
          className="form-input"
          type="text"
          name={name}
          autoComplete="off"
          maxLength={maxLength}
          defaultValue={value}
          placeholder={placeholder}
          onChange={validateInput}
          onBlur={validateInput}
          ref={(r) => {
            setRef(r);
            setRefInternal(r);
          }}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
        />
      </Form.Group>
    </div>
  );
}
