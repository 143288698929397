import {
  getRequirement,
  updateCertificationProgress,
  updateRequirement,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import { checkIcon, selectArrow } from "assets";
import { FormInput } from "components";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Select, { components as ReactSelectComponents } from "react-select";
import {
  filterAlphaKeyPress,
  filterAlphaPaste,
  sortRequirements,
  useClickOutsideHook,
} from "services";
import { CertificationService } from "services/certification";
import { StepIcon, StepIconArrow } from "../icons";
import "./step-2.scss";

const initialOption = {
  id: "",
  requirementId: "",
  attestationStatus: undefined,
  attestedBy: "",
  notApplicableDetails: "",
};
export function Step2({
  certificationInfo,
  assessStep,
  setCurrentStep,
  done,
  level,
  progressData = {},
  getCurrentStep = () => {},
}) {
  // req title character limit
  const titleLimit = 78;
  const [attestedByRef, setAttestedByRef] = useState();
  const [attestationOptions, setAttestationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [completedRequirements, setCompletedRequirements] = useState(0);
  const [updatedStatus, setUpdatedStatus] = useState("NA");
  const [updatedStatusTxt, setUpdatedStatusTxt] = useState(
    "Requirement is updated successfully."
  );
  const [showRequirementDetail, setShowRequirementDetail] = useState(false);
  const [showConfirmAssess, setShowConfirmAssess] = useState(false);
  const [requirementStatus, setRequirementStatus] = useState(null);
  const [showAttestation, setShowAttestation] = useState(false);

  useEffect(() => {
    if (!!progressData) {
      checkRequirements();
      const interval = setInterval(() => checkRequirements(), 5000);
      return () => {
        clearInterval(interval);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressData]);

  const handleClose = () => setShowConfirmAssess(false);
  const handleShow = () => setShowConfirmAssess(true);

  const handleCloseAttestation = () => setShowAttestation(false);
  const handleShowAttestation = () => setShowAttestation(true);

  const confirmToAssess = (event) => {
    event.preventDefault();

    handleShow();
  };

  const displayAttestation = (event) => {
    event.preventDefault();

    handleShowAttestation();
  };

  const assess = () => {
    handleClose();
    assessStep(2, progressData);
  };

  const updateOption = async (o, event, savedStatus = null) => {
    if (updatable()) {
      let result = await updateRequirement({
        id: o.id,
        [event.target.name]: o[event.target.name],
      });

      if (!result) {
        result = await getRequirement(o.id);
      }

      if (!!result) {
        setUpdatedStatus("success");
        if (event.target.name === "attestationStatus") {
          if (result.attestationStatus === "NULL") {
            // setUpdatedStatusTxt(`Requirement has been unmarked.`);
            setUpdatedStatusTxt("");
          } else {
            setUpdatedStatusTxt(
              `Requirement has been marked "${
                event.target.value === "NA" ? "Not Applicable" : "Completed"
              }".`
            );
          }
        } else {
          setUpdatedStatusTxt("Requirement is updated successfully.");
        }
      } else {
        setUpdatedStatus("error");
        setRequirementStatus(savedStatus);
      }

      await updateCertificationProgress({
        id: progressData?.id,
        step: 2,
        updatedAt: new Date().toISOString(),
      });
    }

    assessStep(1);

    await checkRequirements();
  };

  async function handleInput(event) {
    if (!selectedOption.id) {
      return;
    }

    let selectedOptionResult = selectedOption;

    if (
      event.target.name === "attestationStatus" &&
      selectedOption.attestationStatus === event.target.value
    ) {
      selectedOptionResult.attestationStatus = "NULL";
    } else {
      selectedOptionResult[event.target.name] = event.target.value;
    }

    const savedStatus = requirementStatus;

    setRequirementStatus(selectedOptionResult.attestationStatus);

    setSelectedOption(selectedOptionResult);

    await updateOption(selectedOptionResult, event, savedStatus);
  }

  function handleSelect(event) {
    nextOption(
      attestationOptions.filter((option) => option.id === event.value)[0]
    );
  }

  const isRequirementDone = (id) => {
    const requirement = attestationOptions.filter(
      (option) => option.id === id
    )[0];

    return CertificationService.isRequirementDone(requirement);
  };

  const getRequirementState = (id) => {
    if (isRequirementDone(id)) {
      return `requirement-option done`;
    } else return "requirement-option";
  };

  const handleNextStep = (event) => {
    event.preventDefault();

    const currentIdx = attestationOptions.findIndex(
      (item) => item.id === selectedOption.id
    );

    let nextIdx = (currentIdx + 1) % attestationOptions.length;

    if (completedRequirements?.length < attestationOptions.length) {
      while (currentIdx !== nextIdx) {
        const testNextReq = attestationOptions[nextIdx];
        if (
          completedRequirements.filter((req) => req.id === testNextReq.id)
            .length === 0
        ) {
          break;
        }
        nextIdx = (nextIdx + 1) % attestationOptions.length;
      }
    }

    if (nextIdx === currentIdx) {
      nextIdx = (currentIdx + 1) % attestationOptions.length;
    }

    const nextReq = attestationOptions[nextIdx];

    if (
      !done &&
      !!attestedByRef?.value &&
      attestedByRef.value !== nextReq.attestedBy
    ) {
      updateOption(
        { ...nextReq, attestedBy: attestedByRef.value },
        {
          target: {
            name: "attestedBy",
            value: attestedByRef.value,
          },
        }
      );
      setSelectedOption({
        ...nextReq,
        attestedBy: attestedByRef.value,
      });
      setRequirementStatus(nextReq.attestationStatus);
    } else {
      nextOption(nextReq);
    }
  };

  const nextOption = (o) => {
    console.log(o);
    setSelectedOption(o);
    setUpdatedStatus("NA");
    setRequirementStatus(o.attestationStatus);
    if (!!attestedByRef) {
      attestedByRef.value = o.attestedBy;
    }
  };

  const allRequirementsCompleted = () => {
    return completedRequirements.length === attestationOptions.length;
  };

  const checkRequirements = async () => {
    const certInfo = await getCertificationInfo();
    const progressRequirements =
      certInfo?.customer?.certification?.progress?.requirements;
    const items = sortRequirements(progressRequirements?.items) || [];
    setCompletedRequirements(
      items.filter((item) => CertificationService.isRequirementDone(item))
    );
    setAttestationOptions(items);
  };

  const updatable = () => {
    return (
      !!progressData &&
      progressData?.status !== "COMPLETED" &&
      progressData?.step === 2
    );
  };

  return (
    <>
      <Card
        className={`cert-step-1 acc-item ${done ? "done" : getCurrentStep() === "2" ? "current" : ""}`}
      >
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(2)}>
            {done ? <StepIcon /> : <StepIconArrow />}

            <h3>Step 2: Complete Attestation</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>

                      <div className="step-text">
                        <p>
                          Now that you have implemented the certification
                          requirements in your organisation it’s time to
                          complete your attestation. The attestation should be
                          completed by the person responsible for the
                          organisation’s cyber security, the business owner, or
                          a director.
                        </p>

                        <p>
                          Complete this step by marking off all certification
                          requirements that have been implemented in your
                          organisation. If a certification requirement is not
                          applicable to your organisation, select ‘Not
                          applicable’ as your option.
                        </p>

                        <p>
                          When you have completed your attestation click ‘Next
                          Step’ to move to{" "}
                          <strong>STEP 3 – Create Attestation Letter.</strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div className="card-lvl card-lvl-attestation">
                      <h4 className="card-title">
                        Complete Attestation
                        <button
                          onClick={displayAttestation}
                          className="attestationModal"
                        >
                          <svg
                            className="question"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </h4>

                      <strong className="card-subtitle">
                        Attested requirements: {completedRequirements.length}/
                        {attestationOptions.length}
                      </strong>

                      <Form className="form-elements sect-multiple">
                        <div className="form-row select-row">
                          <Select
                            value={
                              !!selectedOption?.id
                                ? {
                                    value: selectedOption?.id,
                                    label: `${selectedOption.requirementId} ${selectedOption.detail?.title}`,
                                  }
                                : undefined
                            }
                            placeholder="Select a requirement"
                            options={attestationOptions.map((option) => {
                              return {
                                value: option.id,
                                label: `${option.requirementId} ${option.detail?.title}`,
                              };
                            })}
                            onChange={handleSelect}
                            components={{
                              IndicatorSeparator: () => null,
                              Option: (props) => (
                                <div
                                  className={getRequirementState(props.value)}
                                >
                                  <ReactSelectComponents.Option {...props} />

                                  {isRequirementDone(props.value) && (
                                    <img src={checkIcon} alt="check-icon" />
                                  )}
                                </div>
                              ),
                              DropdownIndicator: (props) => (
                                <ReactSelectComponents.DropdownIndicator
                                  {...props}
                                >
                                  <img
                                    src={selectArrow}
                                    alt="dropdown-indicator"
                                  />
                                </ReactSelectComponents.DropdownIndicator>
                              ),
                            }}
                            styles={styles.requirementSelect}
                            isSearchable={false}
                          />

                          {!!selectedOption?.id && (
                            <>
                              <span
                                className="link from-popup-link"
                                onClick={() => setShowRequirementDetail(true)}
                              >
                                View requirement detail
                              </span>

                              <RequirementDetail
                                show={showRequirementDetail}
                                handleClose={() =>
                                  setShowRequirementDetail(false)
                                }
                                requirement={selectedOption}
                              />
                            </>
                          )}
                        </div>

                        {!!selectedOption?.id && (
                          <>
                            <div className="form-row form-two-col">
                              <div className="form-row-item">
                                <input
                                  id="completed"
                                  type="radio"
                                  onClick={() =>
                                    handleInput({
                                      target: {
                                        name: "attestationStatus",
                                        value: "COMPLETED",
                                      },
                                    })
                                  }
                                  checked={requirementStatus === "COMPLETED"}
                                  onChange={(event) => {
                                    if (event.target.checked === true) {
                                      setRequirementStatus("COMPLETED");
                                    }
                                  }}
                                  disabled={done}
                                />
                                <label htmlFor="completed">
                                  <span className="step2-label">Completed</span>
                                </label>
                              </div>

                              {!!selectedOption?.detail?.notApplicable && (
                                <div className="form-row-item">
                                  <input
                                    id="nocompleted"
                                    type="radio"
                                    onClick={() =>
                                      handleInput({
                                        target: {
                                          name: "attestationStatus",
                                          value: "NA",
                                        },
                                      })
                                    }
                                    checked={requirementStatus === "NA"}
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setRequirementStatus("NA");
                                      }
                                    }}
                                    disabled={done}
                                  />
                                  <label htmlFor="nocompleted">
                                    <span className="step2-label">
                                      Not applicable
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>

                            <div className={`form-validation ${updatedStatus}`}>
                              <p className="v-text success">
                                {updatedStatusTxt}
                              </p>
                              <p className="v-text error">
                                Requirement failed to update.
                              </p>

                              <div className="form-row">
                                <FormInput
                                  controlId="attested"
                                  label="Attested by"
                                  type="text"
                                  name="attestedBy"
                                  maxLength="240"
                                  onChange={handleInput}
                                  value={selectedOption.attestedBy}
                                  setRef={setAttestedByRef}
                                  required
                                  disabled={done}
                                  onKeyPress={filterAlphaKeyPress}
                                  onPaste={filterAlphaPaste}
                                />
                              </div>

                              {selectedOption.attestationStatus === "NA" ? (
                                <div className="form-row">
                                  <FormInput
                                    controlId="notApplicableDetails"
                                    name="notApplicableDetails"
                                    label="Not applicable details"
                                    type="textarea"
                                    cols="30"
                                    rows="10"
                                    onChange={handleInput}
                                    value={selectedOption?.notApplicableDetails}
                                    disabled={done}
                                    required
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )}

                        {allRequirementsCompleted() && !done ? (
                          <div className="form-row submit-row">
                            <button
                              onClick={confirmToAssess}
                              className="btn btn-warning"
                              type="submit"
                              disabled={done}
                            >
                              Next Step
                            </button>
                          </div>
                        ) : (
                          <div className="form-row submit-row">
                            <button
                              onClick={handleNextStep}
                              className="btn btn-warning-gd"
                              type="submit"
                            >
                              Next Requirement
                            </button>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Modal show={showConfirmAssess} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="step-2-confirm-title">
            Before you continue...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="step2-modal">
          <p>
            Once you have completed Step 2 by clicking the "Next Step" button,
            you will no longer be able to do any edit on the requirements.
          </p>
          <p>
            Please ensure your attestation is final and correct before
            proceeding.
          </p>

          {/* <table className="confirm-requirements-table">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>Attested By</td>
                <td>Status</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              {[
                "Technology Management",
                "Access Management",
                "Backup & Recovery",
                "Policies, Plans & Procedures",
                "Education & Training",
              ].map((cat, idx) => {
                const filteredOptions = attestationOptions.filter(
                  (option) =>
                    parseInt(option.detail?.baseId.charAt(0)) === idx + 1,
                );
                if (filteredOptions.length > 0) {
                  return (
                    <>
                      <tr className="category-row">
                        <td colSpan="2" className="category-name">
                          <span className="category">{cat}</span>
                        </td>
                        {cat == "Technology Management" ? (
                          <>
                            <td>Attested By</td>
                            <td>Status</td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>

                      {filteredOptions.map((option) => (
                        <tr
                          className="requirement-row"
                          onClick={() => {
                            handleClose();
                            handleSelect({ value: option.id });
                          }}
                        >
                          <td className="requirement-data">
                            {option.requirementId}
                          </td>
                          <td className="requirement-data">
                            <span className="title">
                              {option.detail?.title?.length > titleLimit
                                ? option.detail?.title.substring(
                                    0,
                                    titleLimit - 3,
                                  ) + "..."
                                : option.detail?.title}
                            </span>
                          </td>
                          <td className="requirement-data">
                            <span className="capitalize attestedBy">
                              {option.attestedBy}
                            </span>
                          </td>
                          <td className="requirement-data">
                            <span className="centered">
                              {option.attestationStatus === "COMPLETED" ? (
                                <>
                                  <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_i_3628_5119)">
                                      <ellipse
                                        cx="8.80408"
                                        cy="8.5"
                                        rx="8.50001"
                                        ry="8.5"
                                        fill="#CDE2D1"
                                      />
                                    </g>
                                    <path
                                      d="M7.91856 11.5331L5.28009 8.78121C5.12158 8.61588 5.12158 8.34782 5.28009 8.18247L5.85414 7.58373C6.01265 7.41838 6.26968 7.41838 6.42819 7.58373L8.20559 9.43754L12.0126 5.46686C12.1711 5.30153 12.4281 5.30153 12.5866 5.46686L13.1607 6.0656C13.3192 6.23094 13.3192 6.499 13.1607 6.66435L8.49262 11.5332C8.33409 11.6985 8.07708 11.6985 7.91856 11.5331V11.5331Z"
                                      fill="#28A745"
                                    />
                                    <defs>
                                      <filter
                                        id="filter0_i_3628_5119"
                                        x="0.304077"
                                        y="0"
                                        width="17"
                                        height="17"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                      >
                                        <feFlood
                                          floodOpacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in="SourceGraphic"
                                          in2="BackgroundImageFix"
                                          result="shape"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite
                                          in2="hardAlpha"
                                          operator="arithmetic"
                                          k2="-1"
                                          k3="1"
                                        />
                                        <feColorMatrix
                                          type="matrix"
                                          values="0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.25 0"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in2="shape"
                                          result="effect1_innerShadow_3628_5119"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="8.02777"
                                      cy="8.97223"
                                      r="6.13889"
                                      fill="#2078EC"
                                    />
                                    <path
                                      d="M8.5 0C3.80591 0 0 3.80728 0 8.5C0 13.1955 3.80591 17 8.5 17C13.1941 17 17 13.1955 17 8.5C17 3.80728 13.1941 0 8.5 0ZM8.5 3.77016C9.29502 3.77016 9.93952 4.41465 9.93952 5.20968C9.93952 6.0047 9.29502 6.64919 8.5 6.64919C7.70498 6.64919 7.06048 6.0047 7.06048 5.20968C7.06048 4.41465 7.70498 3.77016 8.5 3.77016ZM10.4194 12.4758C10.4194 12.7029 10.2352 12.8871 10.0081 12.8871H6.99194C6.7648 12.8871 6.58065 12.7029 6.58065 12.4758V11.6532C6.58065 11.4261 6.7648 11.2419 6.99194 11.2419H7.40323V9.04839H6.99194C6.7648 9.04839 6.58065 8.86423 6.58065 8.6371V7.81452C6.58065 7.58738 6.7648 7.40323 6.99194 7.40323H9.18548C9.41262 7.40323 9.59677 7.58738 9.59677 7.81452V11.2419H10.0081C10.2352 11.2419 10.4194 11.4261 10.4194 11.6532V12.4758Z"
                                      fill="#E3EFFF"
                                    />
                                  </svg>
                                </>
                              )}
                            </span>
                          </td>
                          {option.attestationStatus !== "COMPLETED" &&
                            !!option.notApplicableDetails && (
                              <>
                                <td className="requirement-data">
                                  {option.notApplicableDetails?.length > 60
                                    ? `${option.notApplicableDetails?.substring(
                                        0,
                                        60
                                      )}...`
                                    : option.notApplicableDetails}
                                  {option.notApplicableDetails}
                                </td>
                              </>
                            )}
                        </tr>
                      ))}
                    </>
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </table> */}

          <div>
            {[
              "Technology Management",
              "Access Management",
              "Backup & Recovery",
              "Policies, Plans & Procedures",
              "Education & Training",
            ].map((cat, idx) => {
              const filteredOptions = attestationOptions.filter(
                (option) =>
                  parseInt(option.detail?.baseId.charAt(0)) === idx + 1
              );
              if (filteredOptions.length > 0) {
                return (
                  <>
                    <div className="category-row">
                      <div colSpan="2" className="category-name">
                        <span className="category">{cat}</span>
                      </div>
                    </div>

                    {filteredOptions.map((option) => (
                      <div
                        className="requirement-row"
                        onClick={() => {
                          handleClose();
                          handleSelect({ value: option.id });
                        }}
                      >
                        <div className="status-data">
                          {option.attestationStatus === "COMPLETED" ? (
                            <>
                              <svg
                                width="18"
                                height="17"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_i_3628_5119)">
                                  <ellipse
                                    cx="8.80408"
                                    cy="8.5"
                                    rx="8.50001"
                                    ry="8.5"
                                    fill="#D1FFDC"
                                  />
                                </g>
                                <path
                                  d="M7.91856 11.5331L5.28009 8.78121C5.12158 8.61588 5.12158 8.34782 5.28009 8.18247L5.85414 7.58373C6.01265 7.41838 6.26968 7.41838 6.42819 7.58373L8.20559 9.43754L12.0126 5.46686C12.1711 5.30153 12.4281 5.30153 12.5866 5.46686L13.1607 6.0656C13.3192 6.23094 13.3192 6.499 13.1607 6.66435L8.49262 11.5332C8.33409 11.6985 8.07708 11.6985 7.91856 11.5331V11.5331Z"
                                  fill="#28A745"
                                />
                                <defs>
                                  <filter
                                    id="filter0_i_3628_5119"
                                    x="0.304077"
                                    y="0"
                                    width="17"
                                    height="17"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                  >
                                    <feFlood
                                      floodOpacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="arithmetic"
                                      k2="-1"
                                      k3="1"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="shape"
                                      result="effect1_innerShadow_3628_5119"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                              <span className="completed">Completed</span>
                            </>
                          ) : (
                            <>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="8.02777"
                                  cy="8.97223"
                                  r="6.13889"
                                  fill="#2078EC"
                                />
                                <path
                                  d="M8.5 0C3.80591 0 0 3.80728 0 8.5C0 13.1955 3.80591 17 8.5 17C13.1941 17 17 13.1955 17 8.5C17 3.80728 13.1941 0 8.5 0ZM8.5 3.77016C9.29502 3.77016 9.93952 4.41465 9.93952 5.20968C9.93952 6.0047 9.29502 6.64919 8.5 6.64919C7.70498 6.64919 7.06048 6.0047 7.06048 5.20968C7.06048 4.41465 7.70498 3.77016 8.5 3.77016ZM10.4194 12.4758C10.4194 12.7029 10.2352 12.8871 10.0081 12.8871H6.99194C6.7648 12.8871 6.58065 12.7029 6.58065 12.4758V11.6532C6.58065 11.4261 6.7648 11.2419 6.99194 11.2419H7.40323V9.04839H6.99194C6.7648 9.04839 6.58065 8.86423 6.58065 8.6371V7.81452C6.58065 7.58738 6.7648 7.40323 6.99194 7.40323H9.18548C9.41262 7.40323 9.59677 7.58738 9.59677 7.81452V11.2419H10.0081C10.2352 11.2419 10.4194 11.4261 10.4194 11.6532V12.4758Z"
                                  fill="#E3EFFF"
                                />
                              </svg>
                              <span className="na">Not Applicable</span>
                            </>
                          )}
                        </div>
                        <div className="requirements-flex">
                          <div className="requirement-data">
                            {option.requirementId}
                          </div>
                          <div className="requirement-data">
                            <span className="title">
                              {option.detail?.title?.length > titleLimit
                                ? option.detail?.title.substring(
                                    0,
                                    titleLimit - 3
                                  ) + "..."
                                : option.detail?.title}
                            </span>
                          </div>
                        </div>
                        <div className="attested-data">
                          Attested by:{" "}
                          <span className="attestedBy capitalize">
                            {option.attestedBy}
                          </span>
                        </div>
                        {/* {option.attestationStatus !== "COMPLETED" &&
                          !!option.notApplicableDetails && (
                            <>
                              <td className="requirement-data">
                                {option.notApplicableDetails?.length > 60
                                  ? `${option.notApplicableDetails?.substring(
                                      0,
                                      60,
                                    )}...`
                                  : option.notApplicableDetails}
                                {option.notApplicableDetails}
                              </td>
                            </>
                          )} */}
                      </div>
                    ))}
                  </>
                );
              } else {
                return <></>;
              }
            })}
          </div>

          <p className="mt-20">Confirm to continue.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="step-2-confirm-btn cancel"
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="step-2-confirm-btn confirm"
            variant="primary"
            onClick={assess}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="attestation-modal"
        show={showAttestation}
        onHide={handleCloseAttestation}
      >
        <Modal.Header closeButton>
          <Modal.Title className="step-2-confirm-title">
            What is an attestation?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="step2-modal">
          <p>It’s when someone confirms or affirms something to be true.</p>
          <p>
            In this situation the Attester (the person making the attestation)
            is confirming that the certification requirement has been completed,
            or was not applicable to their business. They are confirming it is
            true.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="step-2-confirm-btn"
            variant="secondary"
            onClick={handleCloseAttestation}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const styles = {
  requirementSelect: {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #cfdeff",
      padding: "12px",
      position: "relative",
      background: "#f9fbff",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#000",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #cfdeff",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      whiteSpace: "pre-wrap",
    }),
    valueContainer: (provided, state) => ({
      display: "flex",
      flex: 1,
      padding: "2px 8px",
      position: "relative",
      overflow: "hidden",
      boxSizing: "border-box",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      alignItems: "start",
    }),
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "0",
        height: "0",
      },
      "::-webkit-scrollbar-track": {
        background: "none",
      },
      "::-webkit-scrollbar-thumb": {
        background: "none",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      padding: "13px 13px 13px 13px",
      minHeight: "34px",
      transition: ".3s background",
      "&:hover": {
        background: "inherit",
      },
      color: "#6C757D",
      backgroundColor: "inherit",
    }),
  },
};

function RequirementDetail({ show, handleClose, requirement }) {
  const ref = useRef(null);
  useClickOutsideHook({
    ref,
    clickedout: (ref, target) =>
      ref.current.className.includes("popup-show") &&
      !ref.current.children[0].contains(target),
    onClickedout: handleClose,
  });

  const categories = [
    "Technology Management",
    "Access Management",
    "Backup & Recovery",
    "Policies, Plans, & Procedures",
    "Education & Training",
  ];
  const [category, setCategory] = useState({ areaOfFocus: 0, name: "NA" });

  useEffect(() => {
    try {
      const areaOfFocus = parseInt(requirement?.requirementId[0]);
      setCategory({
        areaOfFocus,
        name: categories[areaOfFocus - 1],
      });
    } catch (e) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirement]);

  const joinRequirementDescriptions = (descriptions) => {
    return descriptions?.map((l, i) => (
      <>
        {i === 0 ? (
          <p>{l}</p>
        ) : (
          <>
            <br className="req-line-break" />
            <p>{l}</p>
          </>
        )}
      </>
    ));
  };

  return (
    <div
      className={
        show
          ? "share-popup form-share-popup popup-show"
          : "share-popup form-share-popup"
      }
      ref={ref}
    >
      <div className="inner">
        <div className="close" onClick={handleClose}>
          Close{" "}
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.22222 11L0 9.77778L4.27778 5.5L0 1.22222L1.22222 0L5.5 4.27778L9.77778 0L11 1.22222L6.72222 5.5L11 9.77778L9.77778 11L5.5 6.72222L1.22222 11Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="block-ms success">
          <strong>
            {" "}
            <span className="label">{category.areaOfFocus}.0</span>
            {category.name}
          </strong>
        </div>
        <h4 className="popup-title">
          <span>{requirement?.requirementId}</span>
          {requirement?.detail?.title}
        </h4>
        <div className="text">
          {joinRequirementDescriptions(
            requirement?.detail?.description?.split("\n\n")
          )}
        </div>
        {requirement?.detail?.notApplicable && (
          <div className="block-ms">
            <strong>
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.53333 0C3.82083 0 0 3.58332 0 8C0 12.4193 3.82083 16 8.53333 16C13.2458 16 17.0667 12.4193 17.0667 8C17.0667 3.58332 13.2458 0 8.53333 0ZM8.53333 3.54839C9.33148 3.54839 9.97849 4.15497 9.97849 4.90323C9.97849 5.65148 9.33148 6.25806 8.53333 6.25806C7.73519 6.25806 7.08817 5.65148 7.08817 4.90323C7.08817 4.15497 7.73519 3.54839 8.53333 3.54839ZM10.4602 11.7419C10.4602 11.9557 10.2753 12.129 10.0473 12.129H7.01935C6.79133 12.129 6.60645 11.9557 6.60645 11.7419V10.9677C6.60645 10.754 6.79133 10.5806 7.01935 10.5806H7.43226V8.51613H7.01935C6.79133 8.51613 6.60645 8.34281 6.60645 8.12903V7.35484C6.60645 7.14106 6.79133 6.96774 7.01935 6.96774H9.22151C9.44953 6.96774 9.63441 7.14106 9.63441 7.35484V10.5806H10.0473C10.2753 10.5806 10.4602 10.754 10.4602 10.9677V11.7419Z"
                  fill="#007BFF"
                />
              </svg>
              Not Applicable
            </strong>
            <p>{requirement?.detail?.notApplicable}</p>
          </div>
        )}
      </div>
    </div>
  );
}
