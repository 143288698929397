import {
  BETA_CERTIFICATION_HOSTNAME,
  CERTIFICATION_HOSTNAME,
  DASHBOARD_HOSTNAME,
  DEMO_CERTIFICATION_HOSTNAME,
  DEMO_LANDING_HOSTNAME,
  DEV_CERTIFICATION_HOSTNAME,
  DEV_DASHBOARD_HOSTNAME,
  DEV_LANDING_HOSTNAME,
  DEV_PARTNERS_MSP_HOSTNAME,
  LANDING_HOSTNAME,
  PARTNERS_MSP_HOSTNAME,
  WWW_LANDING_HOSTNAME,
} from "./hostnames";

export const isLocalHost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.match(
        /^172(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.match(
        /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const isDevHost = () =>
  Boolean(
    window.location.hostname === DEV_CERTIFICATION_HOSTNAME ||
      window.location.hostname === DEV_LANDING_HOSTNAME ||
      window.location.hostname === DEV_DASHBOARD_HOSTNAME ||
      window.location.hostname === DEV_PARTNERS_MSP_HOSTNAME
  );

export const isBetaHost = () =>
  Boolean(window.location.hostname === BETA_CERTIFICATION_HOSTNAME);

export const isDemoHost = () =>
  Boolean(
    window.location.hostname === DEMO_CERTIFICATION_HOSTNAME ||
      window.location.hostname === DEMO_LANDING_HOSTNAME
  );

export const isProdHost = () =>
  Boolean(
    window.location.hostname === CERTIFICATION_HOSTNAME ||
      window.location.hostname === LANDING_HOSTNAME ||
      window.location.hostname === WWW_LANDING_HOSTNAME ||
      window.location.hostname === PARTNERS_MSP_HOSTNAME ||
      window.location.hostname === DASHBOARD_HOSTNAME
  );

export const isDevPartnersMspHost = () =>
  Boolean(window.location.hostname === DEV_PARTNERS_MSP_HOSTNAME);

export const isProdPartnersMspHost = () =>
  Boolean(window.location.hostname === PARTNERS_MSP_HOSTNAME);
