export function StepIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1580_80)">
        <ellipse
          cx="16.8041"
          cy="16.8041"
          rx="16.8041"
          ry="16.8041"
          fill="#E2E2E2"
        />
      </g>
      <path
        d="M15.0537 22.8004L9.83757 17.36C9.5242 17.0331 9.5242 16.5032 9.83757 16.1763L10.9724 14.9926C11.2858 14.6657 11.7939 14.6657 12.1073 14.9926L15.6211 18.6575L23.1473 10.8077C23.4607 10.4808 23.9688 10.4808 24.2822 10.8077L25.4171 11.9914C25.7304 12.3182 25.7304 12.8482 25.4171 13.175L16.1886 22.8004C15.8752 23.1273 15.3671 23.1273 15.0537 22.8004Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_1580_80"
          x="0"
          y="0"
          width="33.6084"
          height="33.6081"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0 0.670833 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1580_80"
          />
        </filter>
      </defs>
    </svg>
  );
}
