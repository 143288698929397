import { API } from "aws-amplify";
import { AuthService } from "services";

export const getIssuedBadge = async ({ level }) => {
  const user = await AuthService.getCurrentAuthUserInfo();

  const myInit = {
    queryStringParameters: {
      user_email: user.email,
      level,
    },
  };

  return API.get("getBadge", "/", myInit);
};
