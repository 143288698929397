import { getSpreadsheetDownloadUrl, getWorkbookDownloadUrl } from "api";
import { LoadingLink } from "components";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { StepIcon, StepIconArrow } from "../icons";

export function Step1({ assessStep, setCurrentStep, done, level, getCurrentStep = () => { } }) {
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("/");
  const [workbookUrl, setWorkbookUrl] = useState("/");

  useEffect(() => {
    getSpreadsheetDownloadUrl().then((result) => setSpreadsheetUrl(result));
    getWorkbookDownloadUrl().then((result) => setWorkbookUrl(result));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getSpreadsheetDownloadUrl().then((result) => setSpreadsheetUrl(result));
      getWorkbookDownloadUrl().then((result) => setWorkbookUrl(result));
      console.log(1);
    }, 3600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Card className={`cert-step-1 acc-item ${done ? "done" : (getCurrentStep() === "1" ? "current" : "")}`}>
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(1)}>
            {done ? <StepIcon /> : <StepIconArrow />}

            {/* <h3>Step 1: Download Workbook and Implement Requirements</h3> */}
            <h3>Step 1: Download Requirements</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>

                      <div className="step-text">
                        <p>
                          Start your certification by downloading your
                          Certification Workbook. This document contains all of
                          the certification requirements that you need to have
                          implemented in your organisation to qualify for a {["Bronze", "Silver", "Gold"][level - 1]} certification.
                        </p>

                        <p>
                          When you have implemented all of the certification
                          requirements for your organisation, return to your
                          Certificate Manager (this page) and complete{" "}
                          <strong>STEP 2 – Complete Attestation.</strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div className="card-lvl">
                      <h4 className="card-title">Download Requirements</h4>

                      <ul className="download-list">
                        <li>
                          <LoadingLink
                            href={workbookUrl}
                            target="_blank"
                            rel="noreferrer"
                            download
                          >
                            <svg
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
                                fill="black"
                              />
                            </svg>
                            Certification Workbook (.pdf)
                          </LoadingLink>
                        </li>

                        <li>
                          <LoadingLink
                            href={spreadsheetUrl}
                            target="_blank"
                            rel="noreferrer"
                            download
                          >
                            <svg
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
                                fill="black"
                              />
                            </svg>
                            Certification Spreadsheet (.xlsx)
                          </LoadingLink>
                        </li>
                      </ul>

                      <button
                        onClick={() => assessStep(1)}
                        className="btn btn-warning"
                        disabled={done}
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
