import { useState } from "react";

import {
  AccountCertificationSettings,
  AccountSettings,
  OrganisationSettings,
  Profile,
} from "components";

export function DashboardTabDesktop({
  userData,
  progress,
  displayProgress,
  progresses,
  setDisplayProgress,
  currentProgressScroll,
  certificationInfo,
}) {
  const [tab, setTab] = useState("certifications");

  const getClassForTab = (thisTab) => {
    return thisTab === tab ? "active" : "";
  };

  const getClassForTabItem = (thisTab) => {
    return thisTab === tab ? "acc-tabs-item active" : "acc-tabs-item inactive";
  };

  return (
    <>
      <ul className="tabset-nav">
        <li onClick={() => setTab("certifications")}>
          <a
            title="Certifications"
            className={getClassForTab("certifications")}
          >
            Certifications
          </a>
        </li>

        <li onClick={() => setTab("organisation")}>
          <a title="Organisation" className={getClassForTab("organisation")}>
            Organisation
          </a>
        </li>

        <li onClick={() => setTab("profile")}>
          <a title="Profile" className={getClassForTab("profile")}>
            Profile
          </a>
        </li>

        <li onClick={() => setTab("settings")}>
          <a title="Settings" className={getClassForTab("settings")}>
            Settings
          </a>
        </li>
      </ul>

      <div className="acc-tabs-content accordion-acc desktop">
        <div
          className={getClassForTabItem("certifications")}
          id="certifications"
        >
          <div className="slide card-compl">
            <AccountCertificationSettings
              progress={progress}
              displayProgress={displayProgress}
              progresses={progresses}
              setDisplayProgress={setDisplayProgress}
              doScroll={currentProgressScroll}
            />
          </div>
        </div>

        <div className={getClassForTabItem("organisation")} id="organisation">
          <div className="slide card-compl">
            <OrganisationSettings
              user={userData}
              certificationInfo={certificationInfo}
            />
          </div>
        </div>

        <div className={getClassForTabItem("profile")} id="profile">
          <div className="slide card-compl">
            <Profile user={userData} certificationInfo={certificationInfo} />
          </div>
        </div>

        <div className={getClassForTabItem("settings")} id="settings">
          <div className="slide card-compl">
            <AccountSettings
              userData={userData}
              certificationInfo={certificationInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
}
