import "./certificate-link.scss";

import { useEffect, useRef, useState } from "react";

import { onUpdateProgress } from "api";

import { LoadingLink } from "../loading-link";

const DEFAULT_TIMER = "--:--"; // minutes:seconds
const CERT_PROCESS_TIME = 60; // seconds

export function CertificateLink({
  progress,
  href = "/",
  download = true,
  target = "_blank",
  rel = "nonreferrer",
  children,
}) {
  const [timer, setTimer] = useState(DEFAULT_TIMER);
  const timerRef = useRef(null);

  useEffect(() => {
    checkCertificateLinkAvailable(progress);

    return onUpdateProgress({
      id: progress?.id,
      callbackFn: (prog) => {
        if (prog.status === "COMPLETED") {
          checkCertificateLinkAvailable(prog);
        }
      },
    });
  }, []);

  const checkCertificateLinkAvailable = (prog) => {
    if (!!prog?.completedDate) {
      const timePassed =
        (new Date().getTime() - new Date(prog.completedDate).getTime()) / 1000; // seconds
      if (timePassed < CERT_PROCESS_TIME) {
        setProcessTimer(prog.completedDate);
      } else {
        setTimer("00:00");
      }
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      seconds,
      minutes,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        `${minutes > 9 ? minutes : "0" + minutes}:${
          seconds > 9 ? seconds : "0" + seconds
        }`,
      );
    }
  };

  const setProcessTimer = (e) => {
    setTimer(DEFAULT_TIMER);

    // set timer
    let timeout = new Date(e);

    timeout.setSeconds(timeout.getSeconds() + CERT_PROCESS_TIME);

    if (!!timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(timeout);
    }, 1000);
    timerRef.current = id;
  };

  const disabledChildren = (
    <a className="disabled">
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
          fill="rgba(109, 118, 126, 0.7)"
        />
      </svg>

      <>Generating Certificate...</>
    </a>
  );

  return (
    <>
      {timer === "00:00" ? (
        <LoadingLink
          href={href}
          download={download}
          target={target}
          rel={rel}
          disabledChidren={disabledChildren}
        >
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
              fill="white"
            />
          </svg>
          Level {progress?.level} Certificate (.pdf)
        </LoadingLink>
      ) : (
        <a className="disabled">
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
              fill="rgba(109, 118, 126, 0.7)"
            />
          </svg>
          {timer !== DEFAULT_TIMER ? (
            <>Certificate will be ready in {timer}</>
          ) : (
            <>Generating Certificate...</>
          )}
        </a>
      )}
    </>
  );
}
