import "./assessment-tool.scss";

import { useEffect, useState } from "react";

import { getAssessmentQuestionnaire, updateAssessmentQuestionaire } from "api";
import {
  AssessmentDone,
  AssessmentQuestionnaire,
  NavBreadcrumbList,
  produceAssessmentResult,
  Spinner,
} from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { clearUrlParamsState } from "services";
import { ROUTES } from "variables";

const STEPS = ["START", "PROGRESS", "DONE"];

const DONE = "done";
const ACTIVE = "active";
const initialSteps = {
  // "Technology Management": {
  //   state: ACTIVE,
  //   answers: [],
  // },
  // "Access Management": {
  //   state: ACTIVE,
  //   answers: [],
  // },
  // "Backup & Recovery": {
  //   state: ACTIVE,
  //   answers: [],
  // },
  // "Policies, Plans & Procedures": {
  //   state: ACTIVE,
  //   answers: [],
  // },
  // "Education & Training": {
  //   state: ACTIVE,
  //   answers: [],
  // },

  All: {
    state: ACTIVE,
    answers: [],
  },
};

export function AssessmentTool() {
  const [step, setStep] = useState();
  const [result, setResult] = useState();
  const [eligibleReq, setEligibleRequirements] = useState();
  const [assessment, setAssessment] = useState();
  const [stepProgress, setStepProgress] = useState(initialSteps);
  const steps = Object.keys(initialSteps);
  const { loading, setLoading } = useLoadingState();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const a = await getAssessmentQuestionnaire();
      setAssessment(a);
      if (!!location.search) {
        const params = queryString.parse(location.search);
        if (params["view_result"] === "true") {
          view({
            viewAssessment: a,
          });
        } else if (params["start"] === "true") {
          await start();
        } else {
          await goToQuestions(a);
        }
        clearUrlParamsState();
      } else {
        await goToQuestions(a);
      }
      setLoading(false);
    })();
  }, []);

  const goToQuestions = async (a) => {
    setStep(STEPS[1]);
  };

  const generateResult = async (questions, answers) => {
    setLoading(true);
    const { res, eligibleRequirements } = produceAssessmentResult(answers);

    // console.log(`===> RES = ${res}`);

    // auto-save results
    await save(res, eligibleRequirements, true);

    setResult(res);
    setEligibleRequirements(eligibleRequirements);
    // setStep(STEPS[2]);
    // setLoading(false);
    window.location.href = ROUTES.ASSESSMENT_RESULTS;
  };

  const start = async () => {
    await updateAssessmentQuestionaire({
      progress: [],
    });
    setStep(STEPS[1]);
  };

  const cont = () => {
    setStep(STEPS[1]);
  };

  const view = ({ viewAssessment = assessment }) => {
    setResult(viewAssessment?.result);
    setEligibleRequirements(viewAssessment?.eligibleRequirements);
    // setStep(STEPS[2]);
    window.location.href = ROUTES.ASSESSMENT_RESULTS;
  };

  const save = async (res = result, eligibleRequirements, submit = false) => {
    await updateAssessmentQuestionaire({
      result: res,
      progress:
        submit === true ? [] : stepProgress.answers.filter((a) => !!a.value),
      eligibleRequirements,
    });
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <div className="dashboard-header">
        <div className="container-md">
          <div className="df-row">
            <div className="col-wl">
              <div className="card-wl assessment">
                <NavBreadcrumbList
                  items={[
                    { to: ROUTES.ROOT, name: "Dashboard" },
                    {
                      to: ROUTES.ASSESSMENT_TOOL,
                      name: "Recommendation Tool",
                      forceReload: true,
                      active: step !== "DONE",
                    },
                  ].concat(
                    step === "DONE"
                      ? [
                          {
                            to: `${ROUTES.ASSESSMENT_TOOL}?view_result=true`,
                            name: "Results",
                            forceReload: true,
                            active: true,
                          },
                        ]
                      : [],
                  )}
                />

                {
                  {
                    PROGRESS: (
                      <AssessmentQuestionnaire
                        steps={steps}
                        stepProgress={stepProgress}
                        setStepProgress={setStepProgress}
                        done={generateResult}
                      />
                    ),
                    DONE: (
                      <AssessmentDone
                        result={result}
                        eligibleRequirements={eligibleReq}
                      />
                    ),
                  }[step]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
