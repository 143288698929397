import { isExpired } from "services";
import { ROUTES } from "variables";

export function StatusBadge({ progress, trigger }) {
  return (
    <>
      {progress?.status === "COMPLETED" && isExpired(progress?.expiredDate) ? (
        <ul className={`status-label-list red`}>
          <li>
            <a href={ROUTES.CERTIFICATION_MANAGER}>EXPIRED</a>
          </li>
        </ul>
      ) : (
        <ul
          className={`status-label-list ${
            progress?.status === "IN_PROGRESS" ? "orange" : "success"
          }`}
        >
          <li>
            {!!trigger ? (
              <a onClick={trigger}>{progress?.status}</a>
            ) : (
              <a href={ROUTES.CERTIFICATION_MANAGER}>{progress?.status}</a>
            )}
          </li>
        </ul>
      )}
    </>
  );
}
