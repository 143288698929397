import { getPublicCertificateURL } from "api";
import { spinner } from "assets";
import { Spinner } from "components";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function Certificate() {
  const [certificate, setCertificate] = useState("/");

  const { search } = useLocation();
  const params = queryString.parse(search);
  const { loading, setLoading } = useLoadingState();

  useEffect(() => {
    if (!!params.certificate) {
      getPublicCertificateURL({ certificate: params.certificate }).then(
        (result) => {
          setCertificate(result);
          setLoading(false);
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      {certificate !== "/" && (
        <embed
          width="100%"
          height="600px"
          src={`${certificate}#toolbar=0&navpanes=0&scrollbar=0`}
        />
      )}
    </div>
  );
}
