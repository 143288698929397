import { useEffect, useState } from "react";

import { onUpdateProgress } from "api";
import { LoadingLink } from "components";

export function CredlyLink({
  progress,
  href = "/",
  download = true,
  target = "_blank",
  rel = "nonreferrer",
}) {
  const [linkReady, setLinkReady] = useState(false);

  useEffect(() => {
    checkCertificateLinkAvailable(progress);

    return onUpdateProgress({
      id: progress?.id,
      callbackFn: (prog) => {
        if (prog.status === "COMPLETED") {
          checkCertificateLinkAvailable(prog);
        }
      },
    });
  }, []);

  const checkCertificateLinkAvailable = (prog) => {
    if (!!prog?.completedDate) {
      setLinkReady(true);
    }
  };

  const disabledChildren = (
    <a className="disabled">
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
          fill="rgba(109, 118, 126, 0.7)"
        />
      </svg>
      <>Generating Certified Badge...</>
    </a>
  );

  return (
    <>
      {linkReady === true ? (
        <LoadingLink
          href={href}
          download={download}
          target={target}
          rel={rel}
          disabledChidren={disabledChildren}
        >
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.179688 13H11.8203V14.6797H0.179688V13ZM11.8203 5.5L6 11.3203L0.179688 5.5H3.5V0.5H8.5V5.5H11.8203Z"
              fill="white"
            />
          </svg>
          Credly Certified Badge
        </LoadingLink>
      ) : (
        <>{disabledChildren}</>
      )}
    </>
  );
}
