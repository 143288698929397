import { useEffect, useRef, useState } from "react";

import { updateAssessmentQuestionaire } from "api";
import { OTHERS_TAG, QuestionaireStep } from "components";
import { useLoadingState } from "middleware";
import { Button, Modal } from "react-bootstrap";
import { ROUTES } from "variables";

import {
  ALLOW_DEV_TESTING,
  SAMPLE_TEST_PROGRESS,
  TEST_ASSESSMENT_SUBMIT,
} from "./assessment-dev/sample";
import { getQuestionaire } from "./questionaire";

const IN_PROGRESS = "in-progress";

export function AssessmentQuestionnaire({
  stepProgress,
  setStepProgress,
  steps,
  done = () => {},
}) {
  const [quizCounter, setQuizCounter] = useState();
  const [pages, setPages] = useState();
  const { setLoading } = useLoadingState();
  const [showFailedSubmit, setShowFailedSubmit] = useState();
  const [unanswer, setUnanswer] = useState();
  const stepsRef = useRef();
  const flatQuestions = getQuestionaire().flatMap((q) => q);

  const handleCloseFailedSubmit = () => {
    setShowFailedSubmit(false);
  };

  const handleOpenFailedSubmit = () => {
    const nonanswers = getUnansweredQuestions();
    setUnanswer(
      pages
        .flatMap((page) => page)
        .flatMap((q) => q)
        .filter((q) => nonanswers.filter((a) => a.id === q.id).length > 0),
    );
    setShowFailedSubmit(true);
  };

  useEffect(() => {
    setStepProgress({
      ...stepProgress,
      All: {
        ...stepProgress["All"],
        state: IN_PROGRESS,
      },
    });
  }, []);
  const nextPage = () => {
    if (allQuestionsAnswered()) {
      stepsRef.current?.gotoSubmitPage();
    } else if (quizCounter.page < quizCounter.total) {
      stepsRef.current.gotoPage(quizCounter.page + 1);
    }
  };

  const getProgressBar = () => {
    if (!quizCounter) {
      return 0;
    }
    return (quizCounter.page / pages.length) * 100;
  };

  const shouldShow = (question) => {
    const getBased = (q) => {
      return stepProgress?.answers?.filter(
        (item) => item.id === q.dependsOn,
      )[0];
    };
    const getBasedQuestion = (q) => {
      return flatQuestions?.filter((item) => item.id === q.dependsOn)[0];
    };

    // console.log(`Question: ${JSON.stringify(question)}`);

    if (!question.dependsOn) {
      return true;
    }

    const based = getBased(question);
    const basedQuestion = getBasedQuestion(question);

    // console.log(`Based: ${JSON.stringify(basedQuestion)}`);

    if (!based) {
      return false;
    }

    if (!!based.value) {
      return (
        shouldShow(basedQuestion) &&
        (!question.condition || question.condition === based.value)
      );
    }

    return false;
  };

  const allQuestionsAnswered = () => {
    return (stepProgress.answers || []).every((q) => {
      const qinfo = flatQuestions?.filter((item) => item.id === q.id)[0];

      if (qinfo.type === "checkbox" && qinfo.others === true) {
        const othersInput = q.value
          ?.split(/[\r\n]+/)
          ?.filter((a) => a.includes(OTHERS_TAG))[0];

        if (!!othersInput && othersInput.replace(OTHERS_TAG, "").length === 0) {
          return false;
        }
      }

      if (qinfo.type === "checkbox" && !qinfo.required) {
        return true;
      }

      return !shouldShow(qinfo) || !!q?.value;
    });
  };

  const checkAllQuestionsAnswered = () => {
    if (allQuestionsAnswered()) {
      stepsRef.current?.gotoSubmitPage();
    }
  };

  const getUnansweredQuestions = () => {
    return (stepProgress.answers || []).filter((q) => {
      const qinfo = flatQuestions?.filter((item) => item.id === q.id)[0];

      if (qinfo.type === "checkbox" && qinfo.others === true) {
        const othersInput = q.value
          ?.split(/[\r\n]+/)
          ?.filter((a) => a.includes(OTHERS_TAG))[0];

        if (!!othersInput && othersInput.replace(OTHERS_TAG, "").length === 0) {
          return true;
        }
      }

      if (qinfo.type === "checkbox" && !qinfo.required) {
        return false;
      }

      return !(!shouldShow(qinfo) || !!q?.value);
    });
  };

  const submitAnswer = () => {
    const shouldShowAnswers = stepProgress.answers.filter((a) =>
      shouldShow(flatQuestions?.filter((item) => item.id === a.id)[0]),
    );
    if (allQuestionsAnswered()) {
      done(flatQuestions, shouldShowAnswers);
    } else {
      handleOpenFailedSubmit();
    }
  };

  const save = async () => {
    setLoading(true);

    await updateAssessmentQuestionaire({
      progress: stepProgress.answers
        .filter((a) => !!a.value)
        .map((a) => ({
          id: a.id,
          value: a.value,
        })),
    });

    setLoading(false);

    window.location.href = ROUTES.ROOT;
  };

  return (
    <>
      <>
        <div className="panel-quiz-header">
          <div className="panel-header">
            <div className="progress-bar">
              <span
                style={{
                  width: `${getProgressBar()}%`,
                }}
              ></span>
            </div>
            <div className="panel-steps">
              <div className="panel-steps-header">
                <div className="heading w-75">
                  <h1 className="mb-0">Certification Recommendation Tool</h1>
                  {/* <p>
                  Get a recommendation on which level of cyber security
                  certification is best for your business.
                </p> */}
                </div>
                <div className="quiz-counter">
                  <span>
                    {quizCounter?.lo}-{quizCounter?.hi} of{" "}
                    {quizCounter?.displayTotal} Questions
                  </span>
                </div>
              </div>
              {/* <ul className="steps">
              {steps.map((step) => (
                <li className={stepProgress[step].state}>
                  <a href="#">
                    <span>{step}</span>
                  </a>
                </li>
              ))}
            </ul> */}
            </div>
          </div>
        </div>
        <div className="panel-quiz">
          {steps.map((step) => (
            <>
              {stepProgress[step].state === IN_PROGRESS && (
                <QuestionaireStep
                  shouldShow={shouldShow}
                  quizCounter={quizCounter}
                  setQuizCounter={setQuizCounter}
                  title={step}
                  pages={pages}
                  setPages={setPages}
                  ref={stepsRef}
                  stepProgress={stepProgress}
                  setStepProgress={setStepProgress}
                  checkAllQuestionsAnswered={checkAllQuestionsAnswered}
                />
              )}
            </>
          ))}
        </div>
        <div className="panel-bottom-actions">
          <div className="panel-btns">
            <button className="btn btn-warning-gd save-btn" onClick={save}>
              Save & Exit
            </button>

            <button
              id="assessment-btn-submit"
              className="btn btn-warning-gd continue-btn"
              onClick={submitAnswer}
            >
              Submit
            </button>

            <button
              className="btn btn-warning-gd continue-btn"
              onClick={nextPage}
              disabled={quizCounter?.page >= pages?.length}
            >
              Continue
            </button>

            {/* {quizCounter?.page >= pages?.length ? (
              <button
                id="assessment-btn-submit"
                className="btn btn-warning-gd continue-btn"
                onClick={submitAnswer}
              >
                Submit
              </button>
            ) : (
              <button
                className="btn btn-warning-gd continue-btn"
                onClick={nextPage}
                disabled={quizCounter?.page >= pages?.length}
              >
                Continue
              </button>
            )} */}
          </div>

          <div className="panel-pages">
            Page{" "}
            {!!pages &&
              pages.length > 0 &&
              pages.map((page, idx) => (
                <>
                  <a
                    className={`page-link ${
                      idx + 1 === quizCounter?.page && "active"
                    }`}
                    onClick={() => stepsRef.current?.gotoPage(idx + 1)}
                  >
                    {idx + 1}
                  </a>{" "}
                </>
              ))}
          </div>
        </div>

        <Modal show={showFailedSubmit} onHide={handleCloseFailedSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="step-2-confirm-title">
              Some answers are missing...
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please provide answers for these questions to submit the form.
            </p>

            <table className="confirm-requirements-table">
              <thead>
                <tr>
                  <td colSpan="2">Missing answers</td>
                </tr>
              </thead>

              {unanswer?.map((a, i) => (
                <tr>
                  <td className="req-id-pr18">
                    <b>{`${a.displayId}.${a.groupId}`}</b>
                  </td>
                  <td>
                    <a
                      className="link"
                      onClick={() => {
                        handleCloseFailedSubmit();
                        stepsRef?.current?.goToQuestion(a.id);
                      }}
                    >
                      {a.title}
                    </a>
                  </td>
                </tr>
              ))}
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="step-2-confirm-btn"
              variant="secondary"
              onClick={handleCloseFailedSubmit}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <div className="panel-bottom-actions">
        <div className="panel-btns-testing">
          <h3>Testing Tools</h3>
          {ALLOW_DEV_TESTING && (
            <div className="panel-btns">
              <Button
                onClick={() => {
                  console.log(stepProgress);
                }}
              >
                Log current answers
              </Button>

              <Button
                onClick={() => {
                  setStepProgress(SAMPLE_TEST_PROGRESS);
                }}
              >
                Apply sample answers
              </Button>

              <Button
                onClick={() => {
                  console.log(TEST_ASSESSMENT_SUBMIT());
                }}
              >
                Test Submit with sample answers
              </Button>

              <Button
                onClick={() => {
                  console.log(TEST_ASSESSMENT_SUBMIT(stepProgress));
                }}
              >
                Test Submit with current answers
              </Button>
            </div>
          )}
          <p>Results are logged to the developer tool console.</p>
        </div>
      </div>
    </>
  );
}
