import { getActiveApp } from "api/functions";
import { makeSubscriptionFunction } from "api/utils";
import { onUpdateApp } from "graphql/subscriptions";

export const onUpdateActiveApp = ({ callbackFn }) =>
  makeSubscriptionFunction({
    query: onUpdateApp,
    onAction: (provider, value) => {
      const app = provider.value.data.onUpdateApp;
      if (!app) {
        getActiveApp().then((a) => callbackFn(a));
      } else {
        callbackFn(app);
      }
    },
    // authMode: "API_KEY",
  })();
