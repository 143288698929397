import "./index.scss";

import { useEffect, useState } from "react";

import {
  getAssessmentQuestionnaire,
  getProductStaticPrice,
  getUser,
} from "api";
import {
  AssessmentBlankStateLevel,
  AssessmentDone,
  AssessmentResultLevel,
  CertificateOptionsText,
  CSCAUNotification,
  MetaDescription,
  NavBreadcrumbList,
  Progress,
  RankBlock,
  Spinner,
} from "components";
import { useLoadingState } from "middleware";
import { AuthService } from "services";
import { ROUTES } from "variables";

export function CertificateOptions({ user }) {
  const [currentLevel, setCurrentLevel] = useState();
  const [currentStatus, setCurrentStatus] = useState();
  const [prices, setPrices] = useState([]);
  const { loading, setLoading } = useLoadingState();
  const [assessment, setAssessment] = useState("NOT-SET");
  const [howToQualifyLevel, setHowToQualifyLevel] = useState();

  useEffect(() => {
    (async () => {
      const a = await getAssessmentQuestionnaire();

      setAssessment(a);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const info = await AuthService.getCurrentAuthUserInfo();
      const user = await getUser(info?.id);
      if (!!user) {
        setCurrentLevel(user?.organisation?.certificationProgress?.level);
        setCurrentStatus(user?.organisation?.certificationProgress?.status);

        const staticPrices = (
          await Promise.all(
            // [1, 2, 3, 4, 5]
            [1, 2, 3].map((l) =>
              getProductStaticPrice({
                item: `LEVEL_${l}`,
              }),
            ),
          )
        ).map((p) => p.price);

        setPrices(staticPrices);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <MetaDescription
        pageTitle="Cyber security certification for your business"
        pageDescription="Be guided in your dashboard and complete your cyber security certification in CyberCert"
      />

      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}

      <div className="dashboard-header certificate-options">
        <div className="container-md">
          <div className="df-row">
            <div className="col-wl">
              <div className="card-wl">
                <div>
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTES.ROOT, name: "Dashboard" },
                      {
                        to: ROUTES.OPTIONS,
                        name: "Certification Options",
                      },
                    ]}
                  />

                  <CertificateOptionsText />
                </div>

                <div className="progress-notif">
                  <div className="text">
                    <p>Cyber security certification levels</p>
                  </div>

                  <CSCAUNotification />
                </div>
              </div>
            </div>

            <div className="col-pg">
              <Progress />
            </div>
          </div>

          <div className="assessment-panel mt-56">
            {!!assessment?.result ? (
              <AssessmentDone
                result={assessment?.result}
                eligibleRequirements={assessment?.eligibleRequirements}
              />
            ) : (
              <RankBlock assessment={assessment} hideSkipBlock={true} />
            )}
          </div>

          {!assessment?.result && (
            <div className="panel-quiz-header options">
              <div className="panel-steps">
                <div className="panel-steps-header">
                  <div className="heading">
                    <div className="options-icon-title">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.1094 0C18.7988 0 24.2188 5.41992 24.2188 12.1094C24.2188 18.7988 18.7988 24.2188 12.1094 24.2188C5.41992 24.2188 0 18.7988 0 12.1094C0 5.41992 5.41992 0 12.1094 0ZM10.6982 7.01172L14.3848 10.5469H5.46875C4.81934 10.5469 4.29688 11.0693 4.29688 11.7188V12.5C4.29688 13.1494 4.81934 13.6719 5.46875 13.6719H14.3848L10.6982 17.207C10.2246 17.6611 10.2148 18.418 10.6787 18.8818L11.2158 19.4141C11.6748 19.873 12.417 19.873 12.8711 19.4141L19.3506 12.9395C19.8096 12.4805 19.8096 11.7383 19.3506 11.2842L12.8711 4.7998C12.4121 4.34082 11.6699 4.34082 11.2158 4.7998L10.6787 5.33203C10.2148 5.80078 10.2246 6.55762 10.6982 7.01172Z"
                          fill="#28a745"
                        />
                      </svg>

                      <h1>Certification Options</h1>
                    </div>

                    <p className="pb-0 font-normal">
                      There are different levels of cyber security certification
                      available to choose from, as each business has unique
                      needs. You can select the level of certification that
                      suits your organisation’s cyber security requirements.
                    </p>
                  </div>
                </div>
                <div className="label-block-holder">
                  {[1, 2, 3].map((l) => (
                    <>
                      {!!currentLevel ? (
                        <AssessmentResultLevel
                          howToQualifyLevel={howToQualifyLevel}
                          level={l}
                          currentLevel={currentLevel}
                          currentStatus={currentStatus}
                          setHowToQualifyLevel={setHowToQualifyLevel}
                          price={prices[l - 1]}
                        />
                      ) : (
                        <AssessmentBlankStateLevel
                          howToQualifyLevel={howToQualifyLevel}
                          setHowToQualifyLevel={setHowToQualifyLevel}
                          level={l}
                        />
                      )}
                    </>
                  ))}

                  {/* <div className="subtitle-results require-lvl-3">
                    <div className="heading">
                      <h1>Level 3 certification required to qualify</h1>
                      <p className="rl3-p">
                        Click on "How to qualify" to see additional requirements
                        you need to implement to qualify for the higher level
                        certifications.
                      </p>
                    </div>
                  </div>

                  {[4, 5].map((l) => (
                    <>
                      {!!currentLevel ? (
                        <AssessmentResultLevel
                          howToQualifyLevel={howToQualifyLevel}
                          level={l}
                          currentLevel={currentLevel}
                          currentStatus={currentStatus}
                          setHowToQualifyLevel={setHowToQualifyLevel}
                          price={prices[l - 1]}
                        />
                      ) : (
                        <AssessmentBlankStateLevel
                          howToQualifyLevel={howToQualifyLevel}
                          setHowToQualifyLevel={setHowToQualifyLevel}
                          level={l}
                        />
                      )}
                    </>
                  ))} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
