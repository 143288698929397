import { useEffect, useState } from "react";

import {
  getIssuedBadge,
  getSortedByDateCompletedCertificationProgressesByABN,
  getSpecificEnvelopeSignedAttestationUrl,
  getSpecificLevelCertificateDownloadUrl,
} from "api";
import { DashboardCertSummary } from "components";
import { useDownloadUrlState, useLoadingState } from "middleware";

export function DashboardCertificateHistory({ progress }) {
  const [completedProgress, setCompletedProgress] = useState();
  const { setLoading } = useLoadingState();
  const { setDownloadUrl } = useDownloadUrlState();

  useEffect(() => {
    setup();
  }, [progress]);

  const setup = async () => {
    setLoading(true);

    const displayProgress = await getLastCompletedProgress();

    (async () => {
      const certificateLink = await getSpecificLevelCertificateDownloadUrl(
        displayProgress?.level,
      );
      const signedDeedLink = await getSpecificEnvelopeSignedAttestationUrl(
        displayProgress?.envelopeId,
      );
      const badges = await getIssuedBadge({
        level: displayProgress?.level,
      });
      setDownloadUrl({
        certificateLink,
        signedDeedLink,
        badges,
      });
    })();

    setCompletedProgress(displayProgress);
    setLoading(false);
  };

  const getLastCompletedProgress = async () => {
    const progs =
      (
        await getSortedByDateCompletedCertificationProgressesByABN(
          progress?.ABN,
        )
      )?.items || [];
    const displayProgress = progs[0];

    if (!displayProgress) {
      return displayProgress;
    }
    return displayProgress;
  };

  return (
    <>
      {!!progress &&
        progress.status === "IN_PROGRESS" &&
        !!completedProgress && (
          <div className="dashboard-certificate-history-container">
            <DashboardCertSummary progress={completedProgress} />
          </div>
        )}
    </>
  );
}
