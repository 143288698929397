import { isUpgradedRequirement } from "services";

export function AssessmentRequirementDetail({
  requirement,
  explainRequirement = () => {},
}) {
  return (
    <>
      {isUpgradedRequirement(requirement?.requirementId) ? (
        <li className="upgraded-requirement">
          <span>{requirement?.requirementId}</span>
          <div className="upgraded-req-flex">
            {requirement?.title}
            <svg
              onClick={() => explainRequirement(requirement)}
              className="question"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </li>
      ) : (
        <li>
          <span>{requirement?.requirementId}</span>
          {requirement?.title}
        </li>
      )}
    </>
  );
}
