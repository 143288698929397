import { useState } from "react";

import { FormInput } from "components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { ROUTES } from "variables";

export function EmailInputForm({
  handleInput,
  onContinue,
  pValidation,
  errorTxt,
}) {
  const [validation, setValidation] = useState("NA");
  const [disabled, setDisabled] = useState(true);

  const pSetValidation = (value) => {
    setValidation(value);
    setDisabled(value !== "success");
  };

  const validateContinue = () => {
    if (validation === "success") {
      onContinue();
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <div className="auth-title heading2">Reset password</div>
      <div className="subtitle">
        <Link to={ROUTES.LOGIN}>Back to Login</Link>
      </div>

      <div className="subtitle no-p p-b">
        Please provide the email address linked to your account and we will send
        you a link to proceed.
      </div>

      <div className={`form-validation ${pValidation}`}>
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>

      <Form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <FormInput
          controlId="resetEmail"
          label="Work Email Address"
          type="email"
          name="email"
          maxLength="240"
          onChange={handleInput}
          pValidation={validation}
          pSetValidation={(value) => pSetValidation(value)}
          required
        />

        <Button
          className="btn btn-warning-gd"
          onClick={validateContinue}
          disabled={disabled}
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
