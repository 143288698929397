import "./index.scss";

import { useEffect, useState } from "react";

import { Fblogo, GoogleLogo } from "assets";
import { FormInput, PasswordStrength } from "components";
import { useLoadingState } from "middleware";
import { AuthService, parseDate } from "services";

const initialState = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};

export function AccountSettings() {
  //get notification settings here
  //useEffect(...);
  const [input, setInput] = useState(initialState);
  const [status, setStatus] = useState("NA");
  const [errorTxt, setErrorTxt] = useState("");
  const [inputTypes, setInputTypes] = useState({
    password: "password",
    newPassword: "password",
    confirmPassword: "password",
  });
  const [passwordStrength, setPasswordStrength] = useState();

  const [showPasswordStrength, setShowPasswordStrength] = useState(false);

  const handlePasswordInputClick = () => {
    setShowPasswordStrength(true);
  };

  const { setLoading } = useLoadingState();
  const [provider, setProvider] = useState();
  const [dateCreated, setDateCreated] = useState();

  useEffect(() => {
    AuthService.getCurrentAuthSession().then((sess) => {
      const identities = sess?.attributes?.identities;
      if (!!identities) {
        setDateCreated(parseDate(JSON.parse(identities)[0].dateCreated));
        try {
          if (
            !!identities &&
            JSON.parse(identities)[0].providerName === "Google"
          ) {
            setProvider("Google");
          } else if (
            !!identities &&
            JSON.parse(identities)[0].providerName === "Facebook"
          ) {
            setProvider("Facebook");
          } else {
            setProvider("Default");
          }
        } catch (err) {
          setProvider("Default");
        }
      } else {
        setProvider("Default");
      }
    });
  }, []);

  const changePass = (e) => {
    setInput({
      ...input,
      password: e.currentTarget.value,
    });
  };
  const changeNewPass = (e) => {
    setInput({
      ...input,
      newPassword: e.currentTarget.value,
    });
  };
  const changeConfirmPass = (e) => {
    setInput({
      ...input,
      confirmPassword: e.currentTarget.value,
    });
  };

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (
      input.password.length > 0 &&
      input.newPassword.length > 0 &&
      input.confirmPassword.length > 0 &&
      input.newPassword === input.confirmPassword &&
      input.newPassword !== input.password
    ) {
      await update();
    } else {
      setStatus("error");
      if (
        input.password.length === 0 ||
        input.newPassword.length === 0 ||
        input.confirmPassword.length === 0
      ) {
        setErrorTxt("All fields are required.");
      } else if (
        input.newPassword.length > 0 &&
        input.confirmPassword.length > 0 &&
        input.newPassword !== input.confirmPassword
      ) {
        setErrorTxt("Passwords do not match.");
      } else if (
        input.newPassword.length > 0 &&
        input.password.length > 0 &&
        input.newPassword === input.password
      ) {
        setErrorTxt("Please use a new password.");
      }
    }
    setLoading(false);
  };

  const update = async () => {
    if (input.newPassword === input.confirmPassword) {
      //update password
      let succ = true;
      await AuthService.changePassword(
        input.password,
        input.newPassword,
        (err) => {
          if (err.code === "NotAuthorizedException") {
            succ = false;
            setStatus("error");
            setErrorTxt("Current password is incorrect.");
          }
        },
      );
      if (succ) {
        setStatus("success");
      }
    } else {
      // error state
      setStatus("error");
      setErrorTxt("Passwords do not match.");
    }
  };

  const togglePwd = () => {
    setInputTypes({
      ...inputTypes,
      password: inputTypes.password === "password" ? "text" : "password",
    });
  };

  const toggleNewPwd = () => {
    setInputTypes({
      ...inputTypes,
      newPassword: inputTypes.newPassword === "password" ? "text" : "password",
    });
  };

  const toggleConfirmPwd = () => {
    setInputTypes({
      ...inputTypes,
      confirmPassword:
        inputTypes.confirmPassword === "password" ? "text" : "password",
    });
  };

  return (
    <>
      <h4 className="table-heading main-heading">
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.7445 13.056L17.9502 12.0119C18.1313 11.0272 18.1313 10.017 17.9502 9.03226L19.7445 7.98813C19.9509 7.86928 20.0436 7.6231 19.9762 7.3939C19.5087 5.88287 18.7126 4.51615 17.6722 3.37863C17.5121 3.20461 17.251 3.16217 17.0488 3.28101L15.2544 4.32515C14.5005 3.6715 13.6328 3.16641 12.6935 2.83534V0.75131C12.6935 0.51362 12.5292 0.305641 12.2975 0.254708C10.7517 -0.0933385 9.16792 -0.0763606 7.6979 0.254708C7.46623 0.305641 7.30196 0.51362 7.30196 0.75131V2.83959C6.36687 3.1749 5.49917 3.67999 4.74099 4.3294L2.95084 3.28526C2.74445 3.16641 2.48751 3.20461 2.32745 3.38288C1.28706 4.51615 0.490969 5.88287 0.0234244 7.39814C-0.0481816 7.62735 0.0486971 7.87352 0.255091 7.99237L2.04945 9.03651C1.86833 10.0212 1.86833 11.0314 2.04945 12.0161L0.255091 13.0603C0.0486971 13.1791 -0.0439694 13.4253 0.0234244 13.6545C0.490969 15.1655 1.28706 16.5322 2.32745 17.6698C2.48751 17.8438 2.74866 17.8862 2.95084 17.7674L4.7452 16.7232C5.49917 17.3769 6.36687 17.882 7.30617 18.213V20.3013C7.30617 20.539 7.47044 20.747 7.70211 20.7979C9.24796 21.146 10.8317 21.129 12.3017 20.7979C12.5334 20.747 12.6977 20.539 12.6977 20.3013V18.213C13.6328 17.8777 14.5005 17.3726 15.2586 16.7232L17.053 17.7674C17.2594 17.8862 17.5163 17.848 17.6764 17.6698C18.7168 16.5365 19.5129 15.1698 19.9804 13.6545C20.0436 13.421 19.9509 13.1749 19.7445 13.056ZM9.99771 13.9176C8.14017 13.9176 6.62802 12.3939 6.62802 10.5221C6.62802 8.65026 8.14017 7.1265 9.99771 7.1265C11.8553 7.1265 13.3674 8.65026 13.3674 10.5221C13.3674 12.3939 11.8553 13.9176 9.99771 13.9176Z"
            fill="black"
          />
        </svg>
        Settings
      </h4>

      {provider === "Default" && (
        <>
          <div>
            <strong className="form-heading">Update your password</strong>
          </div>
          <form className="tab-form" onSubmit={submit}>
            <div className="form-row">
              <div className="col-form">
                <div className="form-item">
                  <label>
                    <strong>Current Password</strong>
                  </label>
                  <div className="pass-wrap">
                    <input
                      type={inputTypes.password}
                      required
                      onChange={changePass}
                    />
                    <span className="js-password-toggle" onClick={togglePwd}>
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.24219 5.24219C8.73698 4.7474 9.32292 4.5 10 4.5C10.6771 4.5 11.263 4.7474 11.7578 5.24219C12.2526 5.73698 12.5 6.32292 12.5 7C12.5 7.67708 12.2526 8.26302 11.7578 8.75781C11.263 9.2526 10.6771 9.5 10 9.5C9.32292 9.5 8.73698 9.2526 8.24219 8.75781C7.7474 8.26302 7.5 7.67708 7.5 7C7.5 6.32292 7.7474 5.73698 8.24219 5.24219ZM7.03125 9.96875C7.86458 10.776 8.85417 11.1797 10 11.1797C11.1458 11.1797 12.1224 10.776 12.9297 9.96875C13.763 9.13542 14.1797 8.14583 14.1797 7C14.1797 5.85417 13.763 4.8776 12.9297 4.07031C12.1224 3.23698 11.1458 2.82031 10 2.82031C8.85417 2.82031 7.86458 3.23698 7.03125 4.07031C6.22396 4.8776 5.82031 5.85417 5.82031 7C5.82031 8.14583 6.22396 9.13542 7.03125 9.96875ZM4.41406 2.46875C6.08073 1.32292 7.94271 0.75 10 0.75C12.0573 0.75 13.9193 1.32292 15.5859 2.46875C17.2526 3.61458 18.4505 5.125 19.1797 7C18.4505 8.875 17.2526 10.3854 15.5859 11.5312C13.9193 12.6771 12.0573 13.25 10 13.25C7.94271 13.25 6.08073 12.6771 4.41406 11.5312C2.7474 10.3854 1.54948 8.875 0.820312 7C1.54948 5.125 2.7474 3.61458 4.41406 2.46875Z"
                          fill="#C6C0C0"
                        ></path>
                      </svg>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="14"
                        viewBox="0 0 448 448"
                      >
                        <path
                          d="M138.75 333.75l19.5-35.25c-29-21-46.25-54.75-46.25-90.5 0-19.75 5.25-39.25 15.25-56.25-39 20-71.5 51.5-95.25 88.25 26 40.25 62.75 74 106.75 93.75zM236 144c0-6.5-5.5-12-12-12-41.75 0-76 34.25-76 76 0 6.5 5.5 12 12 12s12-5.5 12-12c0-28.75 23.5-52 52-52 6.5 0 12-5.5 12-12zM326.75 96.25c0 0.5 0 1.75-0.25 2.25-52.75 94.25-105 189-157.75 283.25l-12.25 22.25c-1.5 2.5-4.25 4-7 4-4.5 0-28.25-14.5-33.5-17.5-2.5-1.5-4-4-4-7 0-4 8.5-17.5 11-21.75-48.5-22-89.25-59.5-118-104.5-3.25-5-5-11-5-17.25 0-6 1.75-12.25 5-17.25 49.5-76 126.75-126.75 219-126.75 15 0 30.25 1.5 45 4.25l13.5-24.25c1.5-2.5 4-4 7-4 4.5 0 28 14.5 33.25 17.5 2.5 1.5 4 4 4 6.75zM336 208c0 46.5-28.75 88-72 104.5l70-125.5c1.25 7 2 14 2 21zM448 240c0 6.5-1.75 11.75-5 17.25-7.75 12.75-17.5 25-27.25 36.25-49 56.25-116.5 90.5-191.75 90.5l18.5-33c72.75-6.25 134.5-50.5 173.5-111-18.5-28.75-42.25-54-70.5-73.5l15.75-28c31 20.75 62.25 52 81.75 84.25 3.25 5.5 5 10.75 5 17.25z"
                          fill="#C6C0C0"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-form">
                <div className="form-item">
                  <label>
                    <strong>New Password</strong>
                  </label>

                  <div
                    className="password-input-wrapper"
                    onClick={handlePasswordInputClick}
                  >
                    <FormInput
                      controlId="newpasswd"
                      type="password"
                      name="password"
                      placeholder=""
                      onChange={changeNewPass}
                      errorTxt={""}
                      checkPasswdStrength={true}
                      reportPasswdCheck={setPasswordStrength}
                      usePlainHtml={true}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-form">
                <div className="form-item">
                  <label>
                    <strong>Confirm New Password</strong>
                  </label>
                  <div className="pass-wrap">
                    <input
                      type={inputTypes.confirmPassword}
                      required
                      onChange={changeConfirmPass}
                    />
                    <span
                      className="js-password-toggle"
                      onClick={toggleConfirmPwd}
                    >
                      <svg
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.24219 5.24219C8.73698 4.7474 9.32292 4.5 10 4.5C10.6771 4.5 11.263 4.7474 11.7578 5.24219C12.2526 5.73698 12.5 6.32292 12.5 7C12.5 7.67708 12.2526 8.26302 11.7578 8.75781C11.263 9.2526 10.6771 9.5 10 9.5C9.32292 9.5 8.73698 9.2526 8.24219 8.75781C7.7474 8.26302 7.5 7.67708 7.5 7C7.5 6.32292 7.7474 5.73698 8.24219 5.24219ZM7.03125 9.96875C7.86458 10.776 8.85417 11.1797 10 11.1797C11.1458 11.1797 12.1224 10.776 12.9297 9.96875C13.763 9.13542 14.1797 8.14583 14.1797 7C14.1797 5.85417 13.763 4.8776 12.9297 4.07031C12.1224 3.23698 11.1458 2.82031 10 2.82031C8.85417 2.82031 7.86458 3.23698 7.03125 4.07031C6.22396 4.8776 5.82031 5.85417 5.82031 7C5.82031 8.14583 6.22396 9.13542 7.03125 9.96875ZM4.41406 2.46875C6.08073 1.32292 7.94271 0.75 10 0.75C12.0573 0.75 13.9193 1.32292 15.5859 2.46875C17.2526 3.61458 18.4505 5.125 19.1797 7C18.4505 8.875 17.2526 10.3854 15.5859 11.5312C13.9193 12.6771 12.0573 13.25 10 13.25C7.94271 13.25 6.08073 12.6771 4.41406 11.5312C2.7474 10.3854 1.54948 8.875 0.820312 7C1.54948 5.125 2.7474 3.61458 4.41406 2.46875Z"
                          fill="#C6C0C0"
                        ></path>
                      </svg>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="14"
                        viewBox="0 0 448 448"
                      >
                        <path
                          d="M138.75 333.75l19.5-35.25c-29-21-46.25-54.75-46.25-90.5 0-19.75 5.25-39.25 15.25-56.25-39 20-71.5 51.5-95.25 88.25 26 40.25 62.75 74 106.75 93.75zM236 144c0-6.5-5.5-12-12-12-41.75 0-76 34.25-76 76 0 6.5 5.5 12 12 12s12-5.5 12-12c0-28.75 23.5-52 52-52 6.5 0 12-5.5 12-12zM326.75 96.25c0 0.5 0 1.75-0.25 2.25-52.75 94.25-105 189-157.75 283.25l-12.25 22.25c-1.5 2.5-4.25 4-7 4-4.5 0-28.25-14.5-33.5-17.5-2.5-1.5-4-4-4-7 0-4 8.5-17.5 11-21.75-48.5-22-89.25-59.5-118-104.5-3.25-5-5-11-5-17.25 0-6 1.75-12.25 5-17.25 49.5-76 126.75-126.75 219-126.75 15 0 30.25 1.5 45 4.25l13.5-24.25c1.5-2.5 4-4 7-4 4.5 0 28 14.5 33.25 17.5 2.5 1.5 4 4 4 6.75zM336 208c0 46.5-28.75 88-72 104.5l70-125.5c1.25 7 2 14 2 21zM448 240c0 6.5-1.75 11.75-5 17.25-7.75 12.75-17.5 25-27.25 36.25-49 56.25-116.5 90.5-191.75 90.5l18.5-33c72.75-6.25 134.5-50.5 173.5-111-18.5-28.75-42.25-54-70.5-73.5l15.75-28c31 20.75 62.25 52 81.75 84.25 3.25 5.5 5 10.75 5 17.25z"
                          fill="#C6C0C0"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {showPasswordStrength && (
              <div className="my-32">
                <PasswordStrength passwdStrength={passwordStrength} />
              </div>
            )}

            <div className="form-row">
              <div className="col-form-btn">
                <button className="btn btn-warning-gd" type="submit">
                  Update
                </button>
              </div>
            </div>

            <div className="col-form-btn">
              <div className={`form-validation ${status}`}>
                <div className="validation-txt">
                  <p className="v-text success">Updated successfully.</p>
                  <p className="v-text error">{errorTxt}</p>
                </div>
              </div>
            </div>
          </form>
        </>
      )}

      {provider === "Google" && (
        <>
          <div className="f-block">
            <strong className="form-heading">Single sign on</strong>
          </div>
          <div className="f-row-info-icon">
            <div className="sso-text">
              <img src={GoogleLogo} alt="Google Logo" />
            </div>
            <div className="sso-text">
              <p>
                Your account has been created via Google on{" "}
                <b>{dateCreated || <>N/A</>}</b>.
              </p>
            </div>
          </div>
        </>
      )}

      {provider === "Facebook" && (
        <>
          <div className="f-block">
            <strong className="form-heading">Single sign on</strong>
          </div>
          <div className="f-row-info-icon">
            <div className="sso-text">
              <img src={Fblogo} alt="Facebook Logo" />
            </div>
            <div className="sso-text">
              <p>
                Your account has been created via Facebook on{" "}
                <b>{dateCreated || <>N/A</>}</b>.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
