import "../legal.scss";

import { useEffect } from "react";

import { Spinner } from "components";
import { useLoadingState } from "middleware";
import { getLandingPage } from "services";

export function Terms() {
  const { loading } = useLoadingState();

  useEffect(() => {
    window.location.href = `${getLandingPage()}/terms`;
  }, []);

  return (
    <>
      {loading.loading && (
        <div className="overlay white-bg">
          <Spinner svgTemplate={true} />
        </div>
      )}
    </>
  );
}
// export function Terms() {
//   return (
//     <>
//       <MetaDescription
//         pageTitle="Terms of use"
//         pageDescription="Please read the Terms of use set by CyberCert"
//       />
//       <div className="main-dashboard-container">
//         <div className="dashboard-header">
//           <div className="container-md">
//             <div className="legal-header-container">
//               <div className="card-wl">
//                 <NavBreadcrumbList
//                   items={[
//                     { to: ROUTE.ROOT, name: "Dashboard" },
//                     {
//                       to: ROUTE.TERMS,
//                       name: "Terms of use",
//                     },
//                   ]}
//                 />

//                 <div className="heading">
//                   <h1 className="title">Terms of use</h1>
//                 </div>

//                 <div className="text">
//                   These terms were last updated on 27 September 2023
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="legal-container">
//         <div className="legal-section">
//           <div className="container-md">
//             <div className="text-block">
//               <div className="important-notice">
//                 <h6>IMPORTANT - READ CAREFULLY:</h6>
//                 <p>
//                   This Terms of Use Agreement (<b>“Subscriber Agreement”</b>) is
//                   a legally binding agreement between you (a company, firm,
//                   partnership, or an individual user) and{" "}
//                   <b>CyberCert Pty Ltd</b> ABN 87 662 681 423 covering your
//                   application for and use of a CyberCert certificate (
//                   <b>“Certificate”</b>) and use of the online badge, resources
//                   and processes regarding Certificates (<b>“Service”</b>). We
//                   will refer to ourselves in this Subscriber Agreement as{" "}
//                   <b>“we”</b> or <b>“us”</b>. We are an Australian corporation
//                   with our headquarters in Brisbane.
//                 </p>
//                 <p>
//                   You must read this Subscriber Agreement carefully before
//                   accessing or using the Service or applying for a Certificate.
//                 </p>
//                 <p>
//                   <b>
//                     Your use of the Service or application for and use of a
//                     Certificate and associated badge is conditional on your
//                     acceptance of the terms set forth in this Subscriber
//                     Agreement. By using or continuing to use the Service or
//                     applying for and using or continuing to use a Certificate
//                     and associated badge, you agree to be bound by this
//                     Subscriber Agreement.
//                   </b>
//                 </p>
//                 <p>
//                   <b>
//                     If you do not agree to these terms, you have no right to
//                     obtain information or otherwise continue using a Certificate
//                     or the Service.
//                   </b>
//                 </p>
//                 <p>Effective From 1 August 2023</p>
//               </div>
//               <ul className="number-list">
//                 <li>
//                   <h3 className="list-title">Overview</h3>
//                   <ul>
//                     <li>
//                       {/* <h5 className="list-title">Accepting this Agreement</h5> */}
//                       <ul>
//                         <li>
//                           You may apply for certification online at our website
//                           at{" "}
//                           <a href="https://certification.cybercert.ai">
//                             https://certification.cybercert.ai
//                           </a>
//                           .
//                         </li>
//                         <li>
//                           There are 5 levels of certification requirements (
//                           <b>“Requirements”</b>), being Levels 1 to 5, and there
//                           is a Certificate and badge for each level.
//                         </li>
//                         <li>
//                           To use a Certificate and badge, you must meet the
//                           Requirements for that level.
//                         </li>
//                         <li>
//                           In addition, (i) for levels 1 to 5, you must provide
//                           an attestation as set out below, and (ii) for levels 4
//                           and 5, you must be audited as set out below.
//                         </li>
//                         <li>
//                           You must also pay the Fee, which is a yearly fee.
//                         </li>
//                         <li>
//                           This Agreement includes these standard terms and the
//                           pricing details set out on our website at{" "}
//                           <a href="https://certification.cybercert.ai">
//                             https://certification.cybercert.ai
//                           </a>
//                           .
//                         </li>
//                         <li>
//                           We will provide the Service to you in accordance with
//                           this Agreement.
//                         </li>
//                         <li>
//                           The content provided via our Service is general
//                           information only and does not comprise specific
//                           technical, legal or financial advice, and is not
//                           tailored to your specific circumstances. You are
//                           encouraged to obtain specific technical, legal or
//                           financial advice from experts who are appropriately
//                           qualified relevant to your circumstances.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Application For Certification</h3>
//                   <ul>
//                     <li>
//                       <h5 className="list-title">The Service</h5>
//                       <ul>
//                         <li>The Service can be accessed via our website.</li>
//                         <li>
//                           We will provide the Service in a professional manner
//                           with due care and skill and in accordance with good
//                           industry practice. In providing the Service, we will
//                           comply with all relevant Laws.
//                         </li>
//                         <li>
//                           The Service allows you to obtain a certification at a
//                           particular level in relation to your level of cyber
//                           security maturity.
//                         </li>
//                         <li>There are five levels of certification.</li>
//                         <li>
//                           As further set out in clause 4, there is a
//                           subscription package for each level of certification.
//                         </li>
//                         <li>
//                           For each level of certification, there are
//                           requirements to meet (<b>“Requirements”</b>). Once you
//                           meet all the Requirements for a level, you will be
//                           issued a certification for that level.
//                         </li>
//                         <li>
//                           We will provide you with information as to each
//                           Requirement.
//                         </li>
//                         <li>
//                           We will issue you with a certification for a level
//                           once you demonstrate to our reasonable satisfaction
//                           that you meet the Requirements for a level, which will
//                           include Attestation (see clause 2.9) and potentially
//                           an audit (clause 6). We will inform you how you can
//                           meet and demonstrate that you meet the Requirements
//                           for a level.
//                         </li>
//                         <li>
//                           We will license you to use the Certification Badge for
//                           a level on the terms set out below, once you meet the
//                           Requirements for that level.
//                         </li>
//                         <li>
//                           The certification is for a defined period, as set out
//                           on your certificate. You will no longer be
//                           certificated after that period unless you renew the
//                           certification.
//                         </li>
//                         <li>
//                           If you become certified and then cease to meet the
//                           Requirements, your certification then automatically
//                           expires.
//                         </li>
//                         <li>
//                           To renew a certification, you will need to purchase a
//                           new subscription for the certification level you
//                           desire, complete the certification requirements,
//                           including attestation and successfully completing an
//                           audit if applicable. You will need to complete these
//                           steps even if the requirements for your certification
//                           level have not changed since your previous
//                           certification.
//                         </li>
//                         <li>
//                           Regardless of the level, we reserve the right to
//                           conduct an audit to determine if you meet or continue
//                           to meet the Requirements. You agree to cooperate with
//                           us and our auditors, including providing access to
//                           your records, premises and IT systems.
//                         </li>
//                         <li>
//                           We will not provide you with specific advice or
//                           assistance as to how to meet the Requirements. You may
//                           need to engage a third-party security expert to assist
//                           you meet the Requirements.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Application</h5>
//                       <ul>
//                         <li>
//                           As part of the Service, you may apply for
//                           certification online at our website.
//                         </li>
//                         <li>
//                           To apply, you must establish a user account in
//                           accordance with clause 2.4 and pay the Fee in
//                           accordance with clause 4.
//                         </li>
//                         <li>
//                           In your application, you must select the level of
//                           certification for which you are applying.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Users</h5>
//                       <ul>
//                         <li>
//                           We will provide you with online access to and use of
//                           the Service.
//                         </li>
//                         <li>
//                           Users must have an account to access and use the
//                           Service.
//                         </li>
//                         <li>
//                           You must not create accounts for use by third parties,
//                           that is, people who are not associated with you. You
//                           may only create and claim an account for a business
//                           where you have the legal right to do so.
//                         </li>
//                         <li>
//                           You will ensure that each person who uses the Service
//                           from your organization has an account in their name.
//                           Accounts must not be shared by or between users.
//                         </li>
//                         <li>
//                           We may require that each User agree to terms of use
//                           that are not inconsistent with this Agreement before
//                           using the Service.
//                         </li>
//                         <li>
//                           To use the Service, you must have a computer or mobile
//                           device with an up-to-date operating system and web
//                           browser, and an internet connection.
//                         </li>
//                         <li>
//                           You are responsible for all third-party costs
//                           associated with accessing the Service, including for
//                           example the fees charged by internet service providers
//                           or telecommunications carriers.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">User Accounts</h5>
//                       <ul>
//                         <li>
//                           When a user account is created to use the Service, you
//                           must:
//                           <ul>
//                             <li>
//                               provide an Australian Business Number (ABN) which
//                               you are legally entitled to use and that is for
//                               your business;
//                             </li>
//                             <li>
//                               nominate a unique username and password, or
//                               nominate a single sign-on service (SSO) that we
//                               support;
//                             </li>
//                             <li>
//                               provide accurate, current and complete information
//                               for the indicated mandatory fields (including a
//                               valid email address); and
//                             </li>
//                             <li>
//                               maintain and update your user information to keep
//                               your account details accurate, current and
//                               complete.
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           If you supply us with any inaccurate, false or
//                           incomplete information (or fail to keep such
//                           information current), we have the right to terminate
//                           your account and access to the Service.
//                         </li>
//                         <li>
//                           We reserve the right to reject any new account or
//                           application for the Service in our absolute
//                           discretion. If we decline your application after you
//                           have made a payment to us for the Service that we
//                           decline, we will refund the payment in full.
//                         </li>
//                         <li>
//                           We may close your user account if it is dormant for
//                           more than 13 months or if you have not had an active
//                           subscription in the past 13 months. We do not have to
//                           notify you if we close your account, but in all
//                           likelihood we will do so.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Usernames and Passwords</h5>
//                       <ul>
//                         <li>
//                           To use all features of the Service, you must create an
//                           account with us, including selecting a unique username
//                           and password or nominate a single sign-on service
//                           (SSO) that we support.
//                         </li>
//                         <li>
//                           You are fully responsible for maintaining the
//                           confidentiality of your username and password, and for
//                           all activities that are conducted through or under
//                           your account.
//                         </li>
//                         <li>
//                           You must not under any circumstances share a password
//                           for the Service with any other person.
//                         </li>
//                         <li>
//                           You must not share, transfer, sell, rent, lend or
//                           otherwise dispose of a username or password for the
//                           Service (or attempt to do any of the foregoing). If
//                           you do so, your account and subscription may be
//                           terminated or suspended, and we do not have to give a
//                           refund to you in these circumstances.
//                         </li>
//                         <li>
//                           If we detect any misuse or suspected misuse of your
//                           login details (including the concurrent use of your
//                           login details), your account and subscription may be
//                           terminated or suspended, and we do not have to give a
//                           refund to you in these circumstances.
//                         </li>
//                         <li>
//                           You must inform us immediately if you suspect or
//                           become aware of a breach of security relating to your
//                           account. We may require you to change your password.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Resources</h5>
//                       <ul>
//                         <li>
//                           If you have a user account, you will be able to access
//                           resources, materials, questionnaires, instructions,
//                           checklists, and guides (<b>“Resources”</b>) via our
//                           website. We will provide you with access to and use of
//                           the Resources as part of the Service.
//                         </li>
//                         <li>
//                           You may access and use the Resources solely for your
//                           internal business purposes.
//                         </li>
//                         <li>
//                           You must not distribute, sell, or provide the
//                           Resources to any person outside your business without
//                           our prior written consent. However, you may provide
//                           the relevant Resources to your IT providers and
//                           security specialists to assist you in meeting the
//                           Requirements but for no other purpose.
//                         </li>
//                         <li>
//                           To use the Resources, you must have a computer or
//                           mobile device with an up-to-date operating system and
//                           web browser, and a fast internet connection.
//                         </li>
//                         <li>
//                           You are responsible for all third-party costs
//                           associated with accessing the Resources, including for
//                           example the fees charged by internet service providers
//                           or telecommunications carriers.
//                         </li>
//                         <li>
//                           We will provide the Resources in a professional manner
//                           with due care and skill and in accordance with good
//                           industry practice.
//                         </li>
//                         <li>
//                           In providing the Resources, we will comply with all
//                           relevant Laws.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Use of the Service</h5>
//                       <ul>
//                         <li>
//                           You must use the Service in a responsible and
//                           reasonable manner. You must not use the Service in a
//                           way that is against the Law or that harms us, the
//                           Service or other users of the Service.
//                         </li>
//                         <li>
//                           Without limiting the foregoing, you (and each User)
//                           must not:
//                           <ul>
//                             <li>
//                               Attempt to gain access to, alter or disrupt any
//                               account, software, hardware (including, without
//                               limitation, the Service) or network relating to
//                               the Service without authorization;
//                             </li>
//                             <li>
//                               Use automatic scripts or programs (including web
//                               crawlers) to access or attempt to gain access to
//                               the Service;
//                             </li>
//                             <li>
//                               Attempt to access the Service other than through
//                               our designated website or as otherwise permitted
//                               by us;
//                             </li>
//                             <li>
//                               Remove or alter any copyright, trademark, logo or
//                               other proprietary notice or label appearing on or
//                               in the Service, a Certification Badge or the
//                               Template Documents;
//                             </li>
//                             <li>
//                               Reverse engineer, decompile or disassemble the
//                               Service, or otherwise attempt to derive the trade
//                               secrets of the Service except as, and then only to
//                               the extent, expressly permitted by applicable Law;
//                             </li>
//                             <li>
//                               Create any derivative works or adaptations of the
//                               Service;
//                             </li>
//                             <li>
//                               Rent, lease, lend-for-profit or provide commercial
//                               hosting of the Service, or otherwise provide or
//                               make available the Service to any other person for
//                               their use;
//                             </li>
//                             <li>Resell the Service to any person;</li>
//                             <li>
//                               Create a white label system using the Service;
//                             </li>
//                             <li>
//                               Frame any website provided via the Service; or
//                             </li>
//                             <li>
//                               Use the Service other than as expressly permitted
//                               by this Agreement, or in any manner that we
//                               reasonably determine to be inappropriate or
//                               excessive.
//                             </li>
//                           </ul>
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Purpose</h5>
//                       <ul>
//                         <li>
//                           You have no right to use the Service for any purpose
//                           other than as specified in this Agreement or on our
//                           website.
//                         </li>
//                         <li>
//                           The rights granted in this Agreement are your only
//                           rights in relation to the Service.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Attestations</h5>
//                       <ul>
//                         <li>
//                           As part of a Requirement or pre-condition to receive a
//                           certification, you will be required to provide us with
//                           a statutory declaration, declaration or letter of
//                           attestation (<b>“Attestation”</b>).
//                         </li>
//                         <li>
//                           If you are a corporation, then a director must sign
//                           the Attestation.
//                         </li>
//                         <li>
//                           If you are not a corporation, then the Attestation
//                           must be signed by an owner, officer or senior
//                           executive.
//                         </li>
//                         <li>
//                           You hereby warrant that all information provided by,
//                           or on behalf of, you to us for the purpose of
//                           obtaining the certification (including in respect of
//                           the Attestation) will be accurate, complete, reliable,
//                           suitable in all respects and not misleading.
//                         </li>
//                         <li>
//                           You agree that you and the person providing the
//                           Attestation on behalf of you may be liable for any
//                           civil or criminal penalties that may apply if the
//                           Attestation is false, misleading or incorrect.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Updates</h5>
//                       <ul>
//                         <li>
//                           We may change, modify or remove some or all of the
//                           features or aspects of the Service or the Requirements
//                           at any time and at our sole discretion.
//                         </li>
//                         <li>
//                           We may make available enhancements or updates to the
//                           Service but are under no obligation to do so.
//                         </li>
//                         <li>
//                           We will provide you at least 3 months’ prior notice if
//                           we intend to change any of the Requirements. This
//                           notice will not be required if the 3 months’ notice
//                           period (i) would pose a security or intellectual
//                           property issue, or (ii) would cause us to violate
//                           legal requirements. Where we have issued you with a
//                           certification, and the Requirements change during the
//                           validity period for the certification, your
//                           certification will remain valid for the remaining
//                           period of the certification, but you will have to meet
//                           the new Requirements if you renew your certification.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Website Maintenance</h5>
//                       <ul>
//                         <li>
//                           We periodically conduct maintenance in respect to our
//                           website. During a maintenance period, our website may
//                           be unavailable, or some features may not work as
//                           expected. We will for to give you at least one weeks’
//                           notice of any scheduled maintenance that may impact
//                           your use of the Service. This notice may be provided
//                           on our website.
//                         </li>
//                         <li>
//                           We have the right to interrupt the Service from time
//                           to time, as and when we deem appropriate, to perform
//                           maintenance relating to the Service.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Advertising and Links</h5>
//                       <ul>
//                         <li>
//                           Our website may contain hyperlinks and other pointers
//                           to websites operated by others. These linked websites
//                           are not under our control, and we are not responsible
//                           for the contents on them. We provide these hyperlinks
//                           to you as a convenience only, and the inclusion of any
//                           link does not imply any endorsement of the linked
//                           website by us. You visit such websites at your own
//                           risk.
//                         </li>
//                         <li>
//                           Our website may contain third party advertisements
//                           (including banner ads and full-page advertisements)
//                           which contain embedded hyperlinks, or which include
//                           referral buttons to websites operated by others. All
//                           such advertising (including referral buttons and
//                           embedded hyperlinks) is paid for by the relevant
//                           third-party advertisers. These are not recommendations
//                           or endorsements by us.
//                         </li>
//                         <li>
//                           In some instances, the advertisement may contain
//                           representations or offers by the advertiser which you
//                           can accept by linking to the advertiser’s website and
//                           executing the relevant transaction. Such offers are
//                           not made by us, and the advertiser is solely
//                           responsible to you for the delivery of any goods or
//                           services you so acquire.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Intellectual Property</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>The Service is our intellectual property.</li>
//                         <li>
//                           Copyright and other intellectual property laws protect
//                           the Service.
//                         </li>
//                         <li>
//                           We will not knowingly or intentionally infringe any
//                           Intellectual Property Rights when providing the
//                           Service.
//                         </li>
//                         <li>
//                           In using the Service, you are responsible for ensuring
//                           that you do not infringe or violate any other person’s
//                           Intellectual Property Rights, misappropriate
//                           confidential information, or breach any Laws.
//                         </li>
//                         <li>
//                           On occasion, you may send us suggestions, materials,
//                           information, ideas or concepts relating to the Service
//                           (<b>“Ideas”</b>). You hereby grant us a royalty-free,
//                           perpetual, irrevocable, non-exclusive right (including
//                           any moral rights) to use such Ideas as we see fit,
//                           without payment of a fee. Without limiting the
//                           foregoing, no Idea will be subject to any
//                           confidentiality obligation.
//                         </li>
//                         <li>
//                           You have no right to use our trademarks or brands
//                           unless explicitly provided for in clause 8, or by a
//                           separate written and signed agreement between us.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Subscriptions</h3>
//                   <ul>
//                     <li>
//                       <h5 className="list-title">Subscriptions</h5>
//                       <ul>
//                         <li>
//                           We have different subscription packages for each level
//                           of certification. The features for each subscription
//                           package are set out on our website and are subject to
//                           change. You purchase a subscription for the
//                           subscription package that is selected when subscribing
//                           via our website or when entering into a written
//                           agreement with us.
//                         </li>
//                         <li>Subscriptions are for the specified period.</li>
//                         <li>
//                           The subscription period commences on the date set from
//                           when you first subscribe (<b>“Effective Date”</b>) and
//                           continues for one year from the date your
//                           certification is issued.
//                         </li>
//                         <li>
//                           If a certification is not issued within 12 months of
//                           the Effective Date, then your subscription will
//                           expire.
//                         </li>
//                         <li>
//                           A different subscription fee and associated billing
//                           period (<b>“Subscription Fee”</b>) applies to
//                           subscription packages. Details about our Subscription
//                           Fees, pricing and subscription packages are available
//                           on our website at{" "}
//                           <a href="https://certification.cybercert.ai">
//                             https://certification.cybercert.ai
//                           </a>
//                           .
//                         </li>
//                         <li>
//                           You may upgrade to a higher certification level via
//                           our website on payment of the specified Subscription
//                           Fee. If you upgrade, your subscription period
//                           re-commences on the date of the upgrade. No refunds or
//                           credits are provided for your existing subscription
//                           period.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Subscription Fee</h5>
//                       <ul>
//                         <li>
//                           You agree to pay us the applicable Subscription Fee
//                           for the subscription package selected by you when
//                           subscribing to the Service.
//                         </li>
//                         <li>
//                           You may receive a discount code from a third party
//                           that entitles you to a discounted or free
//                           subscription. We will honor such discount code in
//                           accordance with its terms if you provide it to us. You
//                           must not share or transfer your discount code.
//                         </li>
//                         <li>
//                           Unless expressly stated otherwise, the Subscription
//                           Fee is payable in advance by credit card or debit
//                           card.
//                         </li>
//                         <li>
//                           Subscription Fees are non-refundable. Unless otherwise
//                           stated in this Agreement, if you cancel your
//                           subscription or upgrade your subscription during the
//                           term of the subscription, no part of the Subscription
//                           Fee is refundable.
//                         </li>
//                         <li>
//                           We may change the Subscription Fee from time to time
//                           and will communicate any price changes in advance via
//                           our website. Price changes will only apply to new
//                           subscriptions that are taken out after the price
//                           change is published.
//                         </li>
//                         <li>
//                           We may use a payment gateway or payment service
//                           provider, such as Stripe or PayPal. We are not
//                           responsible for the operation or security of these
//                           third-party payment products. Please read the terms
//                           for any payment gateway prior to completing any
//                           transactions with us.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">
//                         Upgrading Certification Level
//                       </h5>
//                       <ul>
//                         <li>
//                           You may apply to upgrade to a higher level of
//                           certification at any time, except for level 4 or level
//                           5 that require you to be certified to level 3 before
//                           moving to levels 4 or 5.
//                         </li>
//                         <li>
//                           You may do this at any time during the current
//                           subscription period or at the end of the subscription
//                           period. If so, you will be charged the Fee for the
//                           selected level of certification on upgrade or renewal.
//                         </li>
//                         <li>
//                           If you do this during the current subscription period,
//                           your existing certification will remain valid until
//                           the expiry date of the currently issued certification.
//                           If you have not completed a certification under your
//                           current Subscription, any benefits associated to that
//                           Subscription will expire.
//                         </li>
//                         <li>
//                           You will not be entitled to any refunds or credits
//                           associated to Subscription Fees already paid.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">
//                         Downgrading Certification Level
//                       </h5>
//                       <ul>
//                         <li>
//                           You may apply to downgrade to a lower level of
//                           certification at any time. We do not recommend that
//                           you do this.
//                         </li>
//                         <li>
//                           You may do this at the end of the current subscription
//                           period. If so, you will be charged the Fee for the
//                           selected level of certification on renewal.
//                         </li>
//                         <li>
//                           If you do this during the current subscription period,
//                           your existing certification will remain valid until
//                           the expiry date of the currently issued certification.
//                           If you have not completed a certification under your
//                           current Subscription, any benefits associated to that
//                           Subscription will expire.
//                         </li>
//                         <li>
//                           You will not be entitled to any refunds or credits
//                           associated to Subscription Fees already paid.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Additional Products</h5>
//                       <ul>
//                         <li>
//                           You may also acquire digital or other products from
//                           our website, including for example the eBooks, PDF
//                           downloads and other online content (<b>“Products”</b>
//                           ).
//                         </li>
//                         <li>
//                           All Products are subject to copyright protection. When
//                           acquired, you receive a personal, single-user license
//                           only, to make one copy of the Product and edit the
//                           Product for your own internal use. You must not
//                           otherwise copy, distribute, share, loan, sell,
//                           sublicence or transfer a Product.
//                         </li>
//                         <li>
//                           We use technology to protect our intellectual property
//                           in respect of Products. For example, we may encrypt a
//                           Product, require the use of a password to read a
//                           Product, or watermark license details on the Product
//                           (such as your name, email or address).
//                         </li>
//                         <li>
//                           All payments made to us in respect of Products are
//                           non-refundable and products are not exchangeable,
//                           unless defective. If the wrong Product is delivered to
//                           you or it is defective, please let us know and will
//                           redeliver the correct or non-defective Product.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">GST</h5>
//                       <ul>
//                         <li>
//                           If GST is payable on a supply made under or in
//                           connection with this Agreement, the party providing
//                           the consideration for that supply must pay as
//                           additional consideration an amount equal to the amount
//                           of GST payable on that supply.
//                         </li>
//                         <li>
//                           Unless otherwise stated, all amounts referred to in
//                           this Agreement, including the Subscription Fees, are
//                           stated on a GST exclusive basis.
//                         </li>
//                         <li>
//                           If an adjustment event occurs in relation to a supply
//                           made under or in connection with this Agreement, the
//                           GST payable on that supply will be recalculated to
//                           reflect that adjustment and an appropriate payment
//                           will be made between the parties.
//                         </li>
//                         <li>
//                           In providing an invoice, a party shall provide proper
//                           tax invoices if GST is applicable to the Fees.
//                         </li>
//                         <li>
//                           Terms which have a defined meaning in the{" "}
//                           <i>A New Tax System (Goods and Services Tax) Act</i>{" "}
//                           1999 (Cth) shall have that meaning in this Agreement.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Attestation</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           This clause applies to certification levels 1 to 5.
//                         </li>
//                         <li>
//                           To obtain a certification at levels 1, 2, 3, 4, and 5
//                           you must provide a signed Letter of Attestation in a
//                           form provided by us. The Letter of Attestation must be
//                           signed by a director (if you are a corporation) or the
//                           owner, officer or senior executive (if you are not a
//                           corporation).
//                         </li>
//                         <li>
//                           The information that you provide to us for the
//                           purposes of certification must be accurate, complete,
//                           reliable, suitable in all respects and not misleading.
//                           This information must be independently verified by you
//                           and must be capable of verification by our auditor if
//                           required.
//                         </li>
//                         <li>
//                           We will rely upon the Letter of Attestation provided
//                           by you.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Audits</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           In addition to Attestation, an audit is required for
//                           certification levels 4 and 5.
//                         </li>
//                         <li>
//                           Your subscription fee includes the cost of one audit.
//                           In the event you fail your audit, additional audit
//                           fees will be charged for second and subsequent audits.
//                           You will be notified of the additional audit fees and
//                           required to pay additional audit fees prior to such
//                           audit.
//                         </li>
//                         <li>
//                           If you fail to attend an agreed upon audit session you
//                           may be charged an additional audit fee. You will be
//                           notified of the additional audit fees and required to
//                           pay additional audit fees prior to follow-up audits.
//                         </li>
//                         <li>
//                           In addition, after you have been issued with a
//                           Certification, we may audit you as part of our quality
//                           assurance process irrespective of your certification
//                           level. Failure to comply with or participate in such
//                           audit will result in your certification being
//                           cancelled. If you fail such audit, you will have 30
//                           days to rectify issues raised. If you fail to do so,
//                           or fail your follow-up audit, or fail to attend a
//                           follow-up audit, then your certification will be
//                           cancelled. No refunds will be issued if your
//                           certification is cancelled in these circumstances.
//                         </li>
//                         <li>
//                           The audit may be conducted by us or by a person
//                           nominated by us (including by an entity to whom you
//                           supply goods and services). The purpose of the audit
//                           is to ensure that you meet the Requirements.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Award Of Certification</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           If you meet all the Requirements for the level of
//                           certification that you have applied and paid for, we
//                           will issue you with a Certificate for that level.
//                         </li>
//                         <li>
//                           The Requirements are set out in the materials that we
//                           provide to you via our website as part of your
//                           subscription.
//                         </li>
//                         <li>
//                           The Requirements also include providing the signed
//                           Letter of Attestation and, for levels 4 and 5, passing
//                           an audit.
//                         </li>
//                         <li>
//                           The Certificate issued to you by us will be pursuant
//                           to the Certification Practice Statement and the
//                           Certification Requirements (version current at that
//                           time) of the Standards Authority and will be valid at
//                           the time of issuance.
//                         </li>
//                         <li>
//                           The version of the Certification Framework that will
//                           apply to you is the version that exists at the time
//                           that you attest that you have completed the
//                           Requirements and, for levels 4 and 5, at the time of
//                           the audit.
//                         </li>
//                         <li>
//                           Any Certificate issued by us to you may be revoked if
//                           an audit reveals that any information provided by, or
//                           on behalf of, you to us for the purpose of the
//                           certification (including the attestation made pursuant
//                           to clause 5), is or becomes, in any way, inaccurate,
//                           incomplete, unreliable or unsuitable.
//                         </li>
//                         <li>
//                           The Certificate will be valid for the period
//                           identified on the Certificate.
//                         </li>
//                         <li>
//                           You may not transfer, assign, sell or license the
//                           Certificate to anyone else, unless we consent in
//                           writing.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">
//                     License To Use Certificate And Badge
//                   </h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           CyberCert uses Credly to issue sharable verifiable
//                           badges associated with Certificates.
//                         </li>
//                         <li>
//                           If we issue you with a Certificate and have not
//                           revoked that Certificate, then for the period
//                           identified on the Certificate you have the right and
//                           license to use the Certificate and the badge displayed
//                           on the Certificate in accordance with the terms of
//                           this Agreement and Credly’s rules (if applicable).
//                         </li>
//                         <li>
//                           You may only use the badge that corresponds to the
//                           level of certification that you have obtained and has
//                           been issued to you.
//                         </li>
//                         <li>
//                           No license comes into effect until you pay the Fee for
//                           the subscription to the Service and continues only
//                           while you have an active certification.
//                         </li>
//                         <li>
//                           Once you have complied with the above, we grant to you
//                           and you accept a non-exclusive, non-transferable,
//                           personal license for the period defined above to
//                           display the Certificate at your premises and to use
//                           the badge on your website, in your email signature or
//                           footer, in your social media, and in your advertising
//                           and marketing on the terms of this Agreement.
//                         </li>
//                         <li>
//                           You may not assign, sublicense or otherwise deal with
//                           the license granted to you by this Agreement.
//                         </li>
//                         <li>
//                           This Agreement does not grant any other license to you
//                           in relation to any other trademark or intellectual
//                           property.
//                         </li>
//                         <li>
//                           All goodwill arising from your use of the Certificate
//                           and the badge vests solely in us.
//                         </li>
//                         <li>
//                           You must cease use of the Certificate and the badge
//                           within 30 days of expiry of your certification, except
//                           this period is extended to 90 days where you are
//                           currently renewing at a different level and are
//                           undergoing the renewal and certification process for
//                           that level.
//                         </li>
//                         <li>
//                           You are not required to display the Certificate or to
//                           use the badge.
//                         </li>
//                         <li>
//                           We will provide you with an electronic version of the
//                           badge via our service partner, Credly.
//                         </li>
//                         <li>
//                           You must use the complete badge as provided by us.
//                         </li>
//                         <li>
//                           You must not alter or edit the Certificate or the
//                           badge.
//                         </li>
//                         <li>
//                           When you use the badge on your website, you agree to
//                           include in the badge, an embedded URL link (as
//                           supplied by us (<b>“the Link”</b>)), which links back
//                           to our website (as per the Link), and if no Link is
//                           provided by us, then as a default link back to{" "}
//                           <a href="https://certification.cybercert.ai">
//                             https://certification.cybercert.ai
//                           </a>
//                           . You must ensure that the Link always remains
//                           operational whenever you include the badge on your
//                           website.
//                         </li>
//                         <li>
//                           If requested by us, you must provide reasonable
//                           information about how and where you use the
//                           Certificate and the badge.
//                         </li>
//                         <li>
//                           This license and Certificate are only for the
//                           organization that is issued the Certificate. You must
//                           not grant others permission to use the Certificate or
//                           badge.
//                         </li>
//                         <li>
//                           You may only use the Certificate and the badge for the
//                           level of certification that you have achieved.
//                         </li>
//                         <li>
//                           If you breach this Agreement and we terminate this
//                           Agreement, then you must immediately cease all use of
//                           the Certificate and the badge.
//                         </li>
//                         <li>
//                           If you fail an audit or cease being compliant to the
//                           certification requirements, then you must immediately
//                           cease all use of the Certificate and the badge.
//                         </li>
//                         <li>
//                           During the period that you are using the Certificate
//                           and the badge, you must not tarnish the Certificate or
//                           badge or disparage us.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Confidentiality</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           We are not in a fiduciary relationship with you.
//                         </li>
//                         <li>
//                           Each party agrees to keep strictly confidential, and
//                           not to disclose, the Confidential Information of the
//                           other party.
//                         </li>
//                         <li>
//                           Each party agrees to use the Confidential Information
//                           of the other party solely to carry out its obligations
//                           or receive the benefits of this Agreement.
//                         </li>
//                         <li>
//                           Our Confidential Information includes all details of
//                           the Requirements for each certification level.
//                         </li>
//                         <li>
//                           Your Confidential Information includes the information
//                           provided by you to us on the Attestation.
//                         </li>

//                         <li>
//                           Notwithstanding the foregoing, a party may disclose
//                           Confidential Information of the other party:
//                           <ul>
//                             <li>
//                               to its legal advisors, accountants, auditors on a
//                               confidential need-to-know basis;
//                             </li>
//                             <li>
//                               to its partners and employees and its Related
//                               Bodies Corporate on a confidential need-to-know
//                               basis;
//                             </li>
//                             <li>
//                               in enforcing this Agreement or in a proceeding
//                               arising out of or in connection with this
//                               Agreement; or
//                             </li>
//                             <li>
//                               to the extent required by Law or pursuant to a
//                               binding order of a government agency or court.
//                             </li>
//                           </ul>
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Privacy and Security</h3>
//                   <ul>
//                     <li>
//                       <h5 className="list-title">Privacy</h5>
//                       <ul>
//                         <li>
//                           We hold any personal information that you provide to
//                           us in accordance with our privacy policy, the current
//                           version of which is located on our website at{" "}
//                           <a href="https://certification.cybercert.ai/privacy-policy">
//                             https://certification.cybercert.ai/privacy-policy
//                           </a>
//                           .
//                         </li>
//                         <li>
//                           Our privacy policy is hereby incorporated into this
//                           Agreement by reference.
//                         </li>
//                         <li>
//                           You acknowledge and agree to be bound by the terms of
//                           our privacy policy.
//                         </li>
//                         <li>
//                           We may amend our privacy policy from time to time. We
//                           will notify you of any amendments by email or online
//                           posting on our website or when you next use the
//                           Service.
//                         </li>
//                         <li>
//                           We collect and use Personal Information from and about
//                           you. This allows us, for example, to open and
//                           administer user accounts and to provide the Service to
//                           you.
//                         </li>
//                         <li>
//                           You must comply with all our reasonable directions
//                           regarding privacy and security.
//                         </li>
//                         <li>
//                           You must obtain all necessary consents, and provide
//                           all necessary collection notices, relevant to the
//                           Service in relation to the collection, use, disclosure
//                           and storage of Personal Information of any individual
//                           whose Personal Information may be provided to us,
//                           directly or indirectly, as contemplated by this
//                           Agreement.
//                         </li>
//                         <li>
//                           You agree and consent to the handling of Personal
//                           Information in accordance with our privacy policy.
//                         </li>
//                         <li>
//                           We make no warranty as to whether your use of the
//                           Service will comply with your obligations under
//                           Privacy Laws. It is your responsibility to determine
//                           whether the Service is appropriate for your
//                           circumstances.
//                         </li>
//                         <li>
//                           If you have been referred to us by a third party or
//                           are part of the supply chain certification program of
//                           a third party, you give us permission to share details
//                           of your certification and program progress to that
//                           third party, including whether you have been certified
//                           and at what level, whether have created an account
//                           profile but not yet certified, when you are up for
//                           renewal, when you upgrade to a higher level, where you
//                           have met or failed to meet requirements, and if your
//                           certifications are current or have expired.
//                         </li>
//                         <li>
//                           Where you have been referred to us by a third party,
//                           such as referred by one of our Support partners or
//                           Channel partners, and we have an obligation to pay
//                           such third party a referral fee or commission, you
//                           give us permission to share sufficient details for
//                           reporting and commission payments purposes.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Security</h5>
//                       <ul>
//                         <li>
//                           We implement industry standard systems and take
//                           reasonable measures consistent with commercially
//                           available security practices to secure the Service
//                           from unauthorized access and to protect the integrity
//                           and confidentiality of the content on the Service.
//                         </li>
//                         <li>
//                           However, any transmission of data over the internet is
//                           inherently risky. You acknowledge and agree that you
//                           are responsible for your and your User’s access to the
//                           Service, for the computer or mobile device and
//                           associated software that is used to access the
//                           Services, and for transmission of data over the
//                           internet.
//                         </li>
//                         <li>
//                           You are responsible for taking precautions to ensure
//                           that your access to the Service does not expose you to
//                           the risk of interference or damage to your content or
//                           computer systems or networks.
//                         </li>
//                         <li>
//                           We make no representation and give no warranty
//                           regarding your access to the Service. Due to the
//                           nature of the internet and software programs, during
//                           the use of the Service, you may experience errors,
//                           poor network performance and other issues that detract
//                           from use of the Service. While we take steps to
//                           maintain the Service, we do not warrant that the
//                           Service will be constantly available or warrant that
//                           you will have uninterrupted and error-free access to
//                           the Service.
//                         </li>
//                         <li>
//                           Due to regulatory or technical restrictions, you may
//                           not be able to access the Service from every location
//                           in the world. You should verify access prior to
//                           accepting the Agreement. We are not responsible for
//                           your inability to access the Service.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Force Majeure</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           Subject to the requirement to give notice under this
//                           clause, if the performance by any party (
//                           <b>“Affected Party”</b>) of all or any of its
//                           obligations under this Agreement is prevented or
//                           delayed (in whole or in part) due to any Force Majeure
//                           Event, this Agreement will continue and remain in
//                           effect but the Affected Party will not be in breach of
//                           this Agreement for that reason only, and the Affected
//                           Party will be granted a reasonable extension of time
//                           to complete performance of its affected obligations.
//                         </li>
//                         <li>
//                           The Affected Party must promptly after becoming aware
//                           of a Force Majeure Event, give written notice to the
//                           other party of the nature of the Force Majeure Event
//                           and the way and the extent to which its obligations
//                           are prevented or delayed and notify the other party of
//                           any material change in these matters and use its
//                           reasonable endeavors to limit the effects of the Force
//                           Majeure Event, and promptly carry out its obligations
//                           as soon as, and to the extent that, it is able to do
//                           so.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Suspension</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           If we suspect that you have breached any term of this
//                           Agreement, we have the right to (without limitation)
//                           to suspend your access to the Service.
//                         </li>
//                         <li>
//                           We reserve the right to take any such action without
//                           prior notice.
//                         </li>
//                         <li>
//                           If your access to the Service is suspended, you will
//                           not be entitled to damages, or any refund or
//                           reimbursement of Subscription Fees or other amounts
//                           previously paid by or charged to you.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Termination</h3>
//                   <ul>
//                     <li>
//                       {/* <h5 className="list-title">subtitle comment out if none</h5> */}
//                       <ul>
//                         <li>
//                           Either party may terminate this Agreement with
//                           immediate effect by giving written notice to the other
//                           party at any time if:
//                           <ul>
//                             <li>
//                               the other party experiences an Insolvency Event;
//                               or
//                             </li>
//                             <li>
//                               the other party breaches any material provision of
//                               this Agreement, which is incapable of being
//                               remedied, or where the breach is capable of being
//                               remedied, fails to remedy the breach within 14
//                               days after receiving written notice from the
//                               terminating party requiring it to do so.
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           You may terminate this Agreement on 7 days' written
//                           notice for any reason and without cause. However, you
//                           will not be entitled any refund or reimbursement of
//                           Subscription Fees or other amounts previously paid by
//                           or charged to you.
//                         </li>
//                         <li>
//                           We may terminate this Agreement immediately by written
//                           notice to you in the event of:
//                           <ul>
//                             <li>
//                               any change (directly or indirectly) in a
//                               controlling interest or majority ownership of you;
//                               or
//                             </li>
//                             <li>
//                               there is a change in Law that materially or
//                               permanently prevents us from providing the
//                               Service.
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           Upon termination of this Agreement under this clause
//                           13, your right to access the Service ceases, all
//                           licenses granted to you under this Agreement
//                           terminate, and you will no longer be certified by us.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">
//                     Warranties, Indemnity And Limitations
//                   </h3>
//                   <ul>
//                     <li>
//                       <h5 className="list-title">Warranties</h5>
//                       <ul>
//                         <li>
//                           Each party warrants that it:
//                           <ul>
//                             <li>
//                               has the authority to enter into and perform its
//                               obligations under this Agreement and that this
//                               Agreement has been duly executed and is a legal,
//                               valid and binding Agreement; and
//                             </li>
//                             <li>will always comply with applicable Laws.</li>
//                           </ul>
//                         </li>
//                         <li>
//                           You warrant and represent that:
//                           <ul>
//                             <li>
//                               You will use the Service in accordance with this
//                               Agreement;
//                             </li>
//                             <li>
//                               You will not use the Service or any information
//                               that you obtain during use of the Service for any
//                               improper or unlawful purpose;
//                             </li>
//                             <li>
//                               You will not infringe our Intellectual Property
//                               Rights; and
//                             </li>
//                             <li>
//                               You will not infringe any person’s Intellectual
//                               Property Rights while using the Service.
//                             </li>
//                           </ul>
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Indemnity</h5>
//                       <ul>
//                         <li>
//                           You hereby defends, holds harmless and indemnifies us
//                           and our Personnel (<b>“the Indemnified Parties”</b>)
//                           from and against any Loss suffered or incurred by the
//                           Indemnified Parties arising out of or in connection
//                           with any material breach by you of any provision of
//                           this Agreement including any warranty in this
//                           Agreement, or that the Indemnified Parties may suffer
//                           or incur arising out of or relating to your misuse of
//                           the Service, or in respect of any claim made or legal
//                           or regulatory action brought against the Indemnified
//                           Parties arising out of or relating to your conduct
//                           that is a misuse of the Service or breach of this
//                           Agreement.
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       <h5 className="list-title">Limitations</h5>
//                       <ul>
//                         <li>
//                           Unless expressly stated otherwise in writing by us,
//                           the information we provide in a report or assessment
//                           does not take into account the full individual
//                           circumstances of you or of any third party and is
//                           based on standardized methodology. It is your
//                           responsibility to assess the suitability of the
//                           Service for your own individual circumstances. We
//                           recommend that if you are in any doubt or in any way
//                           unsure, you should take professional advice before
//                           taking any action that may affect your security
//                           position and financial or legal standing.
//                         </li>
//                         <li>
//                           Where a Non-Excludable Condition is deemed to apply,
//                           to the fullest extent possible under Law, we limit our
//                           liability for any breach of the Non-Excludable
//                           Condition to:
//                           <ul>
//                             <li>
//                               in the case of goods:
//                               <ul>
//                                 <li>
//                                   the re-supply of the goods or payment of the
//                                   cost of the re-supply of the goods; or
//                                 </li>
//                                 <li>
//                                   the replacement or repair of the goods or
//                                   payment of the cost of replacement or repair
//                                   of the goods; and
//                                 </li>
//                               </ul>
//                             </li>
//                             <li>
//                               in the case of services:
//                               <ul>
//                                 <li>the resupply of the services; or</li>
//                                 <li>
//                                   the payment of the cost of having the services
//                                   resupplied.
//                                 </li>
//                               </ul>
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           Without limiting any other term of this Agreement, we
//                           are not responsible for:
//                           <ul>
//                             <li>
//                               Your security or the security of your computer
//                               equipment, systems, networks or cloud service
//                               providers (including in respect of data breaches
//                               therein);
//                             </li>
//                             <li>
//                               Cybersecurity, data or privacy incidents involving
//                               you or your computer equipment, systems, services,
//                               subscriptions and networks;
//                             </li>
//                             <li>
//                               Internet, electronic, hardware, software, network
//                               or other computer-related failures, malfunctions
//                               or errors;
//                             </li>
//                             <li>
//                               Any disruptions, damages, losses, failures or
//                               errors that are caused by events or occurrences
//                               that are beyond our control; or
//                             </li>
//                             <li>
//                               Typographical, processing, mechanical or human
//                               errors.
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           Subject to the express provisions of this Agreement
//                           and Non-Excludable Conditions, we provide the Service
//                           strictly on an “as-is” and “as available” basis, and
//                           to the maximum extent permitted by law, we exclude all
//                           warranties, terms, conditions or undertakings whether
//                           express or implied, statutory or otherwise, other than
//                           Non-Excludable Conditions, that the Service will be:
//                           <ul>
//                             <li>Available at any particular time;</li>
//                             <li>Secure or error-free;</li>
//                             <li>Fit for any particular purpose; or</li>
//                             <li>Will meet any standard or specification.</li>
//                           </ul>
//                         </li>
//                         <li>
//                           If we are found to be liable to you (including in
//                           contract, tort (including negligence) or otherwise),
//                           our liability is limited in all circumstances to one
//                           of the following options, as selected by us:
//                           <ul>
//                             <li>Resupply of the Service;</li>
//                             <li>Supply of a similar service;</li>
//                             <li>Refunding your Subscription Fee;</li>
//                             <li>Fixing the Service (if appropriate); or</li>
//                             <li>
//                               Paying someone else to fix the Service (if
//                               appropriate).
//                             </li>
//                           </ul>
//                         </li>
//                         <li>
//                           Notwithstanding sub-clause (e) above, but subject to
//                           sub-clause (b) above, to the fullest extent permitted
//                           by Law, our total cumulative liability to you in
//                           connection with this Agreement, whether in contract or
//                           tort (including negligence) or otherwise, will not
//                           exceed AUD $750.
//                         </li>
//                         <li>
//                           To the fullest extent permitted by Law, but subject to
//                           sub-clause (b) above, we hereby expressly disclaim all
//                           implied or statutory warranties, including, without
//                           limitation, any implied warranties of merchantability,
//                           fitness for a particular purpose, non-infringement,
//                           title, reliability, accuracy, completeness and quiet
//                           enjoyment.
//                         </li>
//                         <li>
//                           Notwithstanding any other clause of this Agreement,
//                           but subject to sub-clause (b) above, to the extent
//                           permitted by Law, we exclude all liability, including
//                           in tort (including negligence), contract and
//                           otherwise, for any loss of or damage to data, loss of
//                           profits, loss of revenue, loss of privacy, damage to
//                           reputation and goodwill, and loss of business, and any
//                           consequential, indirect or special loss or damage.
//                         </li>
//                         <li>
//                           Our liability under this Agreement will be reduced
//                           proportionally to the extent to which any loss was
//                           caused or contributed to by any negligence or other
//                           wrongful act or omission of you.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Assignment And Subcontracting</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           You must not assign or novate, directly or indirectly,
//                           any of your rights or obligations under this Agreement
//                           without the prior written consent of us. We may assign
//                           or novate all of or part of this Agreement on written
//                           notice to you. You are not an agent or employee of us.
//                           We are not an agent or employee of you.
//                         </li>
//                         <li>
//                           Nothing contained or implied in this Agreement means
//                           that you are a partner, agent or legal representative
//                           of us for any purpose, or creates any partnership,
//                           agency or trust. You have no authority to bind us in
//                           any way. Any relationship we have you is as a service
//                           provider.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Survival</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           Without limiting any other provision of this
//                           agreement, clauses 2.9, 3, 5, 9, 13,14, 16, 18, 19,
//                           and any other clauses which should by their nature
//                           survive termination of this Agreement, survive
//                           termination or expiry of this Agreement for any
//                           reason.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">No Uptime Guarantee</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           We do not guarantee 24 hours uptime of the Service.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Notices</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           Any notice, demand, consent or other communication (a
//                           Notice) given or made under this Agreement:
//                           <ul>
//                             <li>
//                               must be in writing and signed by the sender or a
//                               person duly authorized by the sender;
//                             </li>
//                             <li>
//                               must be addressed and delivered to the intended
//                               recipient by prepaid post or by hand or email to
//                               the address or email address of the representative
//                               of the party as specified by you when you opened
//                               your account or for us as set out at the end of
//                               this Agreement, or as last notified by the
//                               intended recipient to the sender; and
//                             </li>
//                             <li>
//                               will be conclusively taken to be duly given or
//                               made when delivered, received or left at the above
//                               email address, or address. If delivery or receipt
//                               occurs on a day that is not a business day in the
//                               place to which the Notice is sent or is later than
//                               4pm (local time) at that place, it will be
//                               conclusively taken to have been duly given or made
//                               at the commencement of business on the next
//                               business day in that place.
//                             </li>
//                           </ul>
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Dispute Resolution</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           If any dispute arises in relation to the Service or
//                           this Agreement, you agree to:
//                           <ol type="a">
//                             <li>First contact us to discuss.</li>
//                             <li>
//                               If we are unable to resolve the matter within 14
//                               days of being notified, we will arrange a time for
//                               one of our senior representatives to contact you
//                               (or your senior representative) at a convenient
//                               time to discuss.
//                             </li>
//                             <li>
//                               If we are unable to resolve the dispute between
//                               our senior representatives, the matter will be
//                               referred to mediation, with a mediator to be
//                               appointed by the Institute of Arbitrators and
//                               Mediators Australia.
//                             </li>
//                             <li>
//                               If the dispute is not able to be resolved by
//                               mediation, the parties may agree to implement
//                               further alternative dispute resolution processes.
//                             </li>
//                           </ol>
//                         </li>
//                         <li>
//                           Nothing in this clause prevents a party from seeking
//                           urgent injunctive relief before an appropriate court.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Referrals</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           We may provide recommendations or contact details for
//                           specialist advisers, such as IT security experts.
//                         </li>
//                         <li>
//                           We may receive a referral fee or commission from an
//                           adviser if you engage their services following our
//                           referral.
//                         </li>
//                         <li>
//                           We are not responsible for their advice or your
//                           relationship with them. If you engage one of them,
//                           please refer to their terms to understand your rights
//                           and obligations.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Other Agreements</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           To the extent that there is any inconsistency between
//                           this Agreement and another agreement between you and
//                           us, this Agreement will prevail in relation to the
//                           Service unless the other agreement expressly states
//                           otherwise.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">General</h3>
//                   <ul>
//                     <li>
//                       <ul>
//                         <li>
//                           This Agreement contains the entire agreement between
//                           the parties with respect to its subject matter.
//                         </li>
//                         <li>
//                           Each party will be fully responsible to the other
//                           party for any Loss suffered arising from or in
//                           connection with the acts or omissions of its partners,
//                           sub-contractors, contractors, assigns and all their
//                           employees, as if they were the acts and omissions of
//                           the relevant party.
//                         </li>
//                         <li>
//                           No failure to exercise or delay in exercising any
//                           right, power or remedy under this Agreement operates
//                           as a waiver. A single or partial exercise or waiver of
//                           the exercise of any right, power or remedy does not
//                           preclude any other or further exercise of that or any
//                           other right, power or remedy. A waiver is not valid or
//                           binding on the party granting that waiver unless made
//                           in writing.
//                         </li>
//                         <li>
//                           The rights, powers and remedies provided to a party in
//                           this Agreement are in addition to, and do not exclude
//                           or limit, any right, power or remedy provided by law
//                           or equity or any agreement.
//                         </li>
//                         <li>
//                           Any provision of this Agreement which is prohibited or
//                           unenforceable in any jurisdiction is ineffective as to
//                           that jurisdiction to the extent of the prohibition or
//                           unenforceability. That does not invalidate the
//                           remaining provisions of this Agreement nor affect the
//                           validity or enforceability of that provision in any
//                           other jurisdiction.
//                         </li>
//                         <li>
//                           Each party must bear its own costs arising out of the
//                           negotiation, preparation and execution of this
//                           Agreement.
//                         </li>
//                         <li>
//                           This Agreement and, to the extent permitted by law,
//                           all related matters including non-contractual matters,
//                           is governed by the laws of Queensland, Australia. In
//                           relation to such matters each party irrevocably
//                           accepts the non-exclusive jurisdiction of courts with
//                           jurisdiction in Queensland and waives any right to
//                           object to the venue on any ground.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <h3 className="list-title">Definitions And Interpretation</h3>
//                   <ul>
//                     <li>
//                       <h5 className="list-title">DEFINITIONS</h5>
//                       <p>
//                         The following definitions apply unless the context
//                         requires otherwise.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Confidential Information</i>
//                         </b>{" "}
//                         means all non-public business or technical information,
//                         in any form whether tangible or not, disclosed or
//                         communicated by a party to the other, or learnt or
//                         accessed by, or to which the other party is exposed as a
//                         result of entering into this Agreement.
//                       </p>
//                       <p>
//                         Confidential Information does not include information
//                         which party can demonstrate by written records was:
//                       </p>
//                       <ul>
//                         <li>already known to that party;</li>
//                         <li>
//                           received by that party from a third party not under a
//                           duty of confidence; or
//                         </li>
//                         <li>
//                           independently developed by that party by people who
//                           did not have access to the Confidential Information of
//                           the other party.
//                         </li>
//                       </ul>
//                       <p>
//                         <b>
//                           <i>Force Majeure Event</i>
//                         </b>{" "}
//                         affecting a party means a circumstance beyond the
//                         reasonable control of that party causing that party to
//                         be unable to observe or perform on time an obligation
//                         under this Agreement, including acts of God, lightning
//                         strikes, earthquakes, floods, storms, explosions, fires
//                         and any natural disaster, acts of public enemies,
//                         terrorism, riots, civil commotion, malicious damage,
//                         sabotage, revolution and acts of war and war, general
//                         strikes (other than of its own staff), embargo, or
//                         power, water and other utility shortage.
//                       </p>
//                       <p>
//                         An{" "}
//                         <b>
//                           <i>Insolvency Event</i>
//                         </b>{" "}
//                         occurs in respect of a person where:
//                       </p>
//                       <ul>
//                         <li>
//                           a party ceases, suspends or threatens to cease or
//                           suspend the conduct of all or a substantial part of
//                           its business or disposes of or threatens to dispose of
//                           a substantial part of its assets;
//                         </li>
//                         <li>
//                           a party becomes unable to pay its debts when they fall
//                           due, or stops or suspends or threatens to stop or
//                           suspend payment of all or a class of its debts;
//                         </li>
//                         <li>
//                           a party becomes or is (including under legislation)
//                           deemed or presumed to be insolvent;
//                         </li>
//                         <li>
//                           a party has a receiver, manager, administrator,
//                           administrative receiver or similar officer appointed
//                           in respect of it or the whole or any part of its
//                           assets or business;
//                         </li>
//                         <li>
//                           any composition or arrangement is made with any one or
//                           more classes of its creditors;
//                         </li>
//                         <li>
//                           except for the purpose of solvent amalgamation or
//                           reconstruction, an order, application or resolution is
//                           made, proposed or passed for its winding up,
//                           dissolution, administration or liquidation;
//                         </li>
//                         <li>
//                           a party enters into liquidation whether compulsorily
//                           or voluntarily; or
//                         </li>
//                         <li>
//                           any analogous or comparable event takes place in any
//                           jurisdiction.
//                         </li>
//                       </ul>
//                       <p>
//                         <b>
//                           <i>Intellectual Property Rights</i>
//                         </b>{" "}
//                         means all industrial and intellectual property rights of
//                         any kind including but not limited to copyrights
//                         (including rights in computer software), trademarks,
//                         service marks, designs, patents, trade secrets,
//                         semi-conductor or circuit layout rights, trade,
//                         business, domain or company names, rights in
//                         Confidential Information, know-how and other proprietary
//                         rights (whether or not any of these are registered and
//                         including any application, or right to apply, for
//                         registration) and all rights or forms of protection of a
//                         similar nature or having equivalent or similar effect to
//                         any of these, which may subsist anywhere in the world,
//                         but excludes moral rights, and similar personal rights,
//                         which by law are non-assignable.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Law</i>
//                         </b>{" "}
//                         means all applicable laws including rules of common law,
//                         principles of equity, statutes, regulations,
//                         proclamations, ordinances, by laws, rules, regulatory
//                         principles, requirements and determinations, mandatory
//                         codes of conduct and standards, writs, orders,
//                         injunctions and judgments, and includes any Privacy
//                         Laws.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Loss</i>
//                         </b>{" "}
//                         means any claim, loss, damage, liability, cost, charge
//                         or expense (including legal expenses on a full indemnity
//                         basis), however arising, and whether present or future,
//                         fixed or unascertained, actual or contingent.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Non-Excludable Condition</i>
//                         </b>{" "}
//                         means any guarantee, condition or warranty (such as the
//                         consumer guarantees implied by the Competition and
//                         Consumer Act 2010 (Cth)), which cannot by law be
//                         excluded.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Personal Information</i>
//                         </b>{" "}
//                         has the meaning given to that term in the Privacy Act.{" "}
//                       </p>
//                       <p>
//                         <b>
//                           <i>Personnel</i>
//                         </b>{" "}
//                         means, in respect of a person, any officer, employee,
//                         contractor, servant, agent, or other person under the
//                         person's direct or indirect control and includes any
//                         subcontractors.
//                       </p>
//                       <p>
//                         <b>
//                           <i>Privacy Laws</i>
//                         </b>{" "}
//                         means all legislation, principles, industry codes and
//                         policies, as amended or replaced from time to time,
//                         which relate to the collection, use, disclosure, storage
//                         or granting of access rights to Personal Information,
//                         and includes the Privacy Act 1988 (Cth) and the Spam Act
//                         2003 (Cth).
//                       </p>
//                       <p>
//                         <b>
//                           <i>Subscription Fee</i>
//                         </b>{" "}
//                         is specified when you take up a subscription online on
//                         our website.
//                       </p>
//                     </li>
//                     <li>
//                       <h5 className="list-title">INTERPRETATION</h5>
//                       <p>
//                         Headings are for convenience only and do not affect
//                         interpretation. The following rules apply unless the
//                         context requires otherwise.
//                       </p>
//                       <ul>
//                         <li>
//                           the singular includes the plural and conversely;
//                         </li>
//                         <li>
//                           where a word or phrase is defined, its other
//                           grammatical forms have a corresponding meaning;
//                         </li>
//                         <li>
//                           a reference to a person includes anybody corporate,
//                           unincorporated body or other entity and conversely;
//                         </li>
//                         <li>
//                           a reference to any party to this Agreement or any
//                           other agreement or document includes the party’s
//                           successors and permitted assigns;
//                         </li>
//                         <li>
//                           a reference to any agreement or document (including a
//                           reference to this Agreement) is to that agreement or
//                           document as amended, notated, supplemented, varied or
//                           replaced from time to time, where applicable, in
//                           accordance with this Agreement or that other agreement
//                           or document;
//                         </li>
//                         <li>
//                           a reference to any legislation or to any provision of
//                           any legislation includes any modification or
//                           re-enactment of it, any legislative provision
//                           substituted for it and all regulations and statutory
//                           instruments issued under it;
//                         </li>
//                         <li>
//                           a reference to conduct includes any omissions,
//                           statement or undertaking, whether or not in writing;
//                         </li>
//                         <li>
//                           a reference to includes, means includes without
//                           limitation; and
//                         </li>
//                         <li>
//                           all references to $ are to Australian dollars, unless
//                           otherwise specified.
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li>
//               </ul>

//               <div>
//                 <h6>Our Contact Details and Address for Service</h6>
//                 <p>
//                   Please contact us via the Help & Support contact form located
//                   in your CyberCert Dashboard if you have any questions relating
//                   to these terms and conditions.
//                 </p>
//                 <p>
//                   CyberCert Pty Ltd <br />
//                   60 Martin Place <br />
//                   Sydney, NSW 2000 <br />
//                   Australia
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
